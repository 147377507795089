<template>
  <div class="song" v-loading.fullscreen.lock="fullscreenLoading">
    <h1>Copyright Protection</h1>
    <el-row type="flex" justify="center">
      <el-col :xs="24" :sm="24" :md="18" :lg="12" :xl="9">
        <p style="text-align: left">
          Protect your songs and prove exactly when you wrote them using
          powerful, unforgeable blockchain technology. This feature is coming
          very soon.
        </p>
      </el-col>
    </el-row>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "CopyrightView",
  components: {},
  data() {
    return {
      fullscreenLoading: false,
    };
  },
  methods: {},
  beforeMount() {
    this.loadSong();
  },
};
</script>

<style>
.el-tag {
  margin-right: 5px !important;
}
.button-new-tag {
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}
.input-new-tag {
  width: 90px;
  vertical-align: bottom;
}
</style>
