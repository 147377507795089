<template>
  <el-container v-loading.fullscreen.lock="fullscreenLoading">
    <el-header>
      <el-row align="middle" type="flex" justify="around">
        <el-col
          :xs="24"
          :sm="24"
          :md="18"
          :lg="12"
          align="middle"
          style="text-align: left"
        >
          <el-row align="middle" type="flex" justify="start">
            <router-link :to="{ path: '/' }">
              <el-button
                type="plain"
                size="mini"
                icon="el-icon-s-home"
                style="margin-right: 10px"
              ></el-button>
            </router-link>
            <h1>{{ title }}</h1>
          </el-row>
        </el-col>

        <el-col
          :xs="24"
          :lg="12"
          :xl="12"
          style="text-align: right"
          justify="end"
          class="hidden-sm-and-down"
        >
          <el-row type="flex" justify="end" align="middle">
            <el-button
              type="text"
              style="margin-right: 15px"
              @click="referral()"
            >
              Refer a friend</el-button
            >
            <el-button
              type="text"
              style="margin-right: 15px"
              @click="open('https://www.songpad.co/blog', 'Advice & Tips')"
            >
              Advice & Tips</el-button
            >
            <el-button
              type="text"
              style="margin-right: 25px"
              @click="open('https://help.songpad.co', 'Help')"
              >Help</el-button
            >
            <el-button
              type="primary"
              round
              size="small"
              icon="el-icon-chat-line-square"
              v-if="$route.path.indexOf('/song/') > -1"
              @click="$emit('share')"
              style="margin-right: 10px"
              >Share</el-button
            >
            <p v-if="subscription" style="margin-right: 10px">
              <strong>SongPad Plus</strong>
            </p>
            <el-button
              @click="$refs.modal.showModal()"
              size="small"
              round
              v-else
              style="margin-right: 10px"
            >
              Upgrade to Plus
            </el-button>
            <el-dropdown @command="handleCommand">
              <span
                class="el-dropdown-link"
                style="display: flex; align-items: center"
              >
                <avatar
                  v-if="user"
                  :username="
                    user.attributes.given_name +
                    ' ' +
                    user.attributes.family_name
                  "
                  :size="32"
                  style="margin: 0 0 0 auto"
                ></avatar>
                <i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  icon="el-icon-bank-card"
                  command="manageSubscription"
                  >Manage subscription</el-dropdown-item
                >
                <el-dropdown-item icon="el-icon-right" command="signOut"
                  >Logout</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </el-row>
        </el-col>
      </el-row>
    </el-header>
    <upgrade-modal
      ref="modal"
      intro="Take your songwriting to the next level with SongPad Plus"
      :v-if="!subscription"
    ></upgrade-modal>
    <referral-modal ref="referralModal"></referral-modal>
  </el-container>
</template>

<script>
import { Auth, API } from "aws-amplify";
import Avatar from "vue-avatar";
import { stripe_pricing } from "@/config/stripe.js";
import UpgradeModal from "./UpgradeModal.vue";
import ReferralModal from "./ReferralModal.vue";

export default {
  name: "NavBar",
  components: {
    Avatar,
    UpgradeModal,
    ReferralModal,
  },
  props: ["title", "showBackButton"],
  data() {
    return {
      user: null,
      fullscreenLoading: false,
      subscription: null,
    };
  },
  methods: {
    referral() {
      this.$refs.referralModal.showModal();
    },
    async handleCommand(command) {
      switch (command) {
        case "signOut":
          this.$intercom.shutdown();
          Auth.signOut()
            .then(() => {
              location.href = "/";
            })
            .catch(() => {});
          break;
        case "manageSubscription":
          this.fullscreenLoading = true;
          var user = await Auth.currentAuthenticatedUser({
            bypassCache: true,
          });

          if (this.subscription != null) {
            window.location.href = process.env.VUE_APP_STRIPE_PORTAL;
          } else {
            let vm = this;
            vm.fullscreenLoading = true;
            try {
              const myInit = {
                body: {
                  priceId: stripe_pricing.plus.monthlyPriceId,
                  email: user.attributes.email,
                  successUrl:
                    location.protocol +
                    "//" +
                    location.host +
                    location.pathname,
                  cancelUrl:
                    location.protocol +
                    "//" +
                    location.host +
                    location.pathname,
                },
                headers: { "Content-Type": "application/json" },
              };

              let response = await API.post("stripe", "/checkout", myInit);
              if (response.body.url) {
                window.location.href = response.body.url;
              }
            } catch (error) {
              console.log(error);
              vm.fullscreenLoading = false;
              vm.$alert(
                "Something seems to have gone wrong. Please try again",
                "Sorry",
                {
                  confirmButtonText: "OK",
                }
              );
            }
          }

          break;
        default:
          break;
      }
    },
    open(link, name) {
      this.$intercom.trackEvent("Clicked " + name, { link: link, name: name });
      window.open(link, "_blank");
    },
  },
  async mounted() {
    this.user = await Auth.currentAuthenticatedUser({
      bypassCache: true,
    });

    try {
      let updated = false;
      if (this.user.attributes.given_name === undefined) {
        this.user.attributes.given_name = this.user.attributes.name.substring(
          0,
          this.user.attributes.name.indexOf(" ")
        );
        updated = true;
      }
      if (this.user.attributes.family_name === undefined) {
        this.user.attributes.family_name = this.user.attributes.name.substring(
          this.user.attributes.name.indexOf(" ") + 1
        );
        updated = true;
      }
      if (updated) {
        await Auth.updateUserAttributes(this.user, {
          given_name: this.user.attributes.given_name,
          family_name: this.user.attributes.family_name,
        });
      }

      let response = await API.get("intercom", "/user");

      this.$intercom.boot({
        user_id: this.user.username,
        user_hash: response.hmac,
      });
      this.$intercom.update({
        user_id: this.user.username,
        name:
          this.user.attributes.given_name +
          " " +
          this.user.attributes.family_name,
        email: this.user.attributes.email,
      });
    } catch (error) {
      console.log(error);
    }

    var subscription = this.user.attributes["custom:stripe_subscription"];
    if (subscription != undefined) {
      this.subscription = subscription;
    }

    if (localStorage.signupPlus == "true") {
      this.$refs.modal.showModal();
      localStorage.removeItem("signupPlus");
    }
  },
};
</script>

<style scoped>
h1 {
  font-size: 1.2em;
}

.el-button--mini {
  font-size: 18px;
  padding: 3px 7px;
}
</style>
