<template>
  <div class="home">
    <el-container>
      <el-main style="padding-top: 45px">
        <router-view />
      </el-main>
    </el-container>
  </div>
</template>

<script>
// @ is an alias to /src
import { Auth } from "aws-amplify";

export default {
  name: "HomeView",
  data() {
    return {
      user: null,
    };
  },
  methods: {
    signOut() {
      this.$intercom.shutdown();
      Auth.signOut()
        .then(() => {
          location.reload();
        })
        .catch((err) => console.log(err));
    },
  },
  computed: {},
  beforeMount() {
    // console.log(AmplifyStore.state.user);
  },
  async mounted() {},
};
</script>

<style>
.purple {
  background-color: #211538;
}

.el-header {
  background: #fff;
  position: fixed !important;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
}

@media only screen and (max-width: 600px) {
  .el-main {
    padding: 20px 10px 0 10px !important;
  }
  .el-main .spaced {
    margin: 20px 0 20px 0 !important;
    padding: 20px 10px 0 10px !important;
  }
}
</style>
