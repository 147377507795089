<template>
  <i class="stop-icon">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 19">
      <rect :fill="color" x="6" y="6" width="12" height="12" />
    </svg>
  </i>
</template>

<script>
export default {
  name: "StopIcon",
  props: {
    color: {
      type: String,
      default: "#ffffff", // Default fill color is white
    },
  },
};
</script>

<style scoped>
.stop-icon {
  display: inline-block;
  width: 0.9em;
  height: 0.9em;
  position: relative;
  top: -2px;
  left: -1px;
}
</style>
