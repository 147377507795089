<template>
  <div class="song" v-loading.fullscreen.lock="loading">
    <h1>Recordings</h1>

    <el-row type="flex" justify="center">
      <el-col
        :xs="24"
        :sm="24"
        :md="8"
        :lg="8"
        :xl="8"
        align="middle"
        style="text-align: center; min-height: 100px"
      >
        <div class="recordingContainer">
          <audio-recorder
            size="medium"
            label="Create a new recording"
            :counter="true"
            @recordingUploaded="handleRecordingUploaded"
          />
        </div>
      </el-col>
    </el-row>
    <el-row type="flex" justify="center">
      <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8" align="middle">
        <el-upload
          class="upload-demo"
          action="/"
          drag
          :before-upload="setUploadFile"
          :http-request="handleUpload"
          :auto-upload="true"
          :on-preview="handlePreview"
          :on-remove="handleRemove"
          :file-list="fileList"
          style="width: 300px"
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">
            Drop file here or <em>click to upload</em>
          </div>
          <div class="el-upload__tip">mp3/m4a files less than 15Mb</div>
        </el-upload>
      </el-col>
    </el-row>

    <el-row type="flex" justify="center" style="margin-top: 20px">
      <el-col :xs="20" :sm="20" :md="18" :lg="12" :xl="12">
        <recordings-list
          ref="recordingsList"
          :song-id="songId"
          :lyrics-id="lyricsId"
          @showUpgradeModal="handleModalRequest"
        ></recordings-list>
      </el-col>
    </el-row>
    <upgrade-modal
      ref="upgradeModal"
      intro="Upgrade to SongPad Plus to download recordings"
    />
  </div>
</template>

<script>
// @ is an alias to /src
import awsconfig from "../../aws-exports";
import { Auth, Storage, API, graphqlOperation } from "aws-amplify";
import uuid from "uuid";
import { createRecording } from "./../../graphql/mutations";
//WebAudioRecorder object
import AudioRecorder from "@/components/AudioRecorder.vue";
import UpgradeModal from "../../components/UpgradeModal.vue";
import RecordingsList from "@/components/RecordingsList.vue";

export default {
  name: "RecordingView",
  components: {
    AudioRecorder,
    UpgradeModal,
    RecordingsList,
  },
  data() {
    return {
      recordingList: [],
      recording: false,
      gumStream: null,
      loading: false,
      user: null,
      fileList: [],
      uploadFile: null,
      audio: null,
    };
  },
  methods: {
    handlePreview() {},
    handleRemove() {},
    setUploadFile(file) {
      this.loading = true;
      this.uploadFile = file;
    },
    async handleUpload() {
      let vm = this;
      (async () => {
        let file;

        const name = this.uploadFile.name;
        const bucket = awsconfig.aws_user_files_s3_bucket;
        const region = awsconfig.aws_user_files_s3_bucket_region;
        const key = `${uuid()}.mp3`;

        await Storage.put(key, this.uploadFile, {
          level: "private",
          contentType: "audio/mpeg",
        });

        file = {
          bucket,
          key,
          region,
        };

        API.graphql(
          graphqlOperation(createRecording, {
            input: {
              name: name,
              file: file,
              recordingSongId: vm.$route.params.id,
            },
          })
        )
          .then((result) => {
            vm.loading = false;
            console.log(result);
            vm.$message({
              type: "success",
              message: "Recording uploaded",
            });
            vm.$refs.recordingsList.getRecordings();
          })
          .catch((e) => {
            console.log(e);
          });
      })();
    },
    handleRecordingUploaded() {
      this.$refs.recordingsList.getRecordings();
    },
    handleModalRequest() {
      this.upgradeModalIntro =
        "Upgrade to SongPad Plus to download recordings.";
      this.$refs.upgradeModal.showModal();
    },
  },
  beforeMount() {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        this.user = user;
      })
      .catch((err) => console.log(err));
    this.getRecordings();
  },
};
</script>

<style>
.recordingContainer {
  display: inline-block;
  height: 180px;
  width: 300px;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  color: #606266;
  font-size: 14px;
  text-align: center;
  margin-bottom: 20px;
}

.recordingContainer .el-button {
  margin-top: 50px;
  margin-bottom: 20px;
}

.el-upload-dragger {
  width: 300px !important;
  overflow-x: hidden;
}

#recordingsList {
  list-style: none;
  padding-left: 0;
}

#recordingsList li {
  display: block;
  margin-bottom: 15px;
  padding-bottom: 15px;
}
</style>
