<template>
  <div class="lyricsai" v-loading.lock="loading">
    <el-tabs>
      <el-row type="flex" justify="start">
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <el-alert
            v-if="!subscription"
            type="warning"
            :closable="false"
            style="width: 100%"
          >
            <p
              style="
                margin-top: 0px;
                color: #222;
                font-size: 15px;
                padding: 0;
                margin-bottom: 5px;
              "
            >
              <span v-if="availableTokens > 2000"
                >Try out Lyric Helper with free tokens:</span
              >
              <span v-if="availableTokens > 0 && availableTokens <= 2000"
                >Try out Lyric Helper with 2,000 free tokens:</span
              >
              <span v-if="availableTokens < 1">You're out of free tokens</span>
            </p>
            <el-progress
              :percentage="Math.round(availableTokens / 20)"
              :format="availableTokensFormat"
              style="margin: 0"
            ></el-progress>
            <p
              style="margin-top: 5px; color: #222; font-size: 15px; padding: 0"
            >
              <a
                @click="$refs.modal.showModal()"
                style="text-decoration: underline"
                >Upgrade</a
              >
              to SongPad Plus to get unlimited usage.
            </p>
          </el-alert>
        </el-col>
      </el-row>
      <el-tab-pane label="Basic">
        <el-row>
          <p>
            Spark new ideas and overcome writers block. Lyric Helper will read
            your lyrics and add some lines at the end.
          </p>

          <el-row>
            <el-form
              ref="form"
              label-width="180px"
              label-position="left"
              size="small"
              style="border-bottom: 1px solid #e9e9e9; margin-bottom: 0px"
            >
              <el-form-item label="Select your genre:">
                <el-select
                  v-model="genre"
                  placeholder="Genre (Optional)"
                  :filterable="true"
                  @change="saveSong({ genre: genre })"
                >
                  <el-option
                    v-for="genre in genres"
                    :key="genre"
                    :label="genre"
                    :value="genre"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="Select your mood:">
                <el-select
                  v-model="mood"
                  placeholder="Mood (Optional)"
                  :filterable="true"
                  @change="saveSong({ mood: mood })"
                >
                  <el-option
                    v-for="mood in moods"
                    :key="mood"
                    :label="mood"
                    :value="mood"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="Write in the style of:">
                <el-input
                  placeholder="Artist (Optional)"
                  v-model="artist"
                ></el-input>
              </el-form-item>
              <el-form-item size="small">
                <el-button @click="checkLabels()" type="primary"
                  >Suggest some lyrics</el-button
                >
              </el-form-item>
            </el-form>
          </el-row>
          <!--<p>Feeling stuck? Let Lyric Helper choose for you:</p>
          <el-form
            ref="form"
            label-width="180px"
            label-position="left"
            size="small"
          >
            <el-form-item label="Randomise everything">
              <el-button @click="generate('surprise')" type="secondary"
                >Surprise me</el-button
              >
            </el-form-item>
          </el-form>-->
        </el-row>
      </el-tab-pane>
      <el-tab-pane label="Advanced">
        <p>Advanced mode helps you write individual lyric lines.</p>
        <div v-if="selectedText">Following the line: "{{ selectedText }}"</div>
        <div v-else>Select a line in your lyrics to get started.</div>
        <el-form
          :inline="true"
          size="small"
          style="margin-top: 15px"
          :disabled="!selectedText"
        >
          <el-form-item label="I need a line that">
            <el-select v-model="advanced.criteria" placeholder="choose...">
              <el-option label="rhymes with" value="rhyme with"></el-option>
              <el-option label="starts with" value="start with"></el-option>
              <el-option label="contains" value="contain"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="the word">
            <el-input v-model="advanced.word"></el-input>
          </el-form-item>

          <el-form-item>
            <el-button
              type="primary"
              @click="generate('advanced')"
              :disabled="!advanced.criteria || !advanced.word"
              >Suggest some lines</el-button
            >
          </el-form-item>
        </el-form>
        <ul v-if="advanced.suggestions">
          <li
            v-for="suggestion in advanced.suggestions"
            :key="suggestion"
            @mousedown="copyThis"
          >
            {{ suggestion }} <i class="el-icon-copy-document"></i>
          </li>
        </ul>
      </el-tab-pane>
    </el-tabs>
    <upgrade-modal
      intro="Upgrade to SongPad Plus to get unlimited Lyric Helper suggestions:"
      ref="modal"
    ></upgrade-modal>
    <el-dialog
      title="Surprising you..."
      :visible.sync="surprise.visible"
      width="30%"
      append-to-body
    >
      <p style="font-size: 15px; word-break: normal; line-height: 1.6rem">
        Creating
        <span class="surprise-param">{{ surprise.mood.toLowerCase() }}</span>
        lyrics for a
        <span class="surprise-param">{{ surprise.genre.toLowerCase() }}</span>
        song in the style of
        <span class="surprise-param">{{ surprise.artist }}</span>
      </p>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="surprise.visible = false"
          >OK</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
// @ is an alias to /src
import { Auth, API, Analytics, graphqlOperation } from "aws-amplify";
import { createLyricGeneration } from "./../../graphql/mutations";
import uuid from "uuid";
import UpgradeModal from "@/components/UpgradeModal.vue";
import { updateSong } from "./../../graphql/mutations";
import { genres } from "@/config/genres.js";
import { moods } from "@/config/moods.js";
import { artists } from "@/config/artists.js";

export default {
  name: "LyricHelper",
  props: ["text", "selectedText", "song", "lyricsId"],
  components: { UpgradeModal },
  data() {
    return {
      loading: true,
      idea: null,
      availableTokens: 0,
      subscription: null,
      genres: null,
      moods: null,
      mood: null,
      genre: null,
      artist: null,
      surprise: {
        genre: "",
        mood: "",
        artist: "",
        visible: false,
      },
      advanced: {
        criteria: null,
        word: null,
      },
    };
  },
  methods: {
    async checkLabels() {
      let vm = this;
      if (vm.text.includes("[")) {
        vm.generate("standard");
      } else {
        vm.$confirm(
          "For the best results you should label your songs sections like this: [Verse 1], [Chorus], etc. Do you want to continue without section labels?",
          "Warning",
          {
            confirmButtonText: "Continue without labels",
            cancelButtonText: "Stop so I can add labels",
            type: "warning",
          }
        )
          .then(() => {
            vm.generate("standard");
          })
          .catch(() => {
            return;
          });
      }
    },
    async generate(type) {
      this.$gtag.event("button_click", {
        event_category: "engagement",
        event_label: "suggest_some_lyrics",
      });
      let vm = this;
      vm.loading = true;
      try {
        const myInit = {
          body: {
            temperature: 0.5,
            max_tokens: 60,
          },
          headers: { "Content-Type": "application/json" },
        };

        switch (type) {
          case "standard":
            vm.$intercom.trackEvent("Used Lyric Helper basic");
            myInit.body.genre = vm.song.genre;
            myInit.body.mood = vm.song.mood;
            myInit.body.prompt = vm.text;
            myInit.body.artist = vm.artist;
            break;
          case "surprise":
            vm.$intercom.trackEvent("Used Lyric Helper surprise me");
            Array.prototype.random = function () {
              return this[Math.floor(Math.random() * this.length)];
            };

            vm.surprise.genre = genres.random();
            vm.surprise.mood = moods.random();
            vm.surprise.artist = artists.random();

            vm.surprise.visible = true;

            myInit.body.genre = vm.surprise.genre;
            myInit.body.mood = vm.surprise.mood;
            myInit.body.prompt = vm.text;
            myInit.body.artist = vm.surprise.artist;
            myInit.body.temperature = 0.9;
            myInit.body.max_tokens = 100;
            break;
          case "advanced":
            vm.$intercom.trackEvent("Used Lyric Helper advanced");
            vm.advanced.suggestions = [];
            myInit.body.prompt = vm.selectedText;
            myInit.body.criteria = vm.advanced.criteria;
            myInit.body.word = vm.advanced.word;
            myInit.body.temperature = 0.2;
            myInit.body.max_tokens = 200;
            break;
          default:
            break;
        }

        let response = await API.post("ai", "/suggestion", myInit);
        vm.loading = false;

        if (response.upgrade) {
          vm.surprise.visible = false;
          vm.$refs.modal.showModal();
        } else if (response.error) {
          this.$alert(response.body, "Sorry", {
            confirmButtonText: "OK",
          });
        } else {
          if (response.body.flagged) {
            this.updateAvailableTokens();
            this.$alert(
              "The lyrics generated were flagged which means they likely contain hateful, violent, sexual or otherwise offensive content. For safety and legal reasons we can’t allow Lyric Helper to return flagged content. Please be aware that if the lyrics you have written are explicit, violent, hateful or offensive it is likely anything Lyric Helper generates will be flagged and we won’t be able to show it to you. You can try using Lyric Helper again but please also be aware flagged output counts towards your usage even though it is not shown.",
              "Content failed moderation",
              {
                confirmButtonText: "OK",
              }
            );
          } else {
            if (response.body.advanced) {
              this.advanced.suggestions = JSON.parse(response.body.suggestions);
              this.updateAvailableTokens();
            } else {
              this.idea = response.body.idea;
              this.$emit("suggest", this.idea);
              this.updateAvailableTokens();
            }
          }
        }

        const user = await Auth.currentAuthenticatedUser();

        let input = {
          id: uuid(),
          inputTokens: response.body.inputTokens,
          outputTokens: response.body.outputTokens,
          lyricGenerationLyricId: this.lyricsId,
        };

        await API.graphql(
          graphqlOperation(createLyricGeneration, { input: input })
        );

        Analytics.record({
          name: "aiLyricGeneration",
          attributes: {
            songId: this.song.id,
            userId: user.username,
          },
          metrics: {
            inputTokens: response.body.inputTokens,
            outputTokens: response.body.outputTokens,
          },
        });
      } catch (error) {
        vm.loading = false;
        console.log(error);
      }
    },
    async updateAvailableTokens() {
      const user = await Auth.currentAuthenticatedUser({ bypassCache: true });
      this.availableTokens = user.attributes["custom:ai_tokens"];
      if (this.availableTokens == undefined) {
        this.availableTokens = 2000;
      }
      if (this.availableTokens < 1) this.availableTokens = 0;
      this.$intercom.update({
        lyric_tokens: this.availableTokens,
      });
    },
    availableTokensFormat() {
      return `${this.availableTokens}`;
    },
    async updateStripeSubscriptionStatus() {
      const user = await Auth.currentAuthenticatedUser({ bypassCache: true });
      let subscription = user.attributes["custom:stripe_subscription"];
      if (subscription != undefined) {
        this.subscription = subscription;
      }
    },
    async saveSong(input) {
      input.id = this.$route.params.id;
      await API.graphql(graphqlOperation(updateSong, { input: input }));
    },
    copyThis(evt) {
      navigator.clipboard.writeText(evt.currentTarget.innerText);
      this.$notify.info({
        title: "Copied",
        message: evt.currentTarget.innerText + " copied to clipboard",
        duration: 2500,
      });
    },
  },
  computed: {
    enoughText() {
      if (this.text) {
        return true;
      }
      return false;
    },
    percentage() {
      return Math.round(this.availableTokens / 10);
    },
  },
  mounted() {
    this.loading = false;
    this.updateAvailableTokens();
    this.updateStripeSubscriptionStatus();
    this.genres = genres;
    this.moods = moods;
    this.mood = this.song.mood || "";
    this.genre = this.song.genre || "";
  },
};
</script>

<style>
.el-alert .el-alert__description {
  font-size: 15px;
}
.el-alert__content {
  width: 100%;
  padding-bottom: 10px;
}
.surprise-param {
  padding: 2px;
  background: rgba(249, 28, 129, 0.2);
  border-radius: 2px;
}
</style>
