/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createSong = /* GraphQL */ `
  mutation CreateSong(
    $input: CreateSongInput!
    $condition: ModelSongConditionInput
  ) {
    createSong(input: $input, condition: $condition) {
      id
      title
      tone
      mode
      genre
      mood
      bpm
      writers
      tags
      outline
      createdAt
      updatedAt
      demo
      isrc
      lyrics {
        items {
          id
          clientId
          cursorPosition
          changeSet
          content
          originalContent
          chordDurations
          createdAt
          updatedAt
          collaborators
          owner
          __typename
        }
        nextToken
        __typename
      }
      recordings {
        items {
          id
          name
          identityId
          level
          createdAt
          collaborators
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          title
          genre
          mood
          lyrics
          content
          createdAt
          updatedAt
          status
          owner
          __typename
        }
        nextToken
        __typename
      }
      owner
      collaborators
      collaboratorDetails
      ownerDetails
      __typename
    }
  }
`;
export const updateSong = /* GraphQL */ `
  mutation UpdateSong(
    $input: UpdateSongInput!
    $condition: ModelSongConditionInput
  ) {
    updateSong(input: $input, condition: $condition) {
      id
      title
      tone
      mode
      genre
      mood
      bpm
      writers
      tags
      outline
      createdAt
      updatedAt
      demo
      isrc
      lyrics {
        items {
          id
          clientId
          cursorPosition
          changeSet
          content
          originalContent
          chordDurations
          createdAt
          updatedAt
          collaborators
          owner
          __typename
        }
        nextToken
        __typename
      }
      recordings {
        items {
          id
          name
          identityId
          level
          createdAt
          collaborators
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          title
          genre
          mood
          lyrics
          content
          createdAt
          updatedAt
          status
          owner
          __typename
        }
        nextToken
        __typename
      }
      owner
      collaborators
      collaboratorDetails
      ownerDetails
      __typename
    }
  }
`;
export const deleteSong = /* GraphQL */ `
  mutation DeleteSong(
    $input: DeleteSongInput!
    $condition: ModelSongConditionInput
  ) {
    deleteSong(input: $input, condition: $condition) {
      id
      title
      tone
      mode
      genre
      mood
      bpm
      writers
      tags
      outline
      createdAt
      updatedAt
      demo
      isrc
      lyrics {
        items {
          id
          clientId
          cursorPosition
          changeSet
          content
          originalContent
          chordDurations
          createdAt
          updatedAt
          collaborators
          owner
          __typename
        }
        nextToken
        __typename
      }
      recordings {
        items {
          id
          name
          identityId
          level
          createdAt
          collaborators
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          title
          genre
          mood
          lyrics
          content
          createdAt
          updatedAt
          status
          owner
          __typename
        }
        nextToken
        __typename
      }
      owner
      collaborators
      collaboratorDetails
      ownerDetails
      __typename
    }
  }
`;
export const createLyric = /* GraphQL */ `
  mutation CreateLyric(
    $input: CreateLyricInput!
    $condition: ModelLyricConditionInput
  ) {
    createLyric(input: $input, condition: $condition) {
      id
      clientId
      cursorPosition
      changeSet
      content
      originalContent
      chordDurations
      createdAt
      updatedAt
      collaborators
      song {
        id
        title
        tone
        mode
        genre
        mood
        bpm
        writers
        tags
        outline
        createdAt
        updatedAt
        demo
        isrc
        lyrics {
          nextToken
          __typename
        }
        recordings {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        owner
        collaborators
        collaboratorDetails
        ownerDetails
        __typename
      }
      lyricGenerations {
        items {
          id
          inputTokens
          outputTokens
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      owner
      __typename
    }
  }
`;
export const updateLyric = /* GraphQL */ `
  mutation UpdateLyric(
    $input: UpdateLyricInput!
    $condition: ModelLyricConditionInput
  ) {
    updateLyric(input: $input, condition: $condition) {
      id
      clientId
      cursorPosition
      changeSet
      content
      originalContent
      chordDurations
      createdAt
      updatedAt
      collaborators
      song {
        id
        title
        tone
        mode
        genre
        mood
        bpm
        writers
        tags
        outline
        createdAt
        updatedAt
        demo
        isrc
        lyrics {
          nextToken
          __typename
        }
        recordings {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        owner
        collaborators
        collaboratorDetails
        ownerDetails
        __typename
      }
      lyricGenerations {
        items {
          id
          inputTokens
          outputTokens
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      owner
      __typename
    }
  }
`;
export const deleteLyric = /* GraphQL */ `
  mutation DeleteLyric(
    $input: DeleteLyricInput!
    $condition: ModelLyricConditionInput
  ) {
    deleteLyric(input: $input, condition: $condition) {
      id
      clientId
      cursorPosition
      changeSet
      content
      originalContent
      chordDurations
      createdAt
      updatedAt
      collaborators
      song {
        id
        title
        tone
        mode
        genre
        mood
        bpm
        writers
        tags
        outline
        createdAt
        updatedAt
        demo
        isrc
        lyrics {
          nextToken
          __typename
        }
        recordings {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        owner
        collaborators
        collaboratorDetails
        ownerDetails
        __typename
      }
      lyricGenerations {
        items {
          id
          inputTokens
          outputTokens
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      owner
      __typename
    }
  }
`;
export const createRecording = /* GraphQL */ `
  mutation CreateRecording(
    $input: CreateRecordingInput!
    $condition: ModelRecordingConditionInput
  ) {
    createRecording(input: $input, condition: $condition) {
      id
      name
      identityId
      level
      createdAt
      file {
        bucket
        key
        region
        __typename
      }
      collaborators
      song {
        id
        title
        tone
        mode
        genre
        mood
        bpm
        writers
        tags
        outline
        createdAt
        updatedAt
        demo
        isrc
        lyrics {
          nextToken
          __typename
        }
        recordings {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        owner
        collaborators
        collaboratorDetails
        ownerDetails
        __typename
      }
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateRecording = /* GraphQL */ `
  mutation UpdateRecording(
    $input: UpdateRecordingInput!
    $condition: ModelRecordingConditionInput
  ) {
    updateRecording(input: $input, condition: $condition) {
      id
      name
      identityId
      level
      createdAt
      file {
        bucket
        key
        region
        __typename
      }
      collaborators
      song {
        id
        title
        tone
        mode
        genre
        mood
        bpm
        writers
        tags
        outline
        createdAt
        updatedAt
        demo
        isrc
        lyrics {
          nextToken
          __typename
        }
        recordings {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        owner
        collaborators
        collaboratorDetails
        ownerDetails
        __typename
      }
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteRecording = /* GraphQL */ `
  mutation DeleteRecording(
    $input: DeleteRecordingInput!
    $condition: ModelRecordingConditionInput
  ) {
    deleteRecording(input: $input, condition: $condition) {
      id
      name
      identityId
      level
      createdAt
      file {
        bucket
        key
        region
        __typename
      }
      collaborators
      song {
        id
        title
        tone
        mode
        genre
        mood
        bpm
        writers
        tags
        outline
        createdAt
        updatedAt
        demo
        isrc
        lyrics {
          nextToken
          __typename
        }
        recordings {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        owner
        collaborators
        collaboratorDetails
        ownerDetails
        __typename
      }
      updatedAt
      owner
      __typename
    }
  }
`;
export const createLyricGeneration = /* GraphQL */ `
  mutation CreateLyricGeneration(
    $input: CreateLyricGenerationInput!
    $condition: ModelLyricGenerationConditionInput
  ) {
    createLyricGeneration(input: $input, condition: $condition) {
      id
      inputTokens
      outputTokens
      lyric {
        id
        clientId
        cursorPosition
        changeSet
        content
        originalContent
        chordDurations
        createdAt
        updatedAt
        collaborators
        song {
          id
          title
          tone
          mode
          genre
          mood
          bpm
          writers
          tags
          outline
          createdAt
          updatedAt
          demo
          isrc
          owner
          collaborators
          collaboratorDetails
          ownerDetails
          __typename
        }
        lyricGenerations {
          nextToken
          __typename
        }
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateLyricGeneration = /* GraphQL */ `
  mutation UpdateLyricGeneration(
    $input: UpdateLyricGenerationInput!
    $condition: ModelLyricGenerationConditionInput
  ) {
    updateLyricGeneration(input: $input, condition: $condition) {
      id
      inputTokens
      outputTokens
      lyric {
        id
        clientId
        cursorPosition
        changeSet
        content
        originalContent
        chordDurations
        createdAt
        updatedAt
        collaborators
        song {
          id
          title
          tone
          mode
          genre
          mood
          bpm
          writers
          tags
          outline
          createdAt
          updatedAt
          demo
          isrc
          owner
          collaborators
          collaboratorDetails
          ownerDetails
          __typename
        }
        lyricGenerations {
          nextToken
          __typename
        }
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteLyricGeneration = /* GraphQL */ `
  mutation DeleteLyricGeneration(
    $input: DeleteLyricGenerationInput!
    $condition: ModelLyricGenerationConditionInput
  ) {
    deleteLyricGeneration(input: $input, condition: $condition) {
      id
      inputTokens
      outputTokens
      lyric {
        id
        clientId
        cursorPosition
        changeSet
        content
        originalContent
        chordDurations
        createdAt
        updatedAt
        collaborators
        song {
          id
          title
          tone
          mode
          genre
          mood
          bpm
          writers
          tags
          outline
          createdAt
          updatedAt
          demo
          isrc
          owner
          collaborators
          collaboratorDetails
          ownerDetails
          __typename
        }
        lyricGenerations {
          nextToken
          __typename
        }
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createReview = /* GraphQL */ `
  mutation CreateReview(
    $input: CreateReviewInput!
    $condition: ModelReviewConditionInput
  ) {
    createReview(input: $input, condition: $condition) {
      id
      title
      genre
      mood
      lyrics
      content
      createdAt
      updatedAt
      status
      song {
        id
        title
        tone
        mode
        genre
        mood
        bpm
        writers
        tags
        outline
        createdAt
        updatedAt
        demo
        isrc
        lyrics {
          nextToken
          __typename
        }
        recordings {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        owner
        collaborators
        collaboratorDetails
        ownerDetails
        __typename
      }
      owner
      __typename
    }
  }
`;
export const updateReview = /* GraphQL */ `
  mutation UpdateReview(
    $input: UpdateReviewInput!
    $condition: ModelReviewConditionInput
  ) {
    updateReview(input: $input, condition: $condition) {
      id
      title
      genre
      mood
      lyrics
      content
      createdAt
      updatedAt
      status
      song {
        id
        title
        tone
        mode
        genre
        mood
        bpm
        writers
        tags
        outline
        createdAt
        updatedAt
        demo
        isrc
        lyrics {
          nextToken
          __typename
        }
        recordings {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        owner
        collaborators
        collaboratorDetails
        ownerDetails
        __typename
      }
      owner
      __typename
    }
  }
`;
export const deleteReview = /* GraphQL */ `
  mutation DeleteReview(
    $input: DeleteReviewInput!
    $condition: ModelReviewConditionInput
  ) {
    deleteReview(input: $input, condition: $condition) {
      id
      title
      genre
      mood
      lyrics
      content
      createdAt
      updatedAt
      status
      song {
        id
        title
        tone
        mode
        genre
        mood
        bpm
        writers
        tags
        outline
        createdAt
        updatedAt
        demo
        isrc
        lyrics {
          nextToken
          __typename
        }
        recordings {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        owner
        collaborators
        collaboratorDetails
        ownerDetails
        __typename
      }
      owner
      __typename
    }
  }
`;
export const createSyncOpportunity = /* GraphQL */ `
  mutation CreateSyncOpportunity(
    $input: CreateSyncOpportunityInput!
    $condition: ModelSyncOpportunityConditionInput
  ) {
    createSyncOpportunity(input: $input, condition: $condition) {
      id
      title
      description
      soundalike
      era
      tempo
      format
      rights
      term
      fee
      submission
      deadline
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateSyncOpportunity = /* GraphQL */ `
  mutation UpdateSyncOpportunity(
    $input: UpdateSyncOpportunityInput!
    $condition: ModelSyncOpportunityConditionInput
  ) {
    updateSyncOpportunity(input: $input, condition: $condition) {
      id
      title
      description
      soundalike
      era
      tempo
      format
      rights
      term
      fee
      submission
      deadline
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteSyncOpportunity = /* GraphQL */ `
  mutation DeleteSyncOpportunity(
    $input: DeleteSyncOpportunityInput!
    $condition: ModelSyncOpportunityConditionInput
  ) {
    deleteSyncOpportunity(input: $input, condition: $condition) {
      id
      title
      description
      soundalike
      era
      tempo
      format
      rights
      term
      fee
      submission
      deadline
      createdAt
      updatedAt
      __typename
    }
  }
`;
