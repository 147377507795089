<template>
  <div class="lyrics" v-loading.fullscreen.lock="fullscreenLoading">
    <el-row :gutter="10" class="hidden-sm-and-down">
      <el-col :xs="24" :sm="24" :md="14" :lg="14" :xl="14">
        <el-row type="flex">
          <h1 style="text-align: left; margin-right: 10px">Lyrics & Chords:</h1>
          <el-button type="text" size="small" @click="showChordInfoModal()"
            >(How to add chords)</el-button
          >

          <el-dropdown
            @command="handleExport"
            trigger="click"
            size="small"
            style="
              display: flex;
              align-items: center;
              position: relative;
              height: 30px;
              margin-top: 17px;
              margin-left: 20px;
              margin-bottom: 0px;
            "
          >
            <span
              class="el-dropdown-link"
              style="
                font-weight: 500;
                font-size: 12px;
                color: rgba(249, 28, 129, 1);
              "
            >
              Download / Print<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="chordpro" icon="el-icon-download"
                >Chord Pro File</el-dropdown-item
              >
              <el-dropdown-item command="pdf" icon="el-icon-download"
                >PDF File</el-dropdown-item
              >
              <el-dropdown-item command="text" icon="el-icon-download"
                >Plain Text File</el-dropdown-item
              >
              <el-dropdown-item command="midi" icon="el-icon-download"
                >MIDI File (Chords Only)</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
        </el-row>
        <div
          v-loading="lyricsUpdating"
          element-loading-text="Your co-writer is typing..."
          id="lyric-pad"
          @click.left="focusLyrics"
          class="grid-content"
          v-on:mouseup="getSelectionText"
        >
          <div id="syllables" v-html="syllables"></div>
          <div class="hintsSwitch">
            <el-switch v-model="hints" active-text="show hints"> </el-switch>
            <hints-info></hints-info>
          </div>
          <div id="anotated-lyrics" v-html="anotatedLyrics" v-if="hints"></div>
          <div id="chord-highlights" v-html="chordHighlights"></div>
          <textarea
            autocorrect="off"
            autocomplete="off"
            id="lyric-entry"
            ref="editor"
            v-model="lyrics"
            v-on:keyup="saveLyrics"
            v-on:mouseup="getSelectionText"
            v-on:touchend="getSelectionText"
          >
          </textarea>
          <review-prompt
            v-if="lyrics"
            :chords="chords"
            :lyrics="lyrics"
            :songId="songId"
          />
          <div style="clear: both"></div>
        </div>
      </el-col>
      <el-col :xs="24" :sm="24" :md="10" :lg="10" :xl="10">
        <div id="lyric-tools" class="bg-white">
          <transition-group>
            <h1 key="not-selected">Tools:</h1>
          </transition-group>
          <el-tabs @tab-click="handleTabClick">
            <el-tab-pane label="Rhymes">
              <el-tabs>
                <el-tab-pane label="Perfect">
                  <p v-if="!selectedText">
                    Select a word or phrase in your lyrics to see rhymes
                  </p>
                  <p v-else>Click on any rhyme to copy it to your clipboard</p>
                  <el-tag
                    v-for="(rhyme, index) in rhymes"
                    size="small"
                    style="margin: 2px"
                    @click="copyThis"
                    :key="rhyme + index"
                    >{{ rhyme.word }}</el-tag
                  >
                </el-tab-pane>
                <el-tab-pane label="Near">
                  <p v-if="!selectedText">
                    Select a word or phrase in your lyrics to see rhymes
                  </p>
                  <p v-else>Click on any rhyme to copy it to your clipboard</p>
                  <el-tag
                    v-for="(rhyme, index) in nearRhymes"
                    size="small"
                    style="margin: 2px"
                    @click="copyThis"
                    :key="rhyme + index"
                    >{{ rhyme.word }}</el-tag
                  >
                </el-tab-pane>
              </el-tabs>
            </el-tab-pane>
            <el-tab-pane label="Thesaurus">
              <el-tabs>
                <el-tab-pane label="Strict synonyms">
                  <p v-if="!selectedText">
                    Select a word or phrase in your lyrics to see synonyms
                  </p>
                  <p v-else>
                    Click on any synonym to copy it to your clipboard
                  </p>
                  <el-tag
                    v-for="(synonym, index) in synonyms"
                    size="small"
                    style="margin: 2px"
                    @click="copyThis"
                    :key="synonym + index"
                    >{{ synonym }}</el-tag
                  >
                </el-tab-pane>
                <el-tab-pane label="Similar meanings">
                  <p v-if="!selectedText">
                    Select a word or phrase in your lyrics to see words with
                    similar meanings
                  </p>
                  <p v-else>Click on any word to copy it to your clipboard</p>
                  <el-tag
                    v-for="(word, index) in similar"
                    size="small"
                    style="margin: 2px"
                    @click="copyThis"
                    :key="index"
                    >{{ word }}</el-tag
                  >
                </el-tab-pane>
                <el-tab-pane label="Antonyms">
                  <p v-if="!selectedText">
                    Select a word or phrase in your lyrics to see antonyms
                  </p>
                  <p v-else>
                    Click on any antonym to copy it to your clipboard
                  </p>
                  <el-tag
                    v-for="(antonym, index) in antonyms"
                    size="small"
                    style="margin: 2px"
                    @click="copyThis"
                    :key="index"
                    >{{ antonym }}</el-tag
                  >
                </el-tab-pane>
              </el-tabs>
            </el-tab-pane>
            <el-tab-pane label="Lyric Helper">
              <lyricsai
                v-if="song"
                :text="promptText"
                :selectedText="selectedText"
                @suggest="insertText($event)"
                :song="song"
                :lyrics-id="lyricsId"
              ></lyricsai>
            </el-tab-pane>
            <el-tab-pane label="Chords">
              <chord-progressions
                :chords="chords"
                :song="song"
                v-if="song"
                @update:bpm="updateBpm"
                @update:mode="updateMode"
                @update:tone="updateTone"
              ></chord-progressions>
            </el-tab-pane>
            <!-- tab pane for recordings -->
            <el-tab-pane label="Recordings">
              <recordings-list
                ref="recordingsList"
                :song-id="songId"
                :lyrics-id="lyricsId"
                :client-id="clientId"
                @showUpgradeModal="handleModalRequest"
              ></recordings-list>
            </el-tab-pane>
          </el-tabs>
        </div>
      </el-col>
    </el-row>

    <el-tabs
      v-model="activeMain"
      @tab-click="handleMainClick"
      class="hidden-md-and-up"
    >
      <el-tab-pane label="Lyrics" name="lyrics">
        <el-col :xs="24" :sm="24" :md="14" :lg="14" :xl="14">
          <div
            id="lyric-pad"
            @click.left="focusLyrics"
            class="grid-content"
            v-on:keyup.enter="saveLyrics"
            v-on:keyup.space="saveLyrics"
          >
            <div id="syllables" v-html="syllables"></div>
            <div class="hintsSwitch">
              <el-switch v-model="hints" active-text="show hints"> </el-switch>
              <hints-info></hints-info>
            </div>
            <div
              id="anotated-lyrics"
              v-html="anotatedLyrics"
              v-if="hints"
            ></div>
            <textarea
              autocorrect="off"
              autocomplete="off"
              id="lyric-entry"
              ref="mobEditor"
              v-model="lyrics"
              v-on:keyup="saveLyrics"
              v-on:mouseup="getSelectionText"
              v-on:touchend="getSelectionText"
            >
            </textarea>
            <div style="clear: both"></div>
          </div>
        </el-col>
      </el-tab-pane>
      <el-tab-pane label="Tools" name="lyric-tools">
        <el-col :xs="24" :sm="24" :md="10" :lg="10" :xl="10">
          <div id="lyric-tools" class="grid-content bg-white">
            <el-input
              class="hidden-md-and-up"
              placeholder="Word or phrase..."
              v-model="selectedText"
              @blur="
                getRhymes();
                getSynonyms();
                getSimplifications();
                getSimilar();
                getAntonyms();
              "
              style="margin-top: 20px"
            ></el-input>
            <el-tabs @tab-click="handleTabClick">
              <el-tab-pane label="Rhymes">
                <el-tabs>
                  <el-tab-pane label="Perfect">
                    <el-tag
                      v-for="rhyme in rhymes"
                      style="margin: 2px"
                      @click="copyThis"
                      :key="rhyme.word"
                      >{{ rhyme.word }}</el-tag
                    >
                  </el-tab-pane>
                  <el-tab-pane label="Near">
                    <el-tag
                      v-for="rhyme in nearRhymes"
                      style="margin: 2px"
                      @click="copyThis"
                      :key="rhyme.word"
                      >{{ rhyme.word }}</el-tag
                    >
                  </el-tab-pane>
                </el-tabs>
              </el-tab-pane>
              <el-tab-pane label="Synonyms">
                <el-tabs>
                  <el-tab-pane label="Strict synonyms">
                    <el-tag
                      v-for="synonym in synonyms"
                      :key="synonym"
                      style="margin: 2px"
                      @click="copyThis"
                      >{{ synonym }}</el-tag
                    >
                  </el-tab-pane>
                  <el-tab-pane label="Similar meanings">
                    <el-tag
                      v-for="word in similar"
                      :key="word"
                      style="margin: 2px"
                      @click="copyThis"
                      >{{ word }}</el-tag
                    >
                  </el-tab-pane>
                </el-tabs>
              </el-tab-pane>
              <el-tab-pane label="Antonyms">
                <el-tag
                  v-for="antonym in antonyms"
                  style="margin: 2px"
                  :key="antonym"
                  @click="copyThis"
                  >{{ antonym }}</el-tag
                >
              </el-tab-pane>
              <el-tab-pane label="Lyric Helper (NEW)">
                <lyricsai
                  v-if="song"
                  :text="promptText"
                  :selectedText="selectedText"
                  :song="song"
                ></lyricsai>
              </el-tab-pane>
            </el-tabs>
          </div>
        </el-col>
      </el-tab-pane>
    </el-tabs>
    <chords-info-modal ref="chordsInfo" />
    <chord-roll
      :song="song"
      :lyric-id="lyricsId"
      :chords="chords"
      ref="chordRoll"
      :start="chordsStartIndex"
      :end="chordsEndIndex"
      :client-id="clientId"
      @recordingUploaded="$refs.recordingsList.getRecordings()"
    />
    <upgrade-modal :intro="upgradeModalIntro" ref="upgradeModal" />
  </div>
</template>

<script>
// @ is an alias to /src
import { API, Auth, graphqlOperation } from "aws-amplify";
//import striptags from 'striptags'
import syllable from "syllable";
import fuzz from "wordfuzz";
//import thesaurus from 'thesaurus'
import simpler from "simpler-words";
import nlp from "compromise";
import gql from "graphql-tag";
import uuid from "uuid";
import { createLyric, updateLyric } from "./../../graphql/mutations";
import { onUpdateLyricCollab } from "./../../graphql/subscriptions";
import LyricsAi from "./LyricsAi.vue";
import { isChord, lineIsOnlyChords } from "@/helpers/chord-detection";
import ChordsInfoModal from "@/components/ChordsInfoModal.vue";
var Changeset = require("changesets").Changeset;
var dmp = require("diff_match_patch"),
  engine = new dmp.diff_match_patch();
import ChordProgressions from "@/components/ChordProgressions.vue";
import ChordRoll from "@/components/ChordRoll.vue";
import HintsInfo from "@/components/HintsInfo.vue";
import { createCP } from "simplechordpro";
import html2pdf from "html2pdf.js";
import songpadLogo from "@/assets/logo.png";
import UpgradeModal from "@/components/UpgradeModal.vue";
import RecordingsList from "@/components/RecordingsList.vue";
import ReviewPrompt from "@/components/ReviewPrompt.vue";

const eprime_plugin = {
  words: {
    am: "Eprime",
    is: "Eprime",
    are: "Eprime",
    was: "Eprime",
    were: "Eprime",
    be: "Eprime",
    being: "Eprime",
    been: "Eprime",
  },
  tags: {
    Eprime: {
      isA: "Verb",
    },
  },
};
//okay!
nlp.plugin(eprime_plugin);
const CLIENTID = uuid();

export default {
  name: "LyricEditor",
  data() {
    return {
      activeMain: "lyrics",
      fullscreenLoading: true,
      songId: null,
      song: null,
      lyricsId: null,
      lyrics: null,
      originalLyrics: null,
      lyricsVersion: null,
      anotatedLyrics: "",
      chordHighlights: "",
      syllables: "",
      selectedText: "",
      promptText: "",
      rhymes: [],
      nearRhymes: [],
      synonyms: [],
      antonyms: [],
      similar: [],
      simplifications: [],
      hints: false,
      subscriber: null,
      myCursorPosition: 0,
      lyricsUpdating: false,
      lastRemoteUpdate: null,
      chords: null,
      chordsStartIndex: null,
      chordsEndIndex: null,
      clientId: CLIENTID,
      upgradeModalIntro:
        "Download your song as a ChordPro, PDF, text or MIDI file with SongPad Plus.",
    };
  },
  components: {
    lyricsai: LyricsAi,
    "chords-info-modal": ChordsInfoModal,
    "chord-progressions": ChordProgressions,
    "chord-roll": ChordRoll,
    "hints-info": HintsInfo,
    "upgrade-modal": () => import("@/components/UpgradeModal.vue"),
    UpgradeModal,
    RecordingsList,
    ReviewPrompt,
  },
  methods: {
    focusLyrics() {
      this.$refs.editor.focus();
    },
    async loadLyrics() {
      let vm = this;
      try {
        const result = await API.graphql({
          query: gql(`query GetSong($id: ID!) {
              getSong(id: $id) {
                id
                title
                genre
                mood
                tone
                mode
                bpm
                lyrics {
                  items {
                    id
                    content
                    }
                  }
                }
            }
           `),
          variables: {
            id: this.songId,
          },
          fetchPolicy: "network-only",
        });

        vm.song = result.data.getSong;

        // Set up default values if they don't exist
        if (!vm.song.tone) {
          vm.song.tone = "C";
        }

        if (!vm.song.mode) {
          vm.song.mode = "Major";
        }

        if (!vm.song.bpm) {
          vm.song.bpm = 120;
        }

        if (result.data.getSong.lyrics.items.length < 1) {
          vm.lyrics = `# Below is an example song
# Delete this when ready to write your song

# Label your sections [Verse] or [Chorus]
# Chords can be added on a separate lines above lyrics

[Verse 1]
C            G/B
Sometimes I can't find the words
Am             F
To say just how I feel
C            G/B
Sometimes I just need you here
Am             F
To know that this is real

[Chorus]
C           G/B
I love you, I love you
Am                    F
Even more than I can say`;
          vm.originalLyrics = vm.lyrics;
          vm.saveLyrics();
          vm.fullscreenLoading = false;
          return;
        }
        vm.lyrics = result.data.getSong.lyrics.items[0].content;
        vm.lyricsId = result.data.getSong.lyrics.items[0].id;
        //vm.lyricsVersion = result.data.getSong.lyrics.items[0].version;
        vm.originalLyrics = vm.lyrics;
        vm.countSyllables();
        vm.highlightChords();
        vm.highlightWordTypes();
        vm.fullscreenLoading = false;
      } catch (error) {
        console.log(error);
      }
    },
    async saveLyrics(event) {
      if (this.lyricsUpdating) return; // don't save while update is in progress
      if (event) {
        let skipSave = ["Up", "Down", "Left", "Right", "Escape", "Shift"];
        if (skipSave.includes(event.key)) {
          return;
        }
      }
      let vm = this;
      vm.getCursorPosition();
      if (!this.lyricsId) {
        this.lyricsId = uuid();
        let input = {
          id: this.lyricsId,
          clientId: CLIENTID,
          content: this.lyrics,
          cursorPosition: this.myCursorPosition,
          lyricSongId: this.songId,
        };
        await API.graphql(graphqlOperation(createLyric, { input: input }));
      } else {
        this.lyricsVersion++;

        let diff = engine.diff_main(this.originalLyrics, this.lyrics);
        let changeset = Changeset.fromDiff(diff);
        let serialized = changeset.pack();

        let input = {
          id: this.lyricsId,
          clientId: CLIENTID,
          cursorPosition: this.myCursorPosition,
          content: this.lyrics,
          originalContent: this.originalLyrics,
          changeSet: serialized,
          //expectedVersion: this.lyricsVersion
        };

        await API.graphql(graphqlOperation(updateLyric, { input: input }));
      }

      //this.originalLyrics = this.lyrics
    },
    getSelectionText() {
      var text = "";
      this.getCursorPosition();
      if (window.getSelection) {
        text = window.getSelection().toString();
      } else if (document.selection && document.selection.type != "Control") {
        text = document.selection.createRange().text;
      }
      this.selectedText = text;
      this.getRhymes();
      this.getSynonyms();
      this.getSimplifications();
      this.getSimilar();
      this.getAntonyms();
      this.setPromptText();
      this.highlightChords();
    },
    copyThis(evt) {
      navigator.clipboard.writeText(evt.target.innerText);
      this.$notify.info({
        title: "Copied",
        message: evt.target.innerText + " copied to clipboard",
        duration: 2500,
      });
    },
    highlightWordTypes() {
      this.anotatedLyrics = "";
      var lines = this.lyrics.split("\n");
      lines.forEach((line) => {
        if (lineIsOnlyChords(line) || line.indexOf("[") > -1) {
          this.anotatedLyrics += "<br />";
        } else {
          this.anotatedLyrics +=
            nlp(line)
              .out("html")
              .replace(/&nbsp;+/g, " ") + "<br />";
        }
      });
    },
    highlightChords() {
      let vm = this;
      vm.chordHighlights = "";
      const editor = this.$refs.editor;
      const startIndex = editor.selectionStart;
      const endIndex = editor.selectionEnd;

      let currentChordIndex = 0;
      let chordsInSelection = [];
      let textToHere = "";

      let lines = vm.lyrics.split("\n");
      let chords = [];
      for (let i = 0; i < lines.length; i++) {
        const line = lines[i];
        if (lineIsOnlyChords(line)) {
          let words = line.split(/( )/g);
          for (let j = 0; j < words.length; j++) {
            const word = words[j];
            textToHere += word;
            if (isChord(word)) {
              chords.push(word);
              vm.chordHighlights += `<span class="chord">${word}</span>`;
              if (
                startIndex <= textToHere.length &&
                endIndex >= textToHere.length - word.length
              ) {
                chordsInSelection.push(currentChordIndex);
              }
              currentChordIndex++;
            } else {
              vm.chordHighlights += word;
            }
            if (j === words.length - 1) {
              // last word in line
              vm.chordHighlights += "<br />";
              textToHere += "\n";
            }
          }
        } else {
          vm.chordHighlights += line + "<br />";
          textToHere += line + "\n";
        }
      }
      vm.chordsStartIndex = chordsInSelection[0];
      vm.chordsEndIndex = chordsInSelection[chordsInSelection.length - 1];
      this.chords = chords;
    },
    countSyllables() {
      this.syllables = null;
      var linesSyllables = [];
      if (this.lyrics) {
        var lines = this.lyrics.split("\n");
        lines.forEach((line) => {
          if (
            line.indexOf("#") > -1 ||
            line.indexOf("[") > -1 ||
            line.indexOf("{") > -1 ||
            lineIsOnlyChords(line)
          ) {
            linesSyllables.push("&nbsp;");
          } else {
            var count = syllable(line);
            if (count > 0) {
              linesSyllables.push(count);
            } else {
              linesSyllables.push("&nbsp;");
            }
          }
        });
        this.syllables = linesSyllables.join("<br />").trim();
      }
    },
    getRhymes() {
      fuzz()
        .rhymesWith(this.selectedText)
        .ask()
        .then((data) => {
          this.rhymes = data;
        });
      fuzz()
        .nearRhymesWith(this.selectedText)
        .ask()
        .then((data) => {
          this.nearRhymes = data;
        });
    },
    getSynonyms() {
      this.synonyms = [];
      fuzz()
        .synonym(this.selectedText)
        .ask()
        .then((data) => {
          data.forEach((item) => {
            this.synonyms.push(item.word);
          });
        });
    },
    getSimilar() {
      this.similar = [];
      fuzz()
        //.synonym(this.selectedText)
        .meansLike(this.selectedText)
        .ask()
        .then((data) => {
          data.forEach((item) => {
            this.similar.push(item.word);
          });
        });
    },
    getAntonyms() {
      this.antonyms = [];
      fuzz()
        .antonym(this.selectedText)
        .moreGeneral()
        .ask()
        .then((data) => {
          data.forEach((item) => {
            this.antonyms.push(item.word);
          });
        });
    },
    getSimplifications() {
      this.simplifications = simpler(this.selectedText.toLowerCase());
    },
    handleClick() {},
    handleMainClick() {},
    handleTabClick(tab) {
      if (tab.index == 3) {
        this.$gtag.event("tab_clicked", {
          event_category: "engagement",
          event_label: "lyric_helper_tab_clicked",
        });
      }
    },
    insertText(text) {
      this.lyrics = this.lyrics + text;
      this.saveLyrics();
      this.countSyllables();
      this.highlightChords();
    },
    setPromptText() {
      let promptText = "";
      this.promptText = "";
      let vm = this;
      /*
      if (this.selectedText.length > 0) {
        promptText = this.selectedText
      }
      let editor = this.$refs.editor
      if (editor) {
        var startPos = editor.selectionStart,
        endPos = editor.selectionEnd,
        cursorPos = startPos,
        tmpStr = editor.value
        promptText = tmpStr.substring(0, startPos);
      } else {
        promptText = this.lyrics
      } */
      promptText = this.lyrics;
      let lines = promptText.split("\n");
      lines.forEach((line) => {
        if (lineIsOnlyChords(line) == false) {
          vm.promptText += line + "\n";
        }
      });
    },
    showChordInfoModal() {
      this.$refs.chordsInfo.showModal();
    },
    getCursorPosition() {
      this.myCursorPosition = this.$refs.editor.selectionStart;
    },
    setCursorPosition(el, pos) {
      el.focus();
      el.setSelectionRange(pos, pos);
    },
    updateBpm(bpm) {
      this.song.bpm = bpm;
    },
    updateTone(tone) {
      this.song.tone = tone;
    },
    updateMode(mode) {
      this.song.mode = mode;
    },
    async handleExport(command) {
      // if the user has a subscription, create the file else show the upgrade modal
      let response = await Auth.currentAuthenticatedUser({
        bypassCache: true,
      });

      var subscription = response.attributes["custom:stripe_subscription"];
      if (subscription != undefined) {
        this.$intercom.trackEvent("Downloaded song as " + command);
        if (command === "chordpro") {
          let chordProText = `{title: ${this.song.title}} \n\n`;
          chordProText += createCP(this.lyrics);
          const blob = new Blob([chordProText], { type: "text/plain" });
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.download = `${this.song.title}.chordpro`;
          link.click();
          window.URL.revokeObjectURL(url);
        }
        if (command === "text") {
          let textContent = `Title: ${this.song.title} \n\n`;
          textContent += this.lyrics;
          const blob = new Blob([textContent], { type: "text/plain" });
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.download = `${this.song.title}.txt`;
          link.click();
          window.URL.revokeObjectURL(url);
        }
        if (command === "pdf") {
          const pdfElement = document.createElement("div");
          pdfElement.className = "pdf-content";
          pdfElement.innerHTML = `<h1>${this.song.title}</h1><br /><br /><div style="font-family: "Fira Code", monospace !important;">${this.lyrics}</div>`;

          // Create a wrapper div element
          const wrapperElement = document.createElement("div");
          wrapperElement.className = "pdf-logo-wrapper"; // Add a class to the wrapper element

          // Create an img element
          const imgElement = document.createElement("img");
          imgElement.src = songpadLogo;
          imgElement.className = "pdf-logo"; // Add a class to the img element

          // Append the img element to the wrapper element
          wrapperElement.appendChild(imgElement);

          // Append the wrapper element to the PDF container
          pdfElement.appendChild(wrapperElement);

          html2pdf()
            .from(pdfElement)
            .set({
              margin: 15,
              filename: `${this.song.title}.pdf`,
              html2canvas: { dpi: 192, letterRendering: true },
              jsPDF: { unit: "pt", format: "a4", orientation: "portrait" },
            })
            .save();
        }
        if (command == "midi") {
          this.$refs.chordRoll.downloadMidi();
        }
      } else {
        this.upgradeModalIntro =
          "Download your song as a ChordPro, PDF, text or MIDI file with SongPad Plus.";
        this.$refs.upgradeModal.showModal();
        this.$intercom.trackEvent(
          "Tried downloading song as " + command + " without subscription"
        );
      }
    },
    handleModalRequest() {
      this.upgradeModalIntro =
        "Upgrade to SongPad Plus to download recordings.";
      this.$refs.upgradeModal.showModal();
    },
  },
  computed: {},
  async beforeMount() {
    this.songId = this.$route.params.id;
    await this.loadLyrics();
  },
  beforeUnmount() {
    this.saveLyrics();
  },
  beforeDestroy() {
    this.saveLyrics();
  },
  watch: {
    lyrics() {
      this.highlightWordTypes();
      this.highlightChords();
      this.countSyllables();
      this.setPromptText();
    },
    lyricsId(newId) {
      let vm = this;
      try {
        this.subscriber = API.graphql(
          graphqlOperation(onUpdateLyricCollab, {
            id: newId,
          })
        ).subscribe({
          next: async (data) => {
            vm.lastRemoteUpdate = Date.now();
            vm.lyricsUpdating = true;
            vm.lyricsVersion = data.value.data.onUpdateLyricCollab.version;
            if (CLIENTID === data.value.data.onUpdateLyricCollab.clientId) {
              vm.lyricsUpdating = false;
              return;
            }
            // Get current length and cursor position
            let previousPosition = vm.myCursorPosition;
            let currentLyricLength = vm.lyrics.length;

            let diff = engine.diff_main(this.originalLyrics, this.lyrics);
            let localChanges = Changeset.fromDiff(diff);

            let remoteDiff = engine.diff_main(
              this.originalLyrics,
              data.value.data.onUpdateLyricCollab.content
            );
            let remoteChanges = Changeset.fromDiff(remoteDiff);

            let transform = remoteChanges.transformAgainst(localChanges);
            let newLyrics = transform.apply(vm.lyrics);
            vm.lyrics = newLyrics;
            vm.originalLyrics = vm.lyrics;

            let newLyricLength = vm.lyrics.length;

            if (
              data.value.data.onUpdateLyricCollab.cursorPosition <=
              previousPosition
            ) {
              // the other user has changed text before my current cursor position so we need to adjust for the offset
              if (currentLyricLength < newLyricLength) {
                let difference = newLyricLength - currentLyricLength;
                previousPosition = previousPosition + difference;
              } else {
                let difference = currentLyricLength - newLyricLength;
                previousPosition = previousPosition - difference;
              }
            }
            vm.myCursorPosition = previousPosition;

            vm.setCursorPosition(vm.$refs.editor, previousPosition);
            vm.$nextTick(() =>
              vm.setCursorPosition(vm.$refs.editor, previousPosition)
            );

            function delay(time) {
              return new Promise((resolve) => setTimeout(resolve, time));
            }

            let waitTime = 1500;
            await delay(waitTime);
            if (vm.lastRemoteUpdate < Date.now() - waitTime) {
              vm.lyricsUpdating = false;
            }
          },
        });
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss">
#lyric-pad {
  position: relative;
  min-height: 200vh;
  box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.1);
  text-align: left;
  padding: 20px;
  height: auto;
  .el-loading-spinner {
    top: 150px;
  }
}

#lyric-pad:focus {
  outline: none;
}

#anotated-lyrics {
  font-family: "Fira Code", monospace;
  font-size: 14px !important;
  color: transparent;
  position: absolute;
  top: 30px;
  left: 50px;
  font-size: 16px;
  line-height: 24px;
  z-index: 10;
  white-space: normal;
}

#chord-highlights {
  font-family: "Fira Code", monospace;
  font-size: 14px !important;
  color: #fff;
  position: absolute;
  top: 30px;
  left: 50px;
  line-height: 24px;
  z-index: 10;
  white-space: pre;
  span.chord {
    position: relative;
    background: rgba(249, 28, 129, 0.2);
    border-radius: 4px;
    padding: 0 4px;
    margin: auto -4px;
  }
}

#lyric-entry {
  font-family: "Fira Code", monospace;
  font-size: 14px !important;
  position: absolute;
  top: 30px;
  left: 50px;
  background: transparent;
  resize: none;
  border: 0 none;
  width: 80%;
  min-height: 80vh;
  outline: none;
  height: 99%;
  font-size: 16px;
  line-height: 24px;
  padding: 0;
  z-index: 20;
  overflow: hidden;
}

#lyric-tools {
  padding: 0;
  position: relative;
}

#syllables {
  font-family: "Fira Code", monospace;
  font-size: 14px !important;
  background-color: #fff;
  color: #ccc;
  width: 30px;
  position: absolute;
  left: 15px;
  top: 10px;
  padding-top: 21px;
  white-space: pre;
  font-size: 16px;
  line-height: 24px;
  min-height: 95%;
  margin-bottom: 0;
}

.el-tab-pane {
  text-align: left;
  max-height: 80vh;
  overflow-y: scroll;
  margin-bottom: 100px;
}

.nl-Pronoun,
.nl-Noun {
  border-bottom: 2px solid purple;
}

.nl-Verb {
  border-bottom: 2px solid green;
}

.nl-Adverb {
  border-bottom: 2px solid red;
}
.nl-Adjective,
.nl-Determiner,
.nl-Eprime {
  border-bottom: 2px solid orange;
}

.hintsSwitch {
  position: absolute;
  top: 10px;
  left: 0;
  margin-left: 0;
  width: auto;
  z-index: 30;
}

h1.singleline {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

#tab-4 {
  background: rgba(249, 28, 129, 0.1);
  border-radius: 4px;
  font-weight: bold;
  padding-right: 20px;
}

#tab-4::after {
  content: "NEW";
  font-size: 10px;
  background: #f91c81;
  color: #fff;
  border-radius: 4px;
  font-weight: bold;
  margin-left: 5px;
  padding: 2px 5px;
}

.el-loading-mask {
  background-color: rgba(255, 255, 255, 0.75) !important;
}

.pdf-content {
  font-family: "Fira Code", monospace !important;
  white-space: pre;
  .pdf-logo-wrapper {
    position: absolute;
    top: 0; /* Adjust the top position as needed */
    right: 0; /* Adjust the right position as needed */
    z-index: 100;
    .pdf-logo {
      width: 90px; /* Adjust the width as needed */
      height: auto; /* Adjust the height as needed */
    }
  }
}

.pdf-content h1 {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  margin-bottom: 5px;
}

@media only screen and (min-width: 768px) {
  #lyric-pad {
    min-height: 400vh;
    padding-left: 70px;
  }

  #lyric-tools {
    padding: 0 20px;
    position: fixed;
    width: 39%;
    min-height: 0;
  }

  .hintsSwitch {
    left: 100%;
    margin-left: -170px;
    width: 250px;
  }
}

@media screen and (min-width: 1190px) {
  .hintsSwitch {
    top: -45px;
  }
}
</style>
