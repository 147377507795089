<template>
  <el-dialog
    :visible.sync="dialogVisible"
    width="460px"
    append-to-body
    :before-close="hideModal"
  >
    <h2>Share your song:</h2>
    <p style="word-break: break-word">
      Sharing your song allows you to co-write with other songwriters. They will
      be able to edit your song and add more co-writers
    </p>
    <el-row
      v-loading="findingCollaborator"
      type="flex"
      justify="space-between"
      class="add"
    >
      <el-popover
        placement="bottom"
        title="Email not found"
        width="320"
        trigger="manual"
        v-model="userNotFound"
      >
        <div style="word-break: break-word; text-align: left">
          {{ email }} is not currently registered on SongPad. Invite them to
          create a free account and then try adding them again once they have
          signed in.
        </div>
        <el-input
          class="email"
          slot="reference"
          placeholder="Add collaborators by email address"
          v-model="email"
          @focus="userNotFound = false"
        ></el-input>
      </el-popover>

      <el-button size="small" @click="findCollaborator">Add</el-button>
    </el-row>

    <el-row v-loading="loadingCollaborators">
      <p>People with access:</p>
      <div v-if="collaborators && collaborators.length > 0">
        <el-row
          class="collaborator"
          type="flex"
          justify="space-between"
          v-for="collaborator in collaborators"
          :key="collaborator.id"
        >
          <el-row type="flex">
            <avatar
              :username="
                collaborator.given_name + ' ' + collaborator.family_name
              "
              :size="32"
            ></avatar>
            <div style="margin-left: 10px">
              <h4 style="margin: 0">
                {{ collaborator.given_name }} {{ collaborator.family_name }}
              </h4>
              <p style="margin: 0">{{ collaborator.email }}</p>
            </div>
          </el-row>
          <el-button size="small" @click="removeCollaborator(collaborator.id)"
            >Remove</el-button
          >
        </el-row>
      </div>
      <div v-else>
        <p>This song is not currently shared with anyone</p>
      </div>
    </el-row>
    <el-row type="flex" justify="end" style="margin-top: 15px">
      <el-button type="primary" @click="hideModal">Done</el-button>
    </el-row>
  </el-dialog>
</template>

<script>
import { API, graphqlOperation } from "aws-amplify";
import gql from "graphql-tag";
import Avatar from "vue-avatar";
import { updateSong, updateLyric, updateRecording } from "@/graphql/mutations";

export default {
  props: ["songId"],
  components: { Avatar },
  data() {
    return {
      collaborators: null,
      loadingCollaborators: false,
      findingCollaborator: false,
      email: null,
      dialogVisible: false,
      userNotFound: false,
    };
  },
  methods: {
    async loadCollaborators() {
      let vm = this;
      try {
        vm.loadingCollaborators = true;
        const result = await API.graphql({
          query: gql(`query GetSong($id: ID!) {
             getSong(id: $id) {
               collaboratorDetails
               lyrics {
                items {
                  id
                  collaborators
                }
               }
               recordings {
                items {
                  id
                  collaborators
                }
               }
             }
           }
          `),
          variables: {
            id: vm.songId,
          },
          fetchPolicy: "network-only",
        });
        console.log(result.data.getSong);
        vm.song = result.data.getSong;
        vm.collaborators = JSON.parse(result.data.getSong.collaboratorDetails);
        vm.loadingCollaborators = false;
      } catch (error) {
        console.log(error);
        vm.loadingCollaborators = false;
        alert(
          "Sorry, something has gone wrong. Please try again or contact us via chat for help."
        );
      }
    },
    async findCollaborator() {
      try {
        this.findingCollaborator = true;
        const init = {
          body: {
            email: this.email,
          },
          headers: { "Content-Type": "application/json" },
        };

        let response = await API.post("collaborators", "/user", init);
        this.findingCollaborator = false;
        if (response.error) {
          this.$intercom.trackEvent("Tried adding non registered collaborator");
          this.userNotFound = true;
        } else {
          this.$intercom.trackEvent("Added collaborator");
          this.addCollaborator(JSON.parse(response.body));
        }
      } catch (error) {
        this.findingCollaborator = false;
        alert(
          "Sorry, something has gone wrong. Please try again or contact us via chat for help."
        );
      }
    },
    addCollaborator(collaborator) {
      this.loadingCollaborators = true;
      if (this.collaborators === null || this.collaborators === undefined) {
        this.collaborators = [];
      }
      this.collaborators.push(collaborator);
      this.updateCollaborators();
    },
    removeCollaborator(id) {
      this.loadingCollaborators = true;
      this.collaborators = this.collaborators.filter((obj) => obj.id !== id);
      this.updateCollaborators();
    },
    async updateCollaborators() {
      let vm = this;
      try {
        let updatedCollaborators = [];

        vm.loadingCollaborators = true;

        for (let index = 0; index < vm.collaborators.length; index++) {
          const element = vm.collaborators[index];
          updatedCollaborators.push(element.id);
        }

        // update collaborators on each lyric
        for (let index = 0; index < vm.song.lyrics.items.length; index++) {
          const lyric = vm.song.lyrics.items[index];
          let input = {
            id: lyric.id,
            collaborators: updatedCollaborators,
          };
          await API.graphql(graphqlOperation(updateLyric, { input: input }));
        }

        // update collaborators on each recording
        for (let index = 0; index < vm.song.recordings.items.length; index++) {
          const recording = vm.song.recordings.items[index];
          let input = {
            id: recording.id,
            collaborators: updatedCollaborators,
          };
          await API.graphql(
            graphqlOperation(updateRecording, { input: input })
          );
        }

        let song = {
          id: vm.songId,
          collaborators: updatedCollaborators,
        };
        await API.graphql(graphqlOperation(updateSong, { input: song }));
        vm.loadCollaborators();
      } catch (error) {
        alert("Sorry something went wrong");
        console.log(error);
      }
    },
    showModal() {
      this.$intercom.trackEvent("Open share modal");
      this.dialogVisible = true;
      this.loadCollaborators();
    },
    hideModal() {
      this.email = null;
      this.userNotFound = false;
      this.dialogVisible = false;
    },
  },
};
</script>

<style scoped>
.el-row.add {
  border-bottom: #e9e9e9 1px solid;
  padding-bottom: 15px;
}

.el-input.email {
  width: 360px;
}

.el-row.collaborator {
  padding: 10px 0;
  border-bottom: #f2f2f2 1px solid;
}
</style>
