const genres = [
  "Alternative",
  "Blues",
  "Children's Music",
  "Christian & Gospel",
  "Christian Worship",
  "Classical",
  "Comedy",
  "Contemporary Christian",
  "Country",
  "Dance",
  "Drill",
  "Easy Listening",
  "Electronic",
  "Folk",
  "Hip-Hop/Rap",
  "Holiday",
  "J-Pop",
  "Jazz",
  "K-Pop",
  "Latino",
  "New Age",
  "Opera",
  "Pop",
  "R&B/Soul",
  "Reggae",
  "Rock",
  "Vocal",
  "World",
];

export { genres };
