<template>
  <div>
    <div
      style="position: relative"
      v-if="song.mode == 'Major' || song.mode == 'Minor'"
    >
      <div class="circle-container">
        <div class="chord-circle">
          <div
            :class="[
              'chord-highlights',
              { 'first-chord-selected': isFirstChordSelected },
              { 'second-chord-selected': isSecondChordSelected },
            ]"
            :style="getHighlightsStyle()"
          >
            <div class="chord-highlights-center"></div>
          </div>

          <!-- Add this div -->

          <div
            v-for="(chord, index) in chords"
            :key="index"
            class="chord"
            :style="getChordStyle(index)"
            :class="{ selected: chord === selectedChord }"
            @click="selectChord(chord)"
          >
            {{ chord }}
          </div>
        </div>
        <el-button
          style="margin-top: 270px; margin-left: 70px"
          v-if="song.mode == 'Major' || song.mode == 'Minor'"
          type="text"
          @click="showVideo = true"
          >How to use this tool</el-button
        >

        <el-dialog
          title="How to use the Harmonic Scale tool"
          :visible.sync="showVideo"
          width="50%"
          :close-on-click-modal="false"
          :append-to-body="true"
        >
          <iframe
            style="width: 100%; height: 500px"
            src="https://www.loom.com/embed/83731dfa3df44da692f82264d5d9ca0b"
            frameborder="0"
            allowfullscreen="allowfullscreen"
          ></iframe>
        </el-dialog>
      </div>
    </div>

    <div v-else>
      <p>
        Sorry, the harmonic scale tools does not support {{ song.mode }}. Please
        choose either Major or Minor to use this tool.
      </p>
    </div>

    <div class="chord-variations" v-if="chordVariations.length > 0">
      <div v-for="(chord, index) in chordVariations" :key="index">
        <el-tag :key="index"> {{ chord }} </el-tag>

        <chord-player
          :song="song"
          :chords="[chord]"
          ref="chordPlayer"
          :loop="false"
          :clap="false"
          label="Play"
          :key="'chord' + chord + index"
        ></chord-player>
      </div>
    </div>
  </div>
</template>

<script>
import ChordProgressions from "chord-progression";
import ChordPlayer from "./ChordPlayer.vue";
import { Scale } from "tonal";

export default {
  components: {
    ChordPlayer,
  },
  props: {
    song: {
      type: Object,
      default: () => {},
    },
    selectedChord: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      showVideo: false,
    };
  },
  methods: {
    getChordStyle(index) {
      const angle = (index / this.chords.length) * 360; // Calculate angle for each chord
      return {
        transform: `rotate(${angle}deg) translate(100px) rotate(-${angle}deg)`, // Apply transforms
      };
    },
    getHighlightsStyle() {
      const index = this.chords.indexOf(this.selectedChord);
      let rotateValue = 65;

      if (index >= 1 && index <= 6) {
        if (index === 1) {
          rotateValue = 116;
        } else if (index === 2) {
          rotateValue = 168;
        } else if (index === 3) {
          rotateValue = 220;
        } else if (index === 4) {
          rotateValue = 270;
        } else if (index === 5) {
          rotateValue = 323;
        } else if (index === 6) {
          rotateValue = 375;
        }
      }

      return {
        transform: `rotate(${rotateValue}deg)`,
        transition: "transform 0.1s ease-in-out", // Add transition property for smooth animation
      };
    },
    selectChord(chord) {
      this.$emit("update:selectedChord", chord); // Emit the selected chord to the parent component
      this.$intercom.trackEvent("Chord selected on wheel");
    },
  },
  computed: {
    chords() {
      if (this.song.mode === "Major") {
        return ChordProgressions(this.song.tone, "I IV VIIdim IIIm VIm IIm V");
      } else if (this.song.mode === "Minor") {
        console.log(Scale);
        let notes = Scale.get(this.song.tone + " minor").notes;
        console.log(notes);
        return [
          notes[0] + "m",
          notes[3] + "m",
          notes[6],
          notes[2],
          notes[5],
          notes[1] + "dim",
          notes[4] + "m7",
        ];
      }
      return null;
    },
    chordVariations() {
      if (this.song.mode !== "Major" && this.song.mode !== "Minor") {
        return {};
      }
      let index = this.chords.indexOf(this.selectedChord);
      let variations = [];
      switch (index) {
        case 0:
        case 1:
        case 6:
          variations = [
            this.selectedChord,
            this.selectedChord + "7",
            this.selectedChord + "maj7",
            this.selectedChord + "sus",
            this.selectedChord + "sus2",
            this.selectedChord + "sus4",
            this.selectedChord + "5",
            this.selectedChord + "add9",
          ];
          break;
        case 2:
          variations = [
            this.selectedChord,
            this.selectedChord + "7",
            this.selectedChord + "9",
          ];
          break;
        case 3:
        case 4:
        case 5:
          variations = [
            this.selectedChord,
            this.selectedChord + "7",
            this.selectedChord + "9",
            this.selectedChord + "11",
            this.selectedChord + "add9",
            this.selectedChord + "13",
          ];
          break;
        default:
          variations = null;
          break;
      }
      return variations;
    },
    isFirstChordSelected() {
      return this.selectedChord === this.chords[0];
    },
    isSecondChordSelected() {
      return this.selectedChord === this.chords[1];
    },
  },
};
</script>

<style scoped>
.circle-container {
  position: relative;
  margin-top: -25px;
  margin-left: -25px;
  height: 400px;
  width: 400px;
  scale: 0.8;
}

.chord-circle {
  position: absolute;
  top: 15px;
  width: 210px; /* Adjust as needed */
  height: 210px; /* Adjust as needed */
  margin-bottom: 50px;
  overflow: visible;
}

.chord {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  width: 55px;
  text-align: center;
}
.chord-highlights {
  position: absolute;
  top: -20px;
  left: -3px;
  width: 270px;
  height: 270px;
  transform: rotate(165deg);
  background-image: conic-gradient(
    #f91c81 0%,
    #f91c81 14.28%,
    rgba(249, 28, 129, 0.6) 14.28%,
    rgba(249, 28, 129, 0.6) 28.56%,
    rgba(249, 28, 129, 0.4) 28.56%,
    rgba(249, 28, 129, 0.4) 42.84%,
    rgba(249, 28, 129, 0.05) 42.84%,
    rgba(249, 28, 129, 0.05) 57.12%,
    rgba(249, 28, 129, 0.2) 57.12%,
    rgba(249, 28, 129, 0.2) 71.4%,
    rgba(249, 28, 129, 0.4) 71.4%,
    rgba(249, 28, 129, 0.4) 85.68%,
    rgba(249, 28, 129, 0.05) 85.68%,
    rgba(249, 28, 129, 0.05) 100%
  );
  border-radius: 50%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.chord-highlights.first-chord-selected {
  background-image: conic-gradient(
    #f91c81 0%,
    #f91c81 14.28%,
    rgba(249, 28, 129, 0.6) 14.28%,
    rgba(249, 28, 129, 0.6) 28.56%,
    rgba(249, 28, 129, 0.4) 28.56%,
    rgba(249, 28, 129, 0.4) 42.84%,
    rgba(249, 28, 129, 0.05) 42.84%,
    rgba(249, 28, 129, 0.05) 57.12%,
    rgba(249, 28, 129, 0.2) 57.12%,
    rgba(249, 28, 129, 0.2) 71.4%,
    rgba(249, 28, 129, 0.4) 71.4%,
    rgba(249, 28, 129, 0.4) 85.68%,
    rgba(249, 28, 129, 0.6) 85.68%,
    rgba(249, 28, 129, 0.6) 100%
  );
}

.chord-highlights.second-chord-selected {
  background-image: conic-gradient(
    #f91c81 0%,
    #f91c81 14.28%,
    rgba(249, 28, 129, 0.6) 14.28%,
    rgba(249, 28, 129, 0.6) 28.56%,
    rgba(249, 28, 129, 0.4) 28.56%,
    rgba(249, 28, 129, 0.4) 42.84%,
    rgba(249, 28, 129, 0.05) 42.84%,
    rgba(249, 28, 129, 0.05) 57.12%,
    rgba(249, 28, 129, 0.2) 57.12%,
    rgba(249, 28, 129, 0.2) 71.4%,
    rgba(249, 28, 129, 0.4) 71.4%,
    rgba(249, 28, 129, 0.4) 85.68%,
    rgba(249, 28, 129, 0.8) 85.68%,
    rgba(249, 28, 129, 0.8) 100%
  );
}

.chord-highlights-center {
  background: white;
  border-radius: 50%;
  width: 130px;
  height: 130px;
}

.selected {
  color: white;
  font-weight: bold;
  background-color: #f91c81; /* Customize the color for the selected chord */
}

.chord-variations {
  position: absolute;
  top: 10px;
  left: 52%;
}
</style>
