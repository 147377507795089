<template>
  <div>
    <el-row type="flex" justify="space-around">
      <el-col :lg="16" :xl="12">
        <el-row>
          <el-col :span="12">
            <!-- search input -->
            <el-input
              placeholder="Search"
              prefix-icon="el-icon-search"
              clearable
              size="medium"
              v-model="search"
            ></el-input>
          </el-col>
          <el-col :span="12">
            <el-row type="flex" justify="end">
              <el-button
                type="primary"
                icon="el-icon-plus"
                round
                size="medium"
                @click="addOpportunity"
                >New Opportunity</el-button
              ></el-row
            >
            <!-- add button -->
          </el-col>
        </el-row>
      </el-col>
    </el-row>

    <el-row type="flex" justify="space-around">
      <el-col :lg="16" :xl="12">
        <el-table
          :data="
            syncOps.filter(
              (data) =>
                !search ||
                (data.title &&
                  data.title.toLowerCase().includes(search.toLowerCase()))
            )
          "
          style="width: 100%"
          :default-sort="{ prop: 'deadline', order: 'descending' }"
        >
          <el-table-column label="Title" prop="title">
            <template slot-scope="scope">
              <span>{{ scope.row.title }}</span>
            </template>
          </el-table-column>
          <el-table-column label="Fee" prop="fee" sortable>
            <template slot-scope="scope">
              <span>{{ scope.row.fee }}</span>
            </template>
          </el-table-column>
          <el-table-column label="Created" prop="createdAt" sortable>
            <template slot-scope="scope">
              <span>{{ moment(scope.row.createdAt) }}</span>
            </template>
          </el-table-column>
          <el-table-column label="Deadline" prop="deadline" sortable>
            <template slot-scope="scope">
              <span>{{ scope.row.deadline }}</span>
            </template>
          </el-table-column>
          <el-table-column width="100">
            <template slot-scope="scope">
              <el-button
                type="secondary"
                icon="el-icon-edit"
                circle
                size="small"
                @click="editOpportunity(scope.row)"
              ></el-button>
              <el-button
                type="secondary"
                icon="el-icon-delete"
                circle
                size="small"
                @click="deleteOpportunity(scope.row)"
              ></el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { graphqlOperation, API } from "aws-amplify";
import uuid from "uuid/v4";
import moment from "moment";
import { deleteSyncOpportunity } from "../../graphql/mutations";

export default {
  name: "AdminHome",
  data() {
    return {
      search: "",
      syncOps: null,
    };
  },
  methods: {
    goBack() {
      this.$router.push("/");
    },
    async loadOpportunities() {
      // load the opportunites from the graphql api
      try {
        const listSyncOpportunitys = /* GraphQL */ `
          query listSyncOpportunitys($nextToken: String) {
            listSyncOpportunitys(nextToken: $nextToken) {
              items {
                id
                title
                createdAt
                fee
                deadline
              }
              nextToken
            }
          }
        `;
        const syncOps = await API.graphql(
          graphqlOperation(listSyncOpportunitys)
        );
        this.syncOps = syncOps.data.listSyncOpportunitys.items;
        console.log(this.syncOps);
      } catch (err) {
        console.log(err);
      }
    },
    addOpportunity() {
      // create a new opportunity via the graphql api
      const opportunity = {
        id: uuid(),
        title: "New Opportunity",
      };
      const createSyncOpportunity = /* GraphQL */ `
        mutation CreateSyncOpportunity(
          $input: CreateSyncOpportunityInput!
          $condition: ModelSyncOpportunityConditionInput
        ) {
          createSyncOpportunity(input: $input, condition: $condition) {
            id
            title
            description
          }
        }
      `;
      API.graphql(
        graphqlOperation(createSyncOpportunity, { input: opportunity })
      );
      this.$router.push(`/admin/opportunity/${opportunity.id}`);
    },
    editOpportunity(opportunity) {
      // edit an opportunity via the graphql api
      this.$router.push(`/admin/opportunity/${opportunity.id}`);
    },
    async deleteOpportunity(opportunity) {
      // confirm the delete
      try {
        const confirmDelete = confirm(
          `Are you sure you want to delete ${opportunity.title}?`
        );
        if (!confirmDelete) return;

        // delete an opportunity via the graphql api
        let id = opportunity.id;
        await API.graphql(
          graphqlOperation(deleteSyncOpportunity, { input: { id } })
        );
        this.loadOpportunities();
      } catch (err) {
        console.log(err);
      }
    },
    moment(date) {
      if (!date) {
        return;
      }
      return moment(date).format("YYYY-MM-DD");
    },
  },
  async created() {
    await this.loadOpportunities();
  },
};
</script>

<style></style>
