<template>
  <div v-if="song">
    <el-row type="flex" justify="left">
      <el-select
        v-model="chosenTone"
        style="width: 90px"
        placeholder="Select"
        :filterable="true"
        size="small"
        @change="
          saveSong({ tone: chosenTone });
          $emit('update:tone', chosenTone);
        "
      >
        <el-option
          v-for="tone in tones"
          :key="tone"
          :label="tone"
          :value="tone"
        >
        </el-option>
      </el-select>
      &nbsp;
      <el-select
        v-model="chosenMode"
        placeholder="Select"
        size="small"
        @change="
          saveSong({ mode: chosenMode });
          $emit('update:mode', chosenMode);
        "
      >
        <el-option
          v-for="mode in modes"
          :key="mode"
          :label="mode"
          :value="mode"
        >
        </el-option>
      </el-select>
      <el-input-number
        style="margin-left: 5px"
        v-model="chosenBpm"
        :min="60"
        :max="300"
        @change="
          saveSong({ bpm: chosenBpm });
          $emit('update:bpm', chosenBpm);
        "
        size="small"
      ></el-input-number>
      <small style="margin-left: 10px; margin-top: 7px">bpm</small>
    </el-row>

    <el-row>
      <el-tabs v-model="activeName">
        <el-tab-pane label="Common Progressions" name="commonProgressions">
          <div v-if="song.mode === 'Major' || song.mode === 'Minor'">
            <div
              v-for="(progression, progressionIndex) in commonProgressions"
              v-bind:key="progressionIndex"
            >
              <el-tag v-for="(chord, index) in progression" v-bind:key="index">
                {{ chord }}
              </el-tag>

              <chord-player
                :song="song"
                :chords="progression"
                ref="chordPlayer"
                :loop="true"
                :clap="true"
                label="Play"
                :key="'progression' + progressionIndex"
              ></chord-player>

              <el-button
                type="text"
                size="small"
                style="margin-left: 10px"
                @click="copyThis(progression)"
              >
                Copy
              </el-button>
            </div>
          </div>
          <div v-else>
            <p>
              Sorry, common progressions aren't available for
              {{ song.mode }} yet. Please choose either Major or Minor to use
              this tool.
            </p>
          </div>
        </el-tab-pane>

        <el-tab-pane label="Harmonic Scale" name="harmonicScale">
          <chord-circle
            :song="song"
            :selectedChord="selectedChord"
            @update:selectedChord="updateSelectedChord"
          ></chord-circle>
        </el-tab-pane>
      </el-tabs>
    </el-row>
  </div>
</template>

<script>
import { API, graphqlOperation } from "aws-amplify";
import { updateSong } from "../graphql/mutations";
import ChordProgressions from "chord-progression";
import ChordCircle from "./ChordCircle.vue";
import ChordPlayer from "./ChordPlayer.vue";

export default {
  name: "ChordProgressions",
  components: {
    ChordCircle,
    ChordPlayer,
  },
  data: () => ({
    activeName: "commonProgressions",
    selectedChord: "",
    tones: ["C", "C#", "D", "Eb", "E", "F", "F#", "G", "G#", "A", "Bb", "B"],
    modes: [
      "Major",
      "Minor",
      "Dorian",
      "Phrygian",
      "Lydian",
      "Mixolydian",
      "Locrian",
      "Other",
    ],
    chosenTone: "",
    chosenMode: "",
    chosenBpm: 120,
  }),
  props: {
    chords: {
      type: Array,
      default: () => [],
    },
    song: {
      type: Object,
      default: () => {},
    },
    lyricsId: {
      type: String,
      default: "",
    },
  },
  methods: {
    async saveSong(input) {
      input.id = this.$route.params.id;
      await API.graphql(graphqlOperation(updateSong, { input: input }));
    },
    updateSelectedChord(chord) {
      if (chord) {
        this.selectedChord = chord;
      } else {
        let selectedChord = this.chosenTone;
        if (this.chosenMode == "Minor") {
          selectedChord = this.chosenTone + "m";
        }
        this.selectedChord = selectedChord;
      } // Update the selected chord in the parent component
    },
    playChords() {
      this.$refs.chordPlayer.startAudio();
      this.$intercom.trackEvent("Chord progression played");
    },
    copyThis(progression) {
      let chords = progression.join(" ");
      navigator.clipboard.writeText(chords);
      this.$notify.info({
        title: "Copied",
        message: chords + " copied to clipboard",
        duration: 2500,
      });
      this.$intercom.trackEvent("Chord progression copied");
    },
  },
  computed: {
    commonProgressions() {
      let key = this.chosenTone;
      let progressions = [];
      let commons = [];

      switch (this.chosenMode) {
        case "Minor":
          // the chord progressions npm module doesn't support minor keys so we have to convert the minor key to a major key
          switch (key) {
            case "C":
              key = "Eb";
              break;
            case "C#":
              key = "E";
              break;
            case "D":
              key = "F";
              break;
            case "Eb":
              key = "F#";
              break;
            case "E":
              key = "G";
              break;
            case "F":
              key = "Ab";
              break;
            case "F#":
              key = "A";
              break;
            case "G":
              key = "Bb";
              break;
            case "G#":
              key = "B";
              break;
            case "A":
              key = "C";
              break;
            case "Bb":
              key = "Db";
              break;
            case "B":
              key = "D";
              break;
            default:
              break;
          }
          commons = [
            "VIm IV I V",
            "VIm IIIm IIm VIm",
            "VIm IIm IIIm VIm",
            "VIm IIIm IIm V",
            "VIm VIIdim IIIm VIm",
          ];
          break;
        case "Major":
          commons = [
            "I IV V",
            "I V VIm IV",
            "I IV IIm V",
            "I V vim iiim IV I IV V",
            "I vim IV V",
            "iim V I",
            "I IV V IV",
            "vim IV I V",
            "I III IV V",
            "vim IV I V IV",
          ];
          break;
        default:
          break;
      }
      for (let index = 0; index < commons.length; index++) {
        const element = commons[index];
        progressions.push(ChordProgressions(key, element));
      }
      return progressions;
    },
  },
  mounted() {
    this.chosenTone = this.song.tone || "C";
    this.chosenMode = this.song.mode || "Major";
    this.chosenBpm = this.song.bpm || 120;
    this.updateSelectedChord();
  },
  watch: {
    chosenTone: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.updateSelectedChord();
      }
    },
    chosenMode: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.updateSelectedChord();
      }
    },
  },
};
</script>

<style></style>
