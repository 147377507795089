<template>
  <div class="chord-sequence" v-if="chords && chords.length > 0">
    <div class="button-container">
      <chord-player
        ref="chordPlayer"
        :chords="chords"
        :song="song"
        :loop="true"
        :clap="true"
        :button="true"
        icon-color="#f91c81"
        :durations="durations"
        label="Play Chords"
        @current-time="handleCurrentTime"
        :key="`chord-player-${chords}`"
        :start="start"
        :end="end"
        @stopped="handlePlayStopped"
      />
      <audio-recorder
        ref="recorder"
        style="margin-left: 5px; height: 33px"
        @startRecording="handleStarted"
        @stopRecording="handleStop"
        @recordingUploaded="$emit('recordingUploaded')"
      />
      <el-button
        type="secondary"
        size="small"
        round
        style="margin-left: 5px; height: 33px"
        icon="el-icon-download"
        @click="downloadMidi()"
      >
      </el-button>
    </div>

    <div class="scrollable-x" :style="{ width: `${totalWidth()}px` }">
      <div class="chord-container" :style="{ width: `${totalWidth()}px` }">
        <div class="playhead" :style="{ left: `${playheadPosition}%` }"></div>

        <div
          v-for="(chord, index) in chords"
          :key="index"
          :ref="`chord-${index}`"
          class="chord"
          :style="getChordStyle(index)"
          @mousedown="startDrag(index, $event)"
        >
          {{ chord }}
        </div>
      </div>
    </div>
    <upgrade-modal
      intro="Export your chords as a MIDI file with SongPad Plus."
      ref="modal"
    ></upgrade-modal>
  </div>
</template>

<script>
import { API, Auth, graphqlOperation } from "aws-amplify";
import ChordPlayer from "./ChordPlayer.vue";
import { gql } from "graphql-tag";
import { updateLyric } from "../graphql/mutations";
import UpgradeModal from "./UpgradeModal.vue";
import AudioRecorder from "./AudioRecorder.vue";

export default {
  name: "ChordSequence",
  components: {
    ChordPlayer,
    UpgradeModal,
    AudioRecorder,
  },
  props: ["song", "chords", "lyricId", "start", "end", "clientId"],
  data() {
    return {
      durations: [],
      minWidth: 51,
      maxWidth: 408,
      isDragging: false,
      dragIndex: null,
      startX: 0,
      startWidth: 0,
      playheadPosition: 0,
      recording: false,
    };
  },
  methods: {
    getChordStyle(index) {
      return {
        width: `${this.durations[index].width}px`,
      };
    },
    startDrag(chordIndex, event) {
      event.preventDefault();
      this.isDragging = true;
      this.dragIndex = chordIndex;
      this.startX = event.clientX;
      this.startWidth = this.durations[chordIndex].width;
      window.addEventListener("mousemove", this.drag);
      window.addEventListener("mouseup", this.stopDrag);
    },
    drag(event) {
      if (this.isDragging) {
        const offsetX = event.clientX - this.startX;
        let newWidth = this.startWidth + offsetX;

        // Round to the nearest whole multiple of minWidth
        newWidth = Math.round(newWidth / this.minWidth) * this.minWidth;

        // Apply the maxWidth and minWidth constraints
        newWidth = Math.min(Math.max(newWidth, this.minWidth), this.maxWidth);

        this.durations[this.dragIndex].width = newWidth;
        this.durations[this.dragIndex].duration = newWidth / this.minWidth;
        this.$intercom.trackEvent("Chord duration changed");
      }
    },
    stopDrag() {
      this.saveDurations();
      this.isDragging = false;
      this.dragIndex = null;
      this.startX = 0;
      this.startWidth = 0;
      window.removeEventListener("mousemove", this.drag);
      window.removeEventListener("mouseup", this.stopDrag);
    },
    handleCurrentTime(percentage) {
      this.playheadPosition = percentage;

      const container = this.$el.querySelector(".scrollable-x");
      const playhead = this.$el.querySelector(".playhead");

      // Calculate the scroll position based on the playhead's position
      const playheadPosition =
        (container.offsetWidth - playhead.offsetWidth) * (percentage / 100);

      // Set the scrollLeft property of the container
      container.scrollLeft = playheadPosition;

      // Ensure the playhead div is in view
      playhead.scrollIntoView({ inline: "center" });
    },
    totalWidth() {
      return this.durations.reduce((acc, curr) => acc + curr.width, 0);
    },
    async loadDurations() {
      // load the durations for the chords from the lyrics model of the graphql api
      let vm = this;
      try {
        const result = await API.graphql({
          query: gql(`query GetSong($id: ID!) {
              getLyric(id: $id) {
                id
                chordDurations
              }
           }`),
          variables: {
            id: vm.lyricId,
          },
          fetchPolicy: "network-only",
        });
        if (result.data.getLyric.chordDurations) {
          vm.durations = JSON.parse(result.data.getLyric.chordDurations);
        }
        if (vm.durations.length < vm.chords.length) {
          for (
            let index = vm.durations.length;
            index < vm.chords.length;
            index++
          ) {
            vm.durations.push({ duration: 4, width: 204 });
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    async saveDurations() {
      // write durations to the lyrics model of the graphql api
      let vm = this;
      try {
        await API.graphql(
          graphqlOperation(updateLyric, {
            input: {
              id: vm.lyricId,
              chordDurations: JSON.stringify(vm.durations),
              clientId: vm.clientId,
            },
          })
        );
      } catch (error) {
        console.log(error);
      }
    },
    handleKeyCombination(event) {
      // Check for the key combination (Ctrl + Shift + L or Command + Shift + L)
      if (
        (event.ctrlKey || event.metaKey) && // metaKey represents the Command key on macOS
        event.shiftKey &&
        event.key.toUpperCase() === "P"
      ) {
        // Key combination is detected, perform desired action
        this.$refs.chordPlayer.startAudio();
      }
    },
    async downloadMidi() {
      // if the user has a subscription, download the midi file else show the upgrade modal
      let response = await Auth.currentAuthenticatedUser({
        bypassCache: true,
      });

      var subscription = response.attributes["custom:stripe_subscription"];
      if (subscription != undefined) {
        this.$refs.chordPlayer.startAudio(true);
        this.$intercom.trackEvent("Clicked export midi", {
          songId: this.song.id,
          subscription: subscription,
        });
      } else {
        this.$refs.modal.showModal();
        this.$intercom.trackEvent("Clicked export midi", {
          songId: this.song.id,
          subscription: "none",
        });
      }
    },
    handleStarted() {
      this.recording = true;
      this.$refs.chordPlayer.startAudio();
    },
    handleStop() {
      this.recording = false;
      this.$refs.chordPlayer.stopAudio();
    },
    handlePlayStopped() {
      if (this.recording) {
        this.$refs.recorder.stopRecording();
      }
      this.recording = false;
    },
  },
  watch: {
    chords: function (newVal) {
      if (this.durations.length > newVal.length) {
        // Remove excess elements from the durations array
        this.durations.splice(newVal.length);
      }

      if (this.durations.length < newVal.length) {
        for (
          let index = this.durations.length;
          index < newVal.length;
          index++
        ) {
          this.durations.push({ duration: 4, width: 204 });
        }
      }
    },
    lyricId: function (newVal) {
      if (newVal) {
        this.loadDurations();
      }
    },
  },
  beforeMount() {
    if (this.lyricId) {
      this.loadDurations();
    }
  },
  mounted() {
    document.addEventListener("keydown", this.handleKeyCombination);
  },
  beforeUnmount() {
    // Remove event listener when the component is unmounted
    document.removeEventListener("keydown", this.handleKeyCombination);
  },
};
</script>

<style scoped>
.chord-sequence {
  background: white;
  padding: 10px;
  position: fixed;
  bottom: 0;
  left: 0;
  flex: 1;
  flex-direction: row;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 10px;
  z-index: 1001;
  overflow: auto; /* Use overflow: auto instead of overflow-x: scroll */
}

.button-container {
  position: fixed;
  left: 0;
  z-index: 20;
  padding-right: 10px;
  background: #fff;
  flex: 1;
}

.chord-container {
  flex-shrink: 0;
  position: relative;
}

.playhead {
  margin: -15px 0;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 2px;
  height: 200%;
  background-color: black;
  z-index: 15;
}

.scrollable-x {
  margin-left: 155px;
  background-size: 204px 49px;
  background-image: linear-gradient(
    to right,
    #cdcdcd 1px,
    transparent 1px,
    transparent 50px,
    #cdcdcd 51px,
    transparent 51px,
    transparent 101px,
    #cdcdcd 102px,
    transparent 102px,
    transparent 152px,
    #cdcdcd 153px,
    transparent 153px,
    transparent 203px,
    #ccc 204px
  );
  background-repeat: repeat-x;
  width: 100%;
  padding: 15px 0 15px 0;
  white-space: nowrap; /* Ensure the child elements are in a single line */
}

.chord {
  height: 28px;
  background-color: rgba(249, 28, 129, 1);
  border-radius: 4px;
  text-align: center;
  line-height: 28px;
  white-space: nowrap;
  cursor: col-resize;
  display: inline-block; /* Use display: inline-block instead of display: block */
  font-size: 13px;
  color: white;
  font-weight: bold;
}

@media only screen and (max-width: 600px) {
  .chord-sequence {
    bottom: 60px;
  }
}
</style>
