<template>
  <div>
    <el-row v-if="recordingsList.length < 1">
      <p>
        To make recordings, first add chords to your song. Then you can record
        vocals using the mic button on the bottom left of the screen.
      </p>
    </el-row>
    <el-row v-if="recordingsList.length > 0">
      <el-row
        type="flex"
        v-for="recording in recordingsList"
        :key="recording.id"
        align="middle"
        justify="space-between"
        class="recording-row"
      >
        <div style="display: flex; flex-shrink: 0; align-items: center">
          <audio-player
            :ref="`player-${recording.id}`"
            :audio-list="[recording.src]"
            theme-color="#F91C81"
            :show-prev-button="false"
            :show-next-button="false"
            :isLoop="false"
            :show-playback-rate="false"
            :show-volume-button="false"
            :show-progress-bar="false"
            :disabled-progress-drag="true"
            :disabled-progress-click="true"
          />
          {{ recording.name }}
        </div>
        <div>
          <el-button
            @click="
              downloadFile(
                recording.file.key.split('/').pop(),
                recording.level,
                recording.identityId
              )
            "
            icon="el-icon-download"
            circle
          ></el-button>
          <el-button
            icon="el-icon-delete"
            circle
            @click="
              deleteRecording(
                recording.file.key,
                recording.id,
                recording.level,
                recording.owner
              )
            "
          ></el-button>
        </div>
      </el-row>
    </el-row>
  </div>
</template>

<script>
import { Auth, Storage, API, graphqlOperation } from "aws-amplify";
import gql from "graphql-tag";
import { deleteRecording } from "@/graphql/mutations";
import AudioPlayer from "@liripeng/vue-audio-player";

export default {
  name: "RecordingsList",
  components: {
    AudioPlayer,
  },
  data() {
    return {
      recordingsList: [],
    };
  },
  methods: {
    getRecordings() {
      let vm = this;
      (async () => {
        const result = await API.graphql({
          query: gql(`query GetSong($id: ID!) {
             getSong(id: $id) {
               id
               title
               recordings {
                 items {
                   id
                   name
                   identityId
                   owner
                   level
                   file {
                     region
                     bucket
                     key
                   }
                 }
               }
             }
           }
          `),
          variables: {
            id: vm.$route.params.id,
          },
          fetchPolicy: "network-only",
        });

        if (result.data.getSong.recordings.items.length < 1) {
          return;
        }

        for (const recording of result.data.getSong.recordings.items) {
          recording.playing = false;
          let identityId;
          if (recording.identityId) {
            identityId = recording.identityId;
          } else {
            let creds = await Auth.currentUserCredentials();
            identityId = creds.identityId;
          }
          let level = recording.level ? recording.level : "private";
          let signedUrl = await Storage.get(recording.file.key, {
            level: level,
            identityId: identityId,
          });
          console.log(signedUrl);
          recording.src = signedUrl;
        }
        this.recordingsList = result.data.getSong.recordings.items;
        this.$forceUpdate();
      })();
    },
    async downloadFile(key, level, identityId) {
      let vm = this;
      // check if the user is paying for the app
      let response = await Auth.currentAuthenticatedUser({
        bypassCache: true,
      });

      var subscription = response.attributes["custom:stripe_subscription"];
      if (subscription != undefined) {
        vm.$intercom.trackEvent("Downloaded recording");
        vm.$message({
          type: "info",
          message: "Fetching recording file",
        });
        if (!identityId) {
          let creds = await Auth.currentUserCredentials();
          identityId = creds.identityId;
        }
        if (!level) {
          level = "private";
        }

        Storage.get(key, { level: level, identityId: identityId }).then(
          (result) => {
            let element = document.createElement("a");
            element.setAttribute("href", result);
            element.setAttribute("download", key);
            document.body.appendChild(element);
            element.click();
            document.body.removeChild(element);
          }
        );
      } else {
        vm.$emit("showUpgradeModal");
        vm.$intercom.trackEvent(
          "Tried downloading recording without subscription"
        );
      }
    },
    async deleteRecording(key, id, level, owner) {
      if (!level) {
        level = "private";
      }
      // if the user is not the owner of the recording, don't allow deletion
      let user = await Auth.currentAuthenticatedUser();
      if (user.username !== owner) {
        this.$message({
          type: "warning",
          message: "You can only delete you own recordings",
        });
        return;
      }

      this.loading = true;
      // confirm deletion before proceeding
      let confirm = await this.$confirm(
        "This will permanently delete the recording. Continue?",
        "Warning",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      ).catch((e) => {
        console.log(e);
      });

      if (confirm) {
        try {
          this.$intercom.trackEvent("Deleted recording");
          await Storage.remove(key, { level: level });
          await API.graphql(
            graphqlOperation(deleteRecording, { input: { id } })
          );
          this.recordingsList = this.recordingsList.filter(
            (recording) => recording.id !== id
          );
        } catch (e) {
          console.log(e);
        }
        this.loading = false;
        this.getRecordings();
      }
    },
  },
  mounted() {
    this.getRecordings();
  },
};
</script>

<style scoped>
.recording-row {
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #ebeef5;
}
</style>
