/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-2",
    "aws_mobile_analytics_app_id": "6d24950f453444ddab4dc820e4750ee6",
    "aws_mobile_analytics_app_region": "eu-west-1",
    "Analytics": {
        "AWSPinpoint": {
            "appId": "6d24950f453444ddab4dc820e4750ee6",
            "region": "eu-west-1"
        }
    },
    "aws_cloud_logic_custom": [
        {
            "name": "ai",
            "endpoint": "https://lu1aibzzqf.execute-api.eu-west-2.amazonaws.com/production",
            "region": "eu-west-2"
        },
        {
            "name": "collaborators",
            "endpoint": "https://997431dx9a.execute-api.eu-west-2.amazonaws.com/production",
            "region": "eu-west-2"
        },
        {
            "name": "intercom",
            "endpoint": "https://qexedgs91e.execute-api.eu-west-2.amazonaws.com/production",
            "region": "eu-west-2"
        },
        {
            "name": "opportunities",
            "endpoint": "https://o3msc43egk.execute-api.eu-west-2.amazonaws.com/production",
            "region": "eu-west-2"
        },
        {
            "name": "stripe",
            "endpoint": "https://9r2v94287k.execute-api.eu-west-2.amazonaws.com/production",
            "region": "eu-west-2"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://xwtokazl2jdelmd2jpwylst6d4.appsync-api.eu-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "eu-west-2:fbd1d78a-0482-4769-8eb3-cb5e01f9335f",
    "aws_cognito_region": "eu-west-2",
    "aws_user_pools_id": "eu-west-2_3HkWzJitk",
    "aws_user_pools_web_client_id": "4nc7jsp9j6camg66d1sajmiiqt",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "SMS",
        "TOTP"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "songpad-recordings-production",
    "aws_user_files_s3_bucket_region": "eu-west-2"
};


export default awsmobile;
