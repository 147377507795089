<template>
  <div>
    <navbar
      v-if="user"
      :title="
        user.attributes.given_name
          ? `${user.attributes.given_name}'s SongPad`
          : `${user.attributes.name}'s SongPad`
      "
      :show-back-button="false"
    ></navbar>
    <router-view />
  </div>
</template>

<script>
import { Auth } from "aws-amplify";
import Navbar from "@/components/Navbar.vue";

export default {
  name: "SyncOpportunities",
  components: {
    Navbar,
  },
  data() {
    return {
      user: null,
    };
  },
  async created() {
    const user = await Auth.currentAuthenticatedUser();
    this.user = user;
  },
};
</script>

<style></style>
