<template>
  <el-dialog :visible.sync="dialogVisible" width="460px" append-to-body>
    <h2>How to add chords:</h2>
    <p>
        <ul style="word-break: normal;">
            <li>Type the chords on separate lines above your lyrics</li>
            <li>Use spaces to position the chord above the correct word</li>
            <li>SongPad will automatically detect if you're writing a chord and format it</li>
        </ul>
    </p>
    <img src="@/assets/chord-entry-demo.gif" />
    <p>
        <ul style="word-break: normal;">
            <li>Click the play button in the bottom left to hear your chords</li>
            <li>Click and drag on chords in the bottom bar to change their length</li>
        </ul>
    </p>
    <img src="@/assets/chord-durations-demo.gif" />
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: false,
    };
  },
  methods: {
    showModal() {
      this.dialogVisible = true;
    },
    hideModal() {
      this.dialogVisible = false;
    },
  }
}
</script>

<style></style>
