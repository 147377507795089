<template>
  <div v-if="visible" id="reviewPrompt" class="animate-review-prompt">
    <el-button
      class="pulse-button"
      @click="goToReviews()"
      round
      size="small"
      type="primary"
      >Get your song reviewed</el-button
    >
  </div>
</template>

<script>
export default {
  name: "ReviewPrompt",
  props: {
    songId: {
      type: String,
      default: null,
    },
    chords: {
      type: Array,
      default: null,
    },
    lyrics: {
      type: String,
      default: null,
    },
  },
  data() {
    return {};
  },

  methods: {
    goToReviews() {
      this.$intercom.trackEvent("clicked review prompt");
      this.$router.push("/song/" + this.songId + "/reviews");
    },
  },
  computed: {
    visible() {
      // count the number of line breaks in the lyrics
      let lineBreaks = this.lyrics.match(/\n/g);
      if (lineBreaks) {
        lineBreaks = lineBreaks.length;
      } else {
        lineBreaks = 0;
      }
      // if song has chords show review prompt after 20 lines
      if (this.chords.length > 0) {
        return lineBreaks > 20;
      } else {
        // if song has no chords show review prompt after 12 lines
        return lineBreaks > 12;
      }
    },
  },
};
</script>

<style scoped>
#reviewPrompt {
  z-index: 90;
  position: fixed;
  left: 44vw;
  top: calc(100vh - 130px);
}

.animate-review-prompt {
  animation: fadeInPop 0.3s ease-in-out forwards; /* Animation name and duration */
}

@keyframes fadeInPop {
  0% {
    opacity: 0;
    transform: scale(1);
  }
  90% {
    opacity: 1;
    transform: scale(1.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.pulse-button {
  animation: pulse 1s linear 0.3s 1;
  box-shadow: 0 0 0 0 rgba(249, 28, 129, 0.3);
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(249, 28, 129, 0.3);
  }
  100% {
    box-shadow: 0 0 0 12px rgba(249, 28, 129, 0);
  }
}
</style>
