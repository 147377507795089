/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getSong = /* GraphQL */ `
  query GetSong($id: ID!) {
    getSong(id: $id) {
      id
      title
      tone
      mode
      genre
      mood
      bpm
      writers
      tags
      outline
      createdAt
      updatedAt
      demo
      isrc
      lyrics {
        items {
          id
          clientId
          cursorPosition
          changeSet
          content
          originalContent
          chordDurations
          createdAt
          updatedAt
          collaborators
          owner
          __typename
        }
        nextToken
        __typename
      }
      recordings {
        items {
          id
          name
          identityId
          level
          createdAt
          collaborators
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          title
          genre
          mood
          lyrics
          content
          createdAt
          updatedAt
          status
          owner
          __typename
        }
        nextToken
        __typename
      }
      owner
      collaborators
      collaboratorDetails
      ownerDetails
      __typename
    }
  }
`;
export const listSongs = /* GraphQL */ `
  query ListSongs(
    $filter: ModelSongFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSongs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        tone
        mode
        genre
        mood
        bpm
        writers
        tags
        outline
        createdAt
        updatedAt
        demo
        isrc
        lyrics {
          nextToken
          __typename
        }
        recordings {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        owner
        collaborators
        collaboratorDetails
        ownerDetails
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getLyric = /* GraphQL */ `
  query GetLyric($id: ID!) {
    getLyric(id: $id) {
      id
      clientId
      cursorPosition
      changeSet
      content
      originalContent
      chordDurations
      createdAt
      updatedAt
      collaborators
      song {
        id
        title
        tone
        mode
        genre
        mood
        bpm
        writers
        tags
        outline
        createdAt
        updatedAt
        demo
        isrc
        lyrics {
          nextToken
          __typename
        }
        recordings {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        owner
        collaborators
        collaboratorDetails
        ownerDetails
        __typename
      }
      lyricGenerations {
        items {
          id
          inputTokens
          outputTokens
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      owner
      __typename
    }
  }
`;
export const listLyrics = /* GraphQL */ `
  query ListLyrics(
    $filter: ModelLyricFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLyrics(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        clientId
        cursorPosition
        changeSet
        content
        originalContent
        chordDurations
        createdAt
        updatedAt
        collaborators
        song {
          id
          title
          tone
          mode
          genre
          mood
          bpm
          writers
          tags
          outline
          createdAt
          updatedAt
          demo
          isrc
          owner
          collaborators
          collaboratorDetails
          ownerDetails
          __typename
        }
        lyricGenerations {
          nextToken
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getRecording = /* GraphQL */ `
  query GetRecording($id: ID!) {
    getRecording(id: $id) {
      id
      name
      identityId
      level
      createdAt
      file {
        bucket
        key
        region
        __typename
      }
      collaborators
      song {
        id
        title
        tone
        mode
        genre
        mood
        bpm
        writers
        tags
        outline
        createdAt
        updatedAt
        demo
        isrc
        lyrics {
          nextToken
          __typename
        }
        recordings {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        owner
        collaborators
        collaboratorDetails
        ownerDetails
        __typename
      }
      updatedAt
      owner
      __typename
    }
  }
`;
export const listRecordings = /* GraphQL */ `
  query ListRecordings(
    $filter: ModelRecordingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRecordings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        identityId
        level
        createdAt
        file {
          bucket
          key
          region
          __typename
        }
        collaborators
        song {
          id
          title
          tone
          mode
          genre
          mood
          bpm
          writers
          tags
          outline
          createdAt
          updatedAt
          demo
          isrc
          owner
          collaborators
          collaboratorDetails
          ownerDetails
          __typename
        }
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getLyricGeneration = /* GraphQL */ `
  query GetLyricGeneration($id: ID!) {
    getLyricGeneration(id: $id) {
      id
      inputTokens
      outputTokens
      lyric {
        id
        clientId
        cursorPosition
        changeSet
        content
        originalContent
        chordDurations
        createdAt
        updatedAt
        collaborators
        song {
          id
          title
          tone
          mode
          genre
          mood
          bpm
          writers
          tags
          outline
          createdAt
          updatedAt
          demo
          isrc
          owner
          collaborators
          collaboratorDetails
          ownerDetails
          __typename
        }
        lyricGenerations {
          nextToken
          __typename
        }
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listLyricGenerations = /* GraphQL */ `
  query ListLyricGenerations(
    $filter: ModelLyricGenerationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLyricGenerations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        inputTokens
        outputTokens
        lyric {
          id
          clientId
          cursorPosition
          changeSet
          content
          originalContent
          chordDurations
          createdAt
          updatedAt
          collaborators
          owner
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getReview = /* GraphQL */ `
  query GetReview($id: ID!) {
    getReview(id: $id) {
      id
      title
      genre
      mood
      lyrics
      content
      createdAt
      updatedAt
      status
      song {
        id
        title
        tone
        mode
        genre
        mood
        bpm
        writers
        tags
        outline
        createdAt
        updatedAt
        demo
        isrc
        lyrics {
          nextToken
          __typename
        }
        recordings {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        owner
        collaborators
        collaboratorDetails
        ownerDetails
        __typename
      }
      owner
      __typename
    }
  }
`;
export const listReviews = /* GraphQL */ `
  query ListReviews(
    $filter: ModelReviewFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReviews(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        genre
        mood
        lyrics
        content
        createdAt
        updatedAt
        status
        song {
          id
          title
          tone
          mode
          genre
          mood
          bpm
          writers
          tags
          outline
          createdAt
          updatedAt
          demo
          isrc
          owner
          collaborators
          collaboratorDetails
          ownerDetails
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSyncOpportunity = /* GraphQL */ `
  query GetSyncOpportunity($id: ID!) {
    getSyncOpportunity(id: $id) {
      id
      title
      description
      soundalike
      era
      tempo
      format
      rights
      term
      fee
      submission
      deadline
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listSyncOpportunitys = /* GraphQL */ `
  query ListSyncOpportunitys(
    $filter: ModelSyncOpportunityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSyncOpportunitys(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        description
        soundalike
        era
        tempo
        format
        rights
        term
        fee
        submission
        deadline
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
