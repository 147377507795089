const stripe_pricing_prod = {
  plus: {
    name: "Plus",
    productId: "prod_MjUgWxGvxsCR1w",
    monthlyPriceId: "price_1MQsl3RnJzgg7moBu2DY2ZMp",
    monthlyAmount: 2.99,
    yearlyPriceId: "price_1MQsl3RnJzgg7moBWeieG9QS",
    yearlyAmount: 29.99,
    currency: "usd",
    interval: "month",
  },
};
const stripe_pricing_dev = {
  plus: {
    name: "Plus",
    productId: "prod_MjUgWxGvxsCR1w",
    monthlyPriceId: "price_1MP75YRnJzgg7moBRJxEyqjV",
    monthlyAmount: 2.99,
    yearlyPriceId: "price_1MP75IRnJzgg7moBLejsVK9E",
    yearlyAmount: 29.99,
    currency: "usd",
    interval: "month",
  },
};

const stripe_pricing =
  process.env.VUE_APP_NODE_ENV == "production"
    ? stripe_pricing_prod
    : stripe_pricing_dev;
export { stripe_pricing };
