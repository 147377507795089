<template>
  <div class="song" v-loading.fullscreen.lock="fullscreenLoading">
    <h1>Reviews</h1>
    <el-row type="flex" justify="center" v-if="user && subscription == null">
      <el-col :xs="24" :sm="24" :md="16" :lg="12" :xl="9">
        <h3>SongPad Reviews: make your song the best it can be</h3>
        <p>
          Receive instant feedback on your song structure, rhymes, and metaphors
          for less than $1.
        </p>
        <h4>How it works</h4>
        <p>
          <ol>
            <li>Signup to SongPad Plus for only $2.99</li>
            <li>Click "Request a review"</li>
            <li>SongPad's AI will review your song in less than 5 minutes!</li>
            <li>Use the feedback and guidance to refine your song and make it a hit!</li> 
          </ol>
        </p>
        <p>
          <a href="https://www.songpad.co/song-reviews" target="_blank">
          Find out more and see an example review here
          </a>
          </p>
        
        <p>
          Get <strong>5 song reviews</strong> every month with SongPad Plus.
        </p>
        <el-row type="flex" align="center" justify="space-around">
          <el-button
            type="primary"
            @click="$refs.modal.showModal()"
            style="margin-top: 10px;"
            >Upgrade now</el-button
          >
        </el-row>
      </el-col>
    </el-row>
    <el-row type="flex" justify="center" v-if="subscription">
      <el-col :xs="24" :sm="24" :md="18" :lg="18" :xl="9">
        <el-row type="flex" justify="space-between" style="margin-bottom: 20px;">
          <p v-if="availableReviews > -1">
            You have {{ availableReviews }} reviews remaining this month
          </p>
          <p v-else>Checking your available reviews...</p>
        </el-row>
        <el-row type="flex" justify="space-between">
          <el-button
            @click="postReview"
            type="primary"
            style="margin-bottom: 10px;"
            :disabled="availableReviews == 0"
            >Request a review</el-button
          >
          <el-button
            @click="loadSong"
            icon="el-icon-refresh"
            style="margin-bottom: 10px;"
          ></el-button>
        </el-row>
      </el-col>
    </el-row>

    <el-row
      v-if="song && song.reviews.items.length > 0"
      type="flex"
      justify="center"
    >
      <el-col :xs="24" :sm="24" :md="18" :lg="18" :xl="9">
        <el-collapse v-model="activeNames" @change="handleChange">
          <el-collapse-item
            v-for="(review, index) in sortedReviews"
            :key="review.id"
            :name="index"
          >
            <template slot="title">
              <el-row type="flex" justify="space-between" style="width: 100%;">
                <el-col :span="6">
                  Review {{ sortedReviews.length - index }}
                </el-col>
                <el-col :span="6">
                  {{ formatTimestamp(review.createdAt) }}
                </el-col>
                <el-col :span="6" v-if="review.status == 'Complete'">
                  <el-tag type="success">{{ review.status }}</el-tag>
                </el-col>
                <el-col :span="6" v-else>
                  <el-tag type="info">{{ review.status }}</el-tag>
                </el-col>
              </el-row>
            </template>
            <div
              style="white-space: pre-wrap; width: 100%; font-size: 15px; padding-top: 20px;"
              v-html="review.content"
            ></div>
          </el-collapse-item>
        </el-collapse>
      </el-col>
    </el-row>
    <upgrade-modal
      intro="Get up to 5 song reviews every month with SongPad Plus:"
      ref="modal"
    ></upgrade-modal>
  </div>
</template>

<script>
// @ is an alias to /src

import gql from "graphql-tag";
import { API, Auth } from "aws-amplify";
import { lineIsOnlyChords } from "@/helpers/chord-detection";
import { listReviews } from "@/graphql/queries";
import moment from "moment";
import UpgradeModal from "../../components/UpgradeModal.vue";

export default {
  name: "ReviewsView",
  components: { UpgradeModal },
  data() {
    return {
      songId: null,
      song: null,
      lyrics: "",
      fullscreenLoading: true,
      activeNames: [],
      user: null,
      subscription: null,
      availableReviews: -1,
    };
  },
  methods: {
    async loadSong() {
      this.fullscreenLoading = true;
      try {
        const result = await API.graphql({
          query: gql(`query GetSong($id: ID!) {
              getSong(id: $id) {
                id
                title
                genre
                mood
                lyrics {
                  items {
                    id
                    content
                    }
                  }

                reviews {
                  items {
                    id
                    content
                    status
                    createdAt
                    updatedAt
                  }
                }
              }
            }
           `),
          variables: {
            id: this.songId,
          },
          fetchPolicy: "network-only",
        });
        this.song = result.data.getSong;
        this.updateAvailableReviews();
        this.fullscreenLoading = false;
      } catch (error) {
        console.log(error);
        this.fullscreenLoading = false;
      }
    },
    async postReview() {
      this.fullscreenLoading = true;
      try {
        let vm = this;
        let lyrics = this.song.lyrics.items[0].content;
        let lines = lyrics.split("\n");
        lines.forEach((line) => {
          if (lineIsOnlyChords(line) == false) {
            vm.lyrics += line + "\n";
          }
        });

        let myInit = {
          body: {
            songId: this.songId,
            title: this.song.title,
            genre: this.song.genre,
            mood: this.song.mood,
            lyrics: this.lyrics,
          },
        };
        console.log(myInit);
        let response = await API.post("ai", "/review", myInit);
        if (response.error) {
          alert(response.body);
        } else {
          this.$intercom.trackEvent("Song review requested");
        }
        this.fullscreenLoading = false;
        this.loadSong();
      } catch (error) {
        console.log(error);
        this.fullscreenLoading = false;
      }
    },
    formatTimestamp(timestamp) {
      return moment(timestamp).fromNow();
    },
    handleChange(val) {
      console.log(val);
    },
    async updateAvailableReviews() {
      const date30DaysAgo = new Date(Date.now() - 30 * 24 * 60 * 60 * 1000);
      const filter = {
        createdAt: {
          gt: date30DaysAgo.toISOString(),
        },
      };
      let response = await API.graphql({
        query: listReviews,
        variables: {
          filter: filter,
        },
      });
      this.availableReviews = 5 - response.data.listReviews.items.length;
      this.$intercom.update({
         song_reviews_remaining: this.availableReviews
      });
    },
  },
  computed: {
    sortedReviews() {
      return this.song.reviews.items.slice().sort((a, b) => {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });
    },
  },
  async mounted() {
    this.songId = this.$route.params.id;
    this.loadSong();
    this.user = await Auth.currentAuthenticatedUser({
      bypassCache: true,
    });
    var subscription = this.user.attributes["custom:stripe_subscription"];
    if (subscription != undefined) {
      this.subscription = subscription;
    }
    this.fullscreenLoading = false;
    this.updateAvailableReviews();
  },
};
</script>

<style scoped>
.shadow-box {
  padding: 25px;
  border-radius: 8px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
}
.el-tag {
  margin-right: 5px !important;
}
.button-new-tag {
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}
.input-new-tag {
  width: 90px;
  vertical-align: bottom;
}
</style>
