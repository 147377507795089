var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.recordingsList.length < 1)?_c('el-row',[_c('p',[_vm._v(" To make recordings, first add chords to your song. Then you can record vocals using the mic button on the bottom left of the screen. ")])]):_vm._e(),(_vm.recordingsList.length > 0)?_c('el-row',_vm._l((_vm.recordingsList),function(recording){return _c('el-row',{key:recording.id,staticClass:"recording-row",attrs:{"type":"flex","align":"middle","justify":"space-between"}},[_c('div',{staticStyle:{"display":"flex","flex-shrink":"0","align-items":"center"}},[_c('audio-player',{ref:`player-${recording.id}`,refInFor:true,attrs:{"audio-list":[recording.src],"theme-color":"#F91C81","show-prev-button":false,"show-next-button":false,"isLoop":false,"show-playback-rate":false,"show-volume-button":false,"show-progress-bar":false,"disabled-progress-drag":true,"disabled-progress-click":true}}),_vm._v(" "+_vm._s(recording.name)+" ")],1),_c('div',[_c('el-button',{attrs:{"icon":"el-icon-download","circle":""},on:{"click":function($event){_vm.downloadFile(
              recording.file.key.split('/').pop(),
              recording.level,
              recording.identityId
            )}}}),_c('el-button',{attrs:{"icon":"el-icon-delete","circle":""},on:{"click":function($event){return _vm.deleteRecording(
              recording.file.key,
              recording.id,
              recording.level,
              recording.owner
            )}}})],1)])}),1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }