<template>
  <div>
    <el-row type="flex" justify="space-around">
      <h1>Sync Opportunities</h1>
    </el-row>
    <el-row type="flex" justify="space-around">
      <p>
        <strong>Exclusive to SongPad:</strong> opportunities to submit your
        songs for placement in TV shows, movies, podcasts and more
      </p>
    </el-row>
    <el-row type="flex" justify="space-around" v-loading="loading">
      <el-col :lg="16" :xl="12">
        <el-table
          :data="opportunities"
          :default-sort="{ prop: 'deadline', order: 'ascending' }"
        >
          <template slot="empty">
            <p style="color: #222; line-height: 1.5">
              More opportunities coming shortly. Check back soon.
            </p>
          </template>
          <el-table-column prop="title" label="Title" sortable width="300">
            <template slot-scope="scope">
              <router-link
                :to="'/opportunities/' + scope.row.id"
                type="primary"
                style="
                  text-decoration: none;
                  color: #222;
                  font-weight: 500;
                  word-break: break-word;
                "
                >{{ scope.row.title }}</router-link
              >
            </template>
          </el-table-column>
          <el-table-column prop="fee" label="Fee" sortable>
            <template slot-scope="scope">
              <!-- show the fee to 2 decimal places -->
              ${{ scope.row.fee.toFixed(2) }}
            </template>
          </el-table-column>
          <el-table-column
            prop="deadline"
            label="Deadline"
            sortable
          ></el-table-column>
          <el-table-column width="100">
            <template slot-scope="scope">
              <el-button
                type="primary"
                size="small"
                round
                @click="$router.push('/opportunities/' + scope.row.id)"
                >See details</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <el-row type="flex" align="center" justify="space-around">
      <a
        href="https://patreon.com/thesyncopps"
        @click="$intercom.trackEvent('clicked patreon link')"
        target="_blank"
        style="
          color: #222;
          font-size: 12px;
          margin: 30px 0 10px 0;
          text-decoration: none;
          text-align: center;
        "
        >Provided by the Sync Opps. on Patreon.<br />
        https://patreon.com/thesyncopps</a
      >
    </el-row>
  </div>
</template>

<script>
import { graphqlOperation, API } from "aws-amplify";
import gql from "graphql-tag";

export default {
  name: "SyncOpportunitiesList",
  data() {
    return {
      loading: false,
      opportunities: [],
    };
  },
  methods: {
    async getOpportunities() {
      try {
        const opportunities = await API.graphql(
          graphqlOperation(
            gql(`query ListSyncOpportunitys {
          listSyncOpportunitys {
            items {
              id
              title
              fee
              deadline
            }
          }
        }`)
          )
        );
        this.opportunities = opportunities.data.listSyncOpportunitys.items;
      } catch (error) {
        console.log(error);
      }
    },
  },
  mounted() {
    this.getOpportunities();
    this.$intercom.trackEvent("viewed sync opportunities list");
  },
};
</script>

<style scoped>
h1 {
  font-size: 2rem;
  font-weight: 700;
  margin-top: 50px;
  margin-bottom: 0;
}
</style>
