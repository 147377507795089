<template>
  <el-popover
    placement="bottom"
    title="About hints"
    width="290"
    trigger="hover"
  >
    <div style="word-break: break-word; text-align: left">
      <p>
        The colours indicate the type of word -
        <span class="nl-Noun">noun</span>, <span class="nl-Verb">verb</span>,
        <span class="nl-Adjective">adjective</span> and
        <span class="nl-Adverb">adverbs</span>. Generally speaking you want more
        to aim for:
      </p>
      <ul>
        <li>
          majority of <span class="nl-Verb">green</span> and
          <span class="nl-Noun">purple</span>
        </li>
        <li>some <span class="nl-Adjective">yellow</span></li>
        <li>few <span class="nl-Adverb">red</span>.</li>
      </ul>
      <p>
        This will make your lyrics stronger as it will mean you’re using more
        verbs and less weaker words such as adverbs.
      </p>
      <p>
        If a lot of your lyrics are underlined in
        <span class="nl-Adverb">red</span>, or a whole line is
        <span class="nl-Adverb">red</span> and
        <span class="nl-Adjective">yellow</span> you might want to try rewriting
        them as they probably aren’t conveying as strong a message as they
        could.
      </p>
    </div>
    <el-button
      icon="el-icon-question"
      slot="reference"
      size="mini"
      circle
      style="margin-left: 10px"
    ></el-button>
  </el-popover>
</template>

<script>
export default {};
</script>

<style></style>
