<template>
  <el-main v-loading="loading">
    <el-row type="flex" justify="space-around">
      <h1>Submit your song</h1>
    </el-row>
    <el-row type="flex" justify="space-around">
      <p>
        <strong>Opportunity:</strong> {{ opportunity.title }} (${{
          opportunity.fee
        }})
      </p>
    </el-row>

    <el-row type="flex" justify="space-around" style="margin-bottom: 20px">
      <el-col :lg="16" :xl="12">
        <el-steps :active="step" align-center finish-status="success">
          <el-step title="Step 1" description="Select a song"></el-step>
          <el-step title="Step 2" description="Check song details"></el-step>
          <el-step
            title="Step 3"
            description="Submission description"
          ></el-step>
          <el-step title="Step 4" description="Terms and conditions"></el-step>
          <el-step title="Step 5" description="Submission Complete"></el-step>
        </el-steps>
      </el-col>
    </el-row>

    <el-row type="flex" justify="space-around" v-if="step == 0">
      <el-col :lg="12" :xl="8">
        <el-row type="flex" justify="space-around">
          <h3>Select a song</h3>
        </el-row>
        <el-row type="flex" justify="space-around">
          <el-autocomplete
            v-model="search"
            :fetch-suggestions="filterSongs"
            placeholder="Search your songs"
            @select="handleSelect"
            style="width: 310px"
          >
            <template slot-scope="{ item }">
              <div class="value">{{ item.title }}</div>
            </template>
          </el-autocomplete>
        </el-row>
      </el-col>
    </el-row>

    <el-row type="flex" justify="space-around" v-if="step == 1">
      <el-col :lg="12" :xl="8">
        <el-row v-if="song">
          <div id="song-info">
            <h3 style="text-align: center">Song details</h3>
            <p>Please check the details of your song below:</p>
            <!-- two column table -->
            <table>
              <tr>
                <td><strong>Title:</strong></td>
                <td>
                  <i
                    class="el-icon-success"
                    style="color: green"
                    v-if="song.title"
                  ></i>
                  <i class="el-icon-error" style="color: red" v-else></i>
                </td>
                <td v-if="song.title">{{ song.title }}</td>
                <td v-else>Your song needs a title</td>
              </tr>
              <tr>
                <td><strong>Genre:</strong></td>
                <td>
                  <i
                    class="el-icon-success"
                    style="color: green"
                    v-if="song.genre"
                  ></i>
                  <i class="el-icon-error" style="color: red" v-else></i>
                </td>
                <td v-if="song.genre">{{ song.genre }}</td>
                <td v-else>Your song needs a genre</td>
              </tr>
              <tr>
                <td><strong>Streaming link:</strong></td>
                <td>
                  <i
                    class="el-icon-success"
                    style="color: green"
                    v-if="song.demo"
                  ></i>
                  <i class="el-icon-error" style="color: red" v-else></i>
                </td>
                <td v-if="song.demo">{{ song.demo }}</td>
                <td v-else>Your song needs a streaming link</td>
              </tr>
              <!-- ISRC -->
              <tr v-if="!song.isrc">
                <td><strong>ISRC:</strong></td>
                <td>
                  <i class="el-icon-error" style="color: red"></i>
                </td>
                <td v-if="song.isrc">{{ song.isrc }}</td>
                <td v-else>Your song does not have an ISRC (optional)</td>
              </tr>
              <tr v-if="song.isrc">
                <td><strong>ISRC:</strong></td>
                <td>
                  <i
                    class="el-icon-success"
                    style="color: green"
                    v-if="song.isrc"
                  ></i>
                </td>
                <td v-if="song.isrc">{{ song.isrc }}</td>
              </tr>
              <tr>
                <td valign="top"><strong>Writers:</strong></td>
                <td valign="top">
                  <i
                    class="el-icon-error"
                    v-if="!song.writers"
                    style="color: red"
                  ></i>
                  <i class="el-icon-success" v-else style="color: green"></i>
                </td>
                <td v-if="song.writers" valign="top">
                  <div
                    v-for="writer in song.writers"
                    :key="writer"
                    style="margin-bottom: 10px"
                  >
                    {{ writer.name }} - ({{ writer.percent }})%
                    <br />
                    <small>
                      <strong>Pro: </strong>{{ writer.pro }}
                      <strong>IPI: </strong>{{ writer.ipi }}
                      <strong>Publisher: </strong>{{ writer.publisher }}
                      <strong>publisher IPI: </strong>{{ writer.publisherIpi }}
                    </small>
                  </div>
                </td>
                <td v-else>Your song needs writer details</td>
              </tr>
            </table>
          </div>

          <p v-if="!song.title || !song.genre || !song.demo || !song.writers">
            Your song needs to have a title, genre, streaming link and writer
            details to be submitted. Other information is optional. Please
            <a :href="`/song/${song.id}`">click here to edit your song</a> and
            then try again. Or choose another song to submit.
          </p>

          <p v-else>
            If you need to make any changes, please
            <a :href="`/song/${song.id}`">click here to edit your song</a> and
            then try again.
          </p>

          <el-row type="flex" justify="space-around">
            <!-- back button -->
            <div>
              <el-button
                type="secondary"
                size="medium"
                round
                style="margin-top: 20px"
                @click="
                  step = 0;
                  song = null;
                "
                >Back</el-button
              >
              <!-- next button -->
              <el-button
                type="primary"
                size="medium"
                round
                style="margin-top: 20px"
                @click="step = 2"
                :disabled="!song.title || !song.genre || !song.demo"
                >Next</el-button
              >
            </div>
          </el-row>
        </el-row>
      </el-col>
    </el-row>

    <!-- step 3 -->
    <el-row type="flex" justify="space-around" v-if="step == 2">
      <el-col :lg="12" :xl="8">
        <h3 style="text-align: center">Submission description</h3>
        <p>
          Please provide a short description of your song and why you think it
          would be a good fit for this opportunity.
        </p>
        <p>
          <strong>Tip: </strong>It's a good idea to mention the genre, tempo,
          mood and any other relevant information about your song.
        </p>
        <el-input
          type="textarea"
          :rows="5"
          placeholder="Enter a description of your song"
          v-model="submission.description"
        ></el-input>
        <!-- format dropdown -->
        <el-select
          v-model="submission.format"
          placeholder="Select a format"
          style="width: 100%; margin-top: 20px"
        >
          <el-option
            label="Music &amp; Vocals"
            value="music and vocals"
          ></el-option>
          <el-option label="Instrumental" value="instrumental"></el-option>
          <el-option label="Both available" value="both available"></el-option>
        </el-select>

        <el-row type="flex" justify="space-around">
          <!-- back button -->
          <div style="margin-top: 20px">
            <el-button
              type="secondary"
              size="medium"
              round
              style="margin-top: 20px"
              @click="step = 1"
              >Back</el-button
            >
            <!-- next button -->
            <el-button
              type="primary"
              size="medium"
              round
              @click="step = 3"
              :disabled="!submission.description || !submission.format"
              >Next</el-button
            >
          </div></el-row
        >
      </el-col>
    </el-row>

    <!-- step 4 -->
    <el-row type="flex" justify="space-around" v-if="step == 3">
      <el-col :lg="12" :xl="8">
        <el-row v-if="song">
          <h3 style="text-align: center">Terms and conditions</h3>
          <p>
            In addition to the terms and conditions of SongPad, by submitting
            your song to this opportunity you agree to the following:
          </p>
          <p>
            <strong>Rights and fees: </strong>If your music is selected for a
            placement you keep all the rights you own and the sync fee available
            for that opportunity.
          </p>
          <p>
            <strong>No guarantee: </strong>
            You acknowledge that submitting your music to an opportunity does
            not guarantee a placement for your music.
          </p>
          <p>
            <strong>Third party data transfer: </strong>
            By submitting your song to this opportunity you agree to the
            transfer of your data to The Sync Opps Pateron and any other third
            parties involed with this opportunity. This includes transfering
            your data outside of the EU. Your data will be used for the sole
            purpose of evaluating your song for this opportunity.
          </p>
          <!-- checkbox to agree to terms -->
          <el-checkbox
            v-model="submission.agreeTerms"
            label="I understand and agree to the terms above"
          ></el-checkbox>
          <!-- checkbox to accept data transfer -->
          <el-checkbox
            v-model="submission.acceptDataTransfer"
            label="I consent to the transfer of my data to third parties managing
            this opportunity"
          ></el-checkbox>
        </el-row>

        <el-row type="flex" justify="space-around" v-if="song">
          <!-- back button -->
          <div style="margin-top: 20px">
            <el-button
              type="secondary"
              size="medium"
              round
              style="margin-top: 20px"
              @click="step = 1"
              >Back</el-button
            >
            <!-- next button -->
            <el-button
              type="primary"
              size="medium"
              round
              @click="submitSong"
              :disabled="
                !song.title ||
                !song.lyrics ||
                !song.demo ||
                !song.lyrics.items[0].content ||
                !song.lyrics.items[0].content.length > 20 ||
                !submission.agreeTerms ||
                !submission.acceptDataTransfer
              "
              >Submit Song</el-button
            >
          </div></el-row
        >
      </el-col>
    </el-row>

    <!-- step 5 -->
    <div v-if="step == 4">
      <el-row type="flex" justify="space-around">
        <el-col :lg="12" :xl="8">
          <el-row type="flex" justify="space-around">
            <h3>Thank you for submitting your song!</h3>
          </el-row>
          <el-row type="flex" justify="space-around">
            <p>
              Your song has been submitted to the opportunity. You will be
              notified if your song is selected.
            </p>
          </el-row>
          <el-row type="flex" justify="space-around">
            <p>
              <strong>Keep 100% of your rights and sync fees: </strong>If your
              music is selected for a placement you keep all the rights you own
              and the sync fee available for that opportunity.
            </p>
          </el-row>
          <el-row type="flex" justify="space-around">
            <p>
              <strong>Important: </strong>
              Please note that submitting your music to any opportunity does not
              guarantee a placement for your music.
            </p></el-row
          >

          <el-row type="flex" justify="space-around">
            <el-button
              type="primary"
              size="large"
              round
              @click="$router.push('/opportunities')"
              >Back to opportunities</el-button
            >
          </el-row>
        </el-col>
      </el-row>
    </div>
  </el-main>
</template>

<script>
import { graphqlOperation, API } from "aws-amplify";
import gql from "graphql-tag";
import { lineIsOnlyChords } from "@/helpers/chord-detection.js";

export default {
  name: "SyncOpportunitySubmit",
  data() {
    return {
      loading: false,
      opportunity: {},
      search: null,
      nextToken: "",
      songs: [],
      song: null,
      submission: {
        agreeTerms: false,
        acceptDataTransfer: false,
      },
      step: 0,
    };
  },
  methods: {
    async getOpportunity() {
      this.loading = true;
      // load the opportunity from the API using the id from the route
      try {
        const opportunity = await API.graphql(
          graphqlOperation(
            gql(`query GetSyncOpportunity($id: ID!) {
              getSyncOpportunity(id: $id) {
                id
                title
                description
                fee
                soundalike
                era
                tempo
                format
                rights
                term
                submission
                deadline
              }
           }`),
            {
              id: this.$route.params.id,
            }
          )
        );
        this.opportunity = opportunity.data.getSyncOpportunity;
        this.loading = false;
      } catch (error) {
        console.log(error);
        this.loading = false;
      }
    },
    async loadSongs() {
      // load the songs from the API
      try {
        let listSongs = await API.graphql({
          query: gql(`query ListSongs{
          listSongs(limit: 1000 ${
            this.nextToken && `, nextToken: "${this.nextToken}"`
          }) {
            items {
              id
              title
              demo
              genre
              writers
              lyrics {
                items {
                  id
                  content
                }
              }
            }
            nextToken
          }
        }`),
          fetchPolicy: "network-only",
        });

        if (listSongs.data.listSongs.items) {
          this.songs.push(...listSongs.data.listSongs.items);
        }
        if (listSongs.data.listSongs.nextToken != null) {
          this.nextToken = listSongs.data.listSongs.nextToken;
          this.loadSongs();
        } else {
          this.nextToken = "";
          this.loading = false;
        }
      } catch (error) {
        console.log(error);
      }
    },
    filterSongs(query, cb) {
      cb(
        query
          ? this.songs.filter((song) => {
              return song.title.toLowerCase().indexOf(query.toLowerCase()) > -1;
            })
          : this.songs
      );
    },
    handleSelect(item) {
      this.loading = true;
      this.song = item;
      this.song.writers = JSON.parse(this.song.writers);
      this.step = 1;
      this.loading = false;
    },
    lyricsWithoutChords(lyrics) {
      console.log(lyrics);
      // loop through lines
      if (!lyrics) return;
      let lines = lyrics.split("\n");
      let newLyrics = "";
      for (let i = 0; i < lines.length; i++) {
        // check if the line has chords using the chord detection helper
        if (!lineIsOnlyChords(lines[i])) {
          // if the line doesn't have chords, add it to the new lyrics
          newLyrics += lines[i] + "\n";
        }
      }
      return newLyrics;
    },
    async submitSong() {
      this.loading = true;
      // submit the song by posting to the opportunities submission Rest API
      try {
        let response = await API.post("opportunities", "/submission", {
          body: {
            opportunity: JSON.stringify(this.opportunity),
            song: JSON.stringify(this.song),
            lyrics: this.lyricsWithoutChords(this.song.lyrics.items[0].content),
            description: this.submission.description,
            format: this.submission.format,
          },
        });
        if (response.success) {
          this.step = 4;
          // clear the song
          this.song = null;
        } else {
          this.$message({
            message: "There was an error submitting your song",
            type: "error",
          });
        }
      } catch (error) {
        console.log(error);
        this.$message({
          message: "There was an error submitting your song",
          type: "error",
        });
      } finally {
        this.loading = false;
      }
    },
  },
  computed: {},
  // watch the step changes and log the step to Intercom
  watch: {
    step: function (newStep) {
      this.$intercom.trackEvent("Sync opportunity submit step", {
        opportunity: this.opportunity.title,
        opportunityId: this.opportunity.id,
        step: newStep,
      });
      if (newStep == 4) {
        this.$intercom.trackEvent("Sync opportunity submit complete", {
          opportunity: this.opportunity.title,
          opportunityId: this.opportunity.id,
        });
      }
    },
  },
  mounted() {
    this.getOpportunity();
    this.loadSongs();
    this.$intercom.trackEvent("Sync opportunity submit started", {
      opportunityId: this.$route.params.id,
    });
  },
};
</script>

<style scoped>
h1 {
  font-size: 2rem;
  margin-top: 50px;
  margin-bottom: 20px;
}

table {
  width: 100%;
  border-collapse: collapse;
  border-top: 1px solid #f1f1f1;
  border-bottom: 1px solid #f1f1f1;
}

tr {
  border-bottom: 1px solid #f5f5f5;
  padding-top: 10px;
}

td {
  padding: 10px 0 10px 15px;
}
</style>
