/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateLyricCollab = /* GraphQL */ `
  subscription OnCreateLyricCollab {
    onCreateLyricCollab {
      id
      clientId
      cursorPosition
      changeSet
      content
      originalContent
      chordDurations
      createdAt
      updatedAt
      collaborators
      song {
        id
        title
        tone
        mode
        genre
        mood
        bpm
        writers
        tags
        outline
        createdAt
        updatedAt
        demo
        isrc
        lyrics {
          nextToken
          __typename
        }
        recordings {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        owner
        collaborators
        collaboratorDetails
        ownerDetails
        __typename
      }
      lyricGenerations {
        items {
          id
          inputTokens
          outputTokens
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      owner
      __typename
    }
  }
`;
export const onUpdateLyricCollab = /* GraphQL */ `
  subscription OnUpdateLyricCollab($id: ID!) {
    onUpdateLyricCollab(id: $id) {
      id
      clientId
      cursorPosition
      changeSet
      content
      originalContent
      chordDurations
      createdAt
      updatedAt
      collaborators
      song {
        id
        title
        tone
        mode
        genre
        mood
        bpm
        writers
        tags
        outline
        createdAt
        updatedAt
        demo
        isrc
        lyrics {
          nextToken
          __typename
        }
        recordings {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        owner
        collaborators
        collaboratorDetails
        ownerDetails
        __typename
      }
      lyricGenerations {
        items {
          id
          inputTokens
          outputTokens
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      owner
      __typename
    }
  }
`;
export const onDeleteLyricCollab = /* GraphQL */ `
  subscription OnDeleteLyricCollab($id: ID!) {
    onDeleteLyricCollab(id: $id) {
      id
      clientId
      cursorPosition
      changeSet
      content
      originalContent
      chordDurations
      createdAt
      updatedAt
      collaborators
      song {
        id
        title
        tone
        mode
        genre
        mood
        bpm
        writers
        tags
        outline
        createdAt
        updatedAt
        demo
        isrc
        lyrics {
          nextToken
          __typename
        }
        recordings {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        owner
        collaborators
        collaboratorDetails
        ownerDetails
        __typename
      }
      lyricGenerations {
        items {
          id
          inputTokens
          outputTokens
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      owner
      __typename
    }
  }
`;
export const onCreateSong = /* GraphQL */ `
  subscription OnCreateSong($owner: String, $collaborators: String) {
    onCreateSong(owner: $owner, collaborators: $collaborators) {
      id
      title
      tone
      mode
      genre
      mood
      bpm
      writers
      tags
      outline
      createdAt
      updatedAt
      demo
      isrc
      lyrics {
        items {
          id
          clientId
          cursorPosition
          changeSet
          content
          originalContent
          chordDurations
          createdAt
          updatedAt
          collaborators
          owner
          __typename
        }
        nextToken
        __typename
      }
      recordings {
        items {
          id
          name
          identityId
          level
          createdAt
          collaborators
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          title
          genre
          mood
          lyrics
          content
          createdAt
          updatedAt
          status
          owner
          __typename
        }
        nextToken
        __typename
      }
      owner
      collaborators
      collaboratorDetails
      ownerDetails
      __typename
    }
  }
`;
export const onUpdateSong = /* GraphQL */ `
  subscription OnUpdateSong($owner: String, $collaborators: String) {
    onUpdateSong(owner: $owner, collaborators: $collaborators) {
      id
      title
      tone
      mode
      genre
      mood
      bpm
      writers
      tags
      outline
      createdAt
      updatedAt
      demo
      isrc
      lyrics {
        items {
          id
          clientId
          cursorPosition
          changeSet
          content
          originalContent
          chordDurations
          createdAt
          updatedAt
          collaborators
          owner
          __typename
        }
        nextToken
        __typename
      }
      recordings {
        items {
          id
          name
          identityId
          level
          createdAt
          collaborators
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          title
          genre
          mood
          lyrics
          content
          createdAt
          updatedAt
          status
          owner
          __typename
        }
        nextToken
        __typename
      }
      owner
      collaborators
      collaboratorDetails
      ownerDetails
      __typename
    }
  }
`;
export const onDeleteSong = /* GraphQL */ `
  subscription OnDeleteSong($owner: String, $collaborators: String) {
    onDeleteSong(owner: $owner, collaborators: $collaborators) {
      id
      title
      tone
      mode
      genre
      mood
      bpm
      writers
      tags
      outline
      createdAt
      updatedAt
      demo
      isrc
      lyrics {
        items {
          id
          clientId
          cursorPosition
          changeSet
          content
          originalContent
          chordDurations
          createdAt
          updatedAt
          collaborators
          owner
          __typename
        }
        nextToken
        __typename
      }
      recordings {
        items {
          id
          name
          identityId
          level
          createdAt
          collaborators
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      reviews {
        items {
          id
          title
          genre
          mood
          lyrics
          content
          createdAt
          updatedAt
          status
          owner
          __typename
        }
        nextToken
        __typename
      }
      owner
      collaborators
      collaboratorDetails
      ownerDetails
      __typename
    }
  }
`;
export const onCreateLyric = /* GraphQL */ `
  subscription OnCreateLyric($owner: String, $collaborators: String) {
    onCreateLyric(owner: $owner, collaborators: $collaborators) {
      id
      clientId
      cursorPosition
      changeSet
      content
      originalContent
      chordDurations
      createdAt
      updatedAt
      collaborators
      song {
        id
        title
        tone
        mode
        genre
        mood
        bpm
        writers
        tags
        outline
        createdAt
        updatedAt
        demo
        isrc
        lyrics {
          nextToken
          __typename
        }
        recordings {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        owner
        collaborators
        collaboratorDetails
        ownerDetails
        __typename
      }
      lyricGenerations {
        items {
          id
          inputTokens
          outputTokens
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      owner
      __typename
    }
  }
`;
export const onUpdateLyric = /* GraphQL */ `
  subscription OnUpdateLyric($owner: String, $collaborators: String) {
    onUpdateLyric(owner: $owner, collaborators: $collaborators) {
      id
      clientId
      cursorPosition
      changeSet
      content
      originalContent
      chordDurations
      createdAt
      updatedAt
      collaborators
      song {
        id
        title
        tone
        mode
        genre
        mood
        bpm
        writers
        tags
        outline
        createdAt
        updatedAt
        demo
        isrc
        lyrics {
          nextToken
          __typename
        }
        recordings {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        owner
        collaborators
        collaboratorDetails
        ownerDetails
        __typename
      }
      lyricGenerations {
        items {
          id
          inputTokens
          outputTokens
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      owner
      __typename
    }
  }
`;
export const onDeleteLyric = /* GraphQL */ `
  subscription OnDeleteLyric($owner: String, $collaborators: String) {
    onDeleteLyric(owner: $owner, collaborators: $collaborators) {
      id
      clientId
      cursorPosition
      changeSet
      content
      originalContent
      chordDurations
      createdAt
      updatedAt
      collaborators
      song {
        id
        title
        tone
        mode
        genre
        mood
        bpm
        writers
        tags
        outline
        createdAt
        updatedAt
        demo
        isrc
        lyrics {
          nextToken
          __typename
        }
        recordings {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        owner
        collaborators
        collaboratorDetails
        ownerDetails
        __typename
      }
      lyricGenerations {
        items {
          id
          inputTokens
          outputTokens
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      owner
      __typename
    }
  }
`;
export const onCreateRecording = /* GraphQL */ `
  subscription OnCreateRecording($owner: String, $collaborators: String) {
    onCreateRecording(owner: $owner, collaborators: $collaborators) {
      id
      name
      identityId
      level
      createdAt
      file {
        bucket
        key
        region
        __typename
      }
      collaborators
      song {
        id
        title
        tone
        mode
        genre
        mood
        bpm
        writers
        tags
        outline
        createdAt
        updatedAt
        demo
        isrc
        lyrics {
          nextToken
          __typename
        }
        recordings {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        owner
        collaborators
        collaboratorDetails
        ownerDetails
        __typename
      }
      updatedAt
      owner
      __typename
    }
  }
`;
export const onUpdateRecording = /* GraphQL */ `
  subscription OnUpdateRecording($owner: String, $collaborators: String) {
    onUpdateRecording(owner: $owner, collaborators: $collaborators) {
      id
      name
      identityId
      level
      createdAt
      file {
        bucket
        key
        region
        __typename
      }
      collaborators
      song {
        id
        title
        tone
        mode
        genre
        mood
        bpm
        writers
        tags
        outline
        createdAt
        updatedAt
        demo
        isrc
        lyrics {
          nextToken
          __typename
        }
        recordings {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        owner
        collaborators
        collaboratorDetails
        ownerDetails
        __typename
      }
      updatedAt
      owner
      __typename
    }
  }
`;
export const onDeleteRecording = /* GraphQL */ `
  subscription OnDeleteRecording($owner: String, $collaborators: String) {
    onDeleteRecording(owner: $owner, collaborators: $collaborators) {
      id
      name
      identityId
      level
      createdAt
      file {
        bucket
        key
        region
        __typename
      }
      collaborators
      song {
        id
        title
        tone
        mode
        genre
        mood
        bpm
        writers
        tags
        outline
        createdAt
        updatedAt
        demo
        isrc
        lyrics {
          nextToken
          __typename
        }
        recordings {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        owner
        collaborators
        collaboratorDetails
        ownerDetails
        __typename
      }
      updatedAt
      owner
      __typename
    }
  }
`;
export const onCreateLyricGeneration = /* GraphQL */ `
  subscription OnCreateLyricGeneration($owner: String!) {
    onCreateLyricGeneration(owner: $owner) {
      id
      inputTokens
      outputTokens
      lyric {
        id
        clientId
        cursorPosition
        changeSet
        content
        originalContent
        chordDurations
        createdAt
        updatedAt
        collaborators
        song {
          id
          title
          tone
          mode
          genre
          mood
          bpm
          writers
          tags
          outline
          createdAt
          updatedAt
          demo
          isrc
          owner
          collaborators
          collaboratorDetails
          ownerDetails
          __typename
        }
        lyricGenerations {
          nextToken
          __typename
        }
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onUpdateLyricGeneration = /* GraphQL */ `
  subscription OnUpdateLyricGeneration($owner: String!) {
    onUpdateLyricGeneration(owner: $owner) {
      id
      inputTokens
      outputTokens
      lyric {
        id
        clientId
        cursorPosition
        changeSet
        content
        originalContent
        chordDurations
        createdAt
        updatedAt
        collaborators
        song {
          id
          title
          tone
          mode
          genre
          mood
          bpm
          writers
          tags
          outline
          createdAt
          updatedAt
          demo
          isrc
          owner
          collaborators
          collaboratorDetails
          ownerDetails
          __typename
        }
        lyricGenerations {
          nextToken
          __typename
        }
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onDeleteLyricGeneration = /* GraphQL */ `
  subscription OnDeleteLyricGeneration($owner: String!) {
    onDeleteLyricGeneration(owner: $owner) {
      id
      inputTokens
      outputTokens
      lyric {
        id
        clientId
        cursorPosition
        changeSet
        content
        originalContent
        chordDurations
        createdAt
        updatedAt
        collaborators
        song {
          id
          title
          tone
          mode
          genre
          mood
          bpm
          writers
          tags
          outline
          createdAt
          updatedAt
          demo
          isrc
          owner
          collaborators
          collaboratorDetails
          ownerDetails
          __typename
        }
        lyricGenerations {
          nextToken
          __typename
        }
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onCreateReview = /* GraphQL */ `
  subscription OnCreateReview($owner: String!) {
    onCreateReview(owner: $owner) {
      id
      title
      genre
      mood
      lyrics
      content
      createdAt
      updatedAt
      status
      song {
        id
        title
        tone
        mode
        genre
        mood
        bpm
        writers
        tags
        outline
        createdAt
        updatedAt
        demo
        isrc
        lyrics {
          nextToken
          __typename
        }
        recordings {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        owner
        collaborators
        collaboratorDetails
        ownerDetails
        __typename
      }
      owner
      __typename
    }
  }
`;
export const onUpdateReview = /* GraphQL */ `
  subscription OnUpdateReview($owner: String!) {
    onUpdateReview(owner: $owner) {
      id
      title
      genre
      mood
      lyrics
      content
      createdAt
      updatedAt
      status
      song {
        id
        title
        tone
        mode
        genre
        mood
        bpm
        writers
        tags
        outline
        createdAt
        updatedAt
        demo
        isrc
        lyrics {
          nextToken
          __typename
        }
        recordings {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        owner
        collaborators
        collaboratorDetails
        ownerDetails
        __typename
      }
      owner
      __typename
    }
  }
`;
export const onDeleteReview = /* GraphQL */ `
  subscription OnDeleteReview($owner: String!) {
    onDeleteReview(owner: $owner) {
      id
      title
      genre
      mood
      lyrics
      content
      createdAt
      updatedAt
      status
      song {
        id
        title
        tone
        mode
        genre
        mood
        bpm
        writers
        tags
        outline
        createdAt
        updatedAt
        demo
        isrc
        lyrics {
          nextToken
          __typename
        }
        recordings {
          nextToken
          __typename
        }
        reviews {
          nextToken
          __typename
        }
        owner
        collaborators
        collaboratorDetails
        ownerDetails
        __typename
      }
      owner
      __typename
    }
  }
`;
export const onCreateSyncOpportunity = /* GraphQL */ `
  subscription OnCreateSyncOpportunity {
    onCreateSyncOpportunity {
      id
      title
      description
      soundalike
      era
      tempo
      format
      rights
      term
      fee
      submission
      deadline
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateSyncOpportunity = /* GraphQL */ `
  subscription OnUpdateSyncOpportunity {
    onUpdateSyncOpportunity {
      id
      title
      description
      soundalike
      era
      tempo
      format
      rights
      term
      fee
      submission
      deadline
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteSyncOpportunity = /* GraphQL */ `
  subscription OnDeleteSyncOpportunity {
    onDeleteSyncOpportunity {
      id
      title
      description
      soundalike
      era
      tempo
      format
      rights
      term
      fee
      submission
      deadline
      createdAt
      updatedAt
      __typename
    }
  }
`;
