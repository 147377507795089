<template>
  <div>
    <navbar
      v-if="user"
      :title="title"
      :show-back-button="true"
      @share="$refs.shareModal.showModal()"
    ></navbar>

    <el-container class="song">
      <el-aside :width="aside" class="hidden-sm-and-down">
        <el-menu
          default-active="1"
          class="el-menu-vertical"
          :collapse="isCollapse"
        >
          <el-menu-item index="1" @click="go('/lyrics')">
            <i class="el-icon-document"></i>
            <span slot="title">Lyrics</span>
          </el-menu-item>

          <el-menu-item index="2" @click="go('')">
            <i class="el-icon-info"></i>
            <span slot="title">Song Information</span>
          </el-menu-item>

          <el-menu-item index="3" @click="go('/outline')">
            <i class="el-icon-edit"></i>
            <span slot="title">Outline</span>
          </el-menu-item>

          <el-menu-item index="4" @click="go('/recordings')">
            <i class="el-icon-microphone"></i>
            <span slot="title">Audio Recordings</span>
          </el-menu-item>

          <el-menu-item index="5" @click="go('/reviews')">
            <i class="el-icon-notebook-1"></i>
            <span slot="title">Reviews</span>
          </el-menu-item>
        </el-menu>
      </el-aside>

      <el-main class="spaced">
        <router-view />
      </el-main>
    </el-container>

    <el-container>
      <el-footer class="hidden-md-and-up">
        <router-link :to="{ path: '/song/' + $route.params.id }">
          <i class="el-icon-info"></i>
        </router-link>
        <router-link :to="{ path: '/song/' + $route.params.id + '/outline' }">
          <i class="el-icon-edit"></i>
        </router-link>
        <router-link :to="{ path: '/song/' + $route.params.id + '/lyrics' }">
          <i class="el-icon-document"></i>
        </router-link>
        <router-link
          :to="{ path: '/song/' + $route.params.id + '/recordings' }"
        >
          <i class="el-icon-microphone"></i>
        </router-link>
      </el-footer>
    </el-container>
    <share-modal :song-id="song.id" ref="shareModal" />
  </div>
</template>

<script>
// @ is an alias to /src
import { API, Auth } from "aws-amplify";
import gql from "graphql-tag";
import Navbar from "@/components/Navbar";
import ShareModal from "@/components/ShareModal";

export default {
  name: "SongView",
  components: { navbar: Navbar, "share-modal": ShareModal },
  data() {
    return {
      user: null,
      isCollapse: true,
      song: {},
    };
  },
  methods: {
    getSong() {
      let vm = this;
      (async () => {
        const result = await API.graphql({
          query: gql(`query GetSong($id: ID!) {
             getSong(id: $id) {
               id
               title
               updatedAt
             }
           }
          `),
          variables: {
            id: vm.$route.params.id,
          },
        });
        this.song = result.data.getSong;
      })();
    },
    go(path) {
      switch (path) {
        case "/lyrics":
          this.$intercom.trackEvent("Clicked Lyrics");
          break;
        case "/outline":
          this.$intercom.trackEvent("Clicked Outline");
          break;
        case "/recordings":
          this.$intercom.trackEvent("Clicked Recordings");
          break;
        case "/reviews":
          this.$intercom.trackEvent("Clicked Reviews");
          break;
        case "":
          this.$intercom.trackEvent("Clicked Song Info");
          break;
        default:
          break;
      }
      this.$router.push("/song/" + this.song.id + path);
    },
    shout() {
      alert("event emitted");
    },
  },
  computed: {
    title() {
      if (this.song) {
        if (this.song.title) {
          return this.song.title;
        } else {
          return "Untitled song";
        }
      }
      return "New song";
    },
    aside() {
      if (this.isCollapse) {
        return "55px";
      } else {
        return "200px";
      }
    },
  },
  created() {
    this.getSong();
  },
  async mounted() {
    this.user = await Auth.currentAuthenticatedUser();
    if (this.$route.query.sessionId) {
      this.$alert(
        "You're account has been upgraded. Welcome to SongPad Plus!",
        "Account upgraded",
        {
          confirmButtonText: "OK",
        }
      );
    }
    if (this.$route.query.cancelled) {
      this.$alert(
        "You're upgrade was cancelled. If you need help send us a message and let us know!",
        "Upgrade cancelled",
        {
          confirmButtonText: "OK",
        }
      );
    }
  },
};
</script>

<style>
.logo {
  margin-top: 10px;
  margin-bottom: 20px;
  max-height: 32px;
}

.el-footer {
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
  padding: 20px 0;
  z-index: 1000;
  font-size: 28px;
  background: #fff;
  box-shadow: 0 0 8px #444;
}

.el-footer i {
  margin-top: 10px;
  width: 20%;
  color: #f91c81;
}

.el-aside {
  position: fixed;
  left: -10px;
  z-index: 100;
}

.el-menu-vertical {
  top: 16px;
  position: fixed;
  text-align: left;
  color: #ccc;
  background-color: #211538 !important;
  height: 100vh;
  background-image: url("../assets/SongPad-Shape2-RGB-OnPurple-L.png");
  background-size: 120% auto;
  background-repeat: no-repeat;
  background-position: bottom center;
}

.el-menu-item span {
  color: #e9e9e9;
}

.el-menu-item:hover,
.el-menu-item:focus {
  background-color: rgba(249, 28, 129, 0.2) !important;
}

.el-main.spaced {
  margin: 0 30px;
}

@media screen and (min-width: 1024px) {
  /*for small devices*/
  .spaced {
    margin: 0;
    padding: 0;
  }
}
</style>
