<template>
  <div :class="{ gradient: authState !== 'signedin' && !user }">
    <h1 class="login" v-if="authState !== 'signedin' && !user">SongPad</h1>
    <amplify-authenticator :initial-auth-state="initialScreen">
      <amplify-sign-in
        slot="sign-in"
        username-alias="email"
        header-text="Sign in to your SongPad account"
      ></amplify-sign-in>
      <amplify-sign-up
        slot="sign-up"
        username-alias="email"
        :form-fields.prop="signUpFormFields"
      >
        <div slot="header-subtitle">
          By signing up to SongPad you agree to the
          <a href="https://www.songpad.co/terms.html" target="_blank"
            >SongPad Terms and Conditions</a
          >
        </div>
      </amplify-sign-up>
      <amplify-forgot-password
        username-alias="email"
        slot="forgot-password"
      ></amplify-forgot-password>
      <div v-if="authState === 'signedin' && user">
        <router-view />
      </div>
    </amplify-authenticator>
  </div>
</template>

<script>
import { onAuthUIStateChange } from "@aws-amplify/ui-components";

export default {
  name: "SongPad",
  created() {
    this.unsubscribeAuth = onAuthUIStateChange((authState, authData) => {
      this.authState = authState;
      this.user = authData;
    });
  },
  data() {
    return {
      user: undefined,
      authState: undefined,
      signUpFormFields: [
        {
          type: "name",
          label: "Name",
          placeholder: "Enter your full name",
          required: true,
        },
        {
          type: "email",
          label: "Email Address",
          required: true,
        },
        {
          type: "password",
          label:
            "Password (8 characters minimum, one uppercase letter, one number, one special character)",
          required: true,
        },
      ],
    };
  },
  computed: {
    initialScreen() {
      let state = "signup";
      if (this.$route.query && this.$route.query.screen === "signin") {
        state = "signin";
      }
      return state;
    },
  },
  beforeDestroy() {
    this.unsubscribeAuth();
  },
  mounted() {
    this.$intercom.boot();
    if (this.$route.query && this.$route.query.plus == "true") {
      localStorage.signupPlus = true;
    }
    console.log(this.$route.query);
  },
};
</script>

<style>
body {
  margin: 0;
  padding: 0;
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  min-height: 100vh;
}

#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin: 0;
}
#app:focus {
  outline: none;
}

div.gradient {
  background-image: linear-gradient(#211538 00px, #f91c81);
  position: relative;
  height: 100%;
  min-height: 100vh;
  width: 100vw;
  padding: 0;
  margin: 0;
}

h1.login {
  width: 100%;
  text-align: center;
  font-size: 1.5rem;
  font-weight: 600;
  color: #fff;
  margin: 0;
  padding: 0;
  padding-top: 30px;
  margin-bottom: -20px;
}

#logo {
  width: 300px;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 20px;
  margin-bottom: 50px;
}

:root {
  --amplify-primary-color: #f91c81;
  --amplify-primary-tint: #f463a4;
  --amplify-primary-shade: #9d0a4c;
  --border-radius: 15px;
  --amplify-font-family: "Avenir", Helvetica, Arial, sans-serif;
}

.stop-screen {
  position: absolute;
  height: 100%;
  width: 100vw;
  padding: 50px 25px;
  background-color: rgba(255, 255, 255, 0.98);
  z-index: 1000;
}

@media only screen and (min-width: 768px) {
  .stop-screen {
    display: none;
  }
  body {
    padding-bottom: 0;
  }
}
</style>
