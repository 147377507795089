<template>
  <div class="songs">
    <navbar
      v-if="user"
      :title="
        user.attributes.given_name
          ? `${user.attributes.given_name}'s SongPad`
          : `${user.attributes.name}'s SongPad`
      "
      :show-back-button="false"
    ></navbar>

    <el-row
      class="musiversal"
      align="center"
      justify="space-between"
      :gutter="10"
    >
      <el-col :xs="24" :sm="24" :md="4" :lg="4">
        <img
          src="@/assets/musiversal-logo.webp"
          alt="Musiversal"
          style="width: 140px; height: 34px"
        />
      </el-col>

      <el-col :xs="24" :sm="24" :md="14" :lg="14">
        <h3>
          Unlimited recording sessions with remote musicians. Bring your songs
          to life with Musiversal.
        </h3>
        <p>
          <strong>SongPad Members Offer:</strong> Skip the waitlist and save
          $150 on your first three months
        </p>
      </el-col>

      <el-col :xs="24" :sm="24" :md="6" :lg="6">
        <el-row type="flex" align="center" justify="end">
          <!-- get offer button 
          <el-button type="primary" size="medium" @click="musiversal()"
            >Get Offer</el-button
          >-->
          <!-- find out more button -->
          <el-button type="secondary" size="medium" @click="musiversal()"
            >Find out more</el-button
          >
        </el-row>
      </el-col>
    </el-row>

    <el-container>
      <el-main style="margin-top: 0; padding-top: 5px">
        <el-row :gutter="40">
          <el-col :xs="24" :md="16" :lg="16" :xl="16">
            <h3 style="margin-top: 0">Your Songs</h3>
            <el-row>
              <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
                <el-input
                  placeholder="Search songs..."
                  prefix-icon="el-icon-search"
                  v-model="search"
                  clearable
                  size="medium"
                ></el-input>
              </el-col>
              <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
                <el-button
                  round
                  type="primary"
                  icon="el-icon-plus"
                  @click="createSong()"
                  size="medium"
                  style="float: right"
                >
                  New Song
                </el-button>
              </el-col>
            </el-row>
            <el-table
              v-loading="loading"
              :data="
                songs.filter(
                  (data) =>
                    !search ||
                    (data.title &&
                      data.title.toLowerCase().includes(search.toLowerCase()))
                )
              "
              style="width: 100%"
              :default-sort="{ prop: 'updatedAt', order: 'descending' }"
            >
              <el-table-column label="Title" prop="title">
                <template slot-scope="scope">
                  <router-link
                    @click="loadSong(scope.row.id)"
                    style="
                      color: #222;
                      text-decoration: none;
                      word-break: break-word;
                      font-weight: 500;
                    "
                    :to="'/song/' + scope.row.id"
                    v-if="scope.row.title"
                    >{{ scope.row.title }}</router-link
                  >
                  <router-link
                    @click="loadSong(scope.row.id)"
                    style="
                      color: #ccc;
                      text-decoration: none;
                      word-break: break-word;
                      font-weight: 500;
                    "
                    :to="'/song/' + scope.row.id"
                    v-if="!scope.row.title"
                    >Untitled song</router-link
                  >
                </template>
              </el-table-column>

              <el-table-column label="Collaborators" v-if="viewportWidth > 550">
                <template slot-scope="scope">
                  <avatar
                    v-if="user.username == scope.row.owner"
                    class="small-avatar"
                    :username="
                      user.attributes.given_name +
                      ' ' +
                      user.attributes.family_name
                    "
                    :size="26"
                  ></avatar>
                  <avatar
                    v-else
                    class="small-avatar"
                    :username="ownerName(scope.row.ownerDetails)"
                    :size="26"
                  ></avatar>

                  <avatar
                    class="small-avatar"
                    v-for="collaborator in JSON.parse(
                      scope.row.collaboratorDetails
                    )"
                    :key="collaborator.id"
                    :username="
                      collaborator.given_name + ' ' + collaborator.family_name
                    "
                    :size="26"
                  />
                </template>
              </el-table-column>

              <el-table-column
                label="Last edit"
                width="180"
                sortable
                prop="updatedAt"
              >
                <template slot-scope="scope">
                  <i class="el-icon-time"></i>
                  <span style="margin-left: 10px">{{
                    ago(scope.row.updatedAt)
                  }}</span>
                </template>
              </el-table-column>

              <el-table-column width="210" v-if="viewportWidth > 700">
                <template slot-scope="scope">
                  <el-button
                    @click="loadSong(scope.row.id, 'lyrics')"
                    icon="el-icon-edit"
                    circle
                  ></el-button>
                  <el-button
                    v-if="user.username == scope.row.owner"
                    @click="loadSong(scope.row.id)"
                    icon="el-icon-info"
                    circle
                  ></el-button>
                  <el-button
                    v-if="user.username == scope.row.owner"
                    @click="shareSong(scope.row.id)"
                    icon="el-icon-chat-line-square"
                    circle
                  ></el-button>
                  <el-button
                    v-if="user.username == scope.row.owner"
                    @click="deleteSong(scope.row.id)"
                    icon="el-icon-delete"
                    circle
                  ></el-button>
                </template>
              </el-table-column>

              <el-table-column width="20" v-if="viewportWidth <= 700">
                <template slot-scope="scope">
                  <el-dropdown>
                    <el-button icon="el-icon-more" circle></el-button>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item
                        @click="loadSong(scope.row.id, 'lyrics')"
                        >Edit song</el-dropdown-item
                      >
                      <el-dropdown-item @click="loadSong(scope.row.id)"
                        >Song information</el-dropdown-item
                      >
                      <el-dropdown-item @click="shareSong(scope.row.id)"
                        >Share song</el-dropdown-item
                      >
                      <el-dropdown-item @click="deleteSong(scope.row.id)"
                        >Delete song</el-dropdown-item
                      >
                    </el-dropdown-menu>
                  </el-dropdown>
                </template>
              </el-table-column>
            </el-table>
          </el-col>
          <el-col :xs="24" :md="8" :lg="8" :xl="8">
            <div class="opportunities">
              <el-table
                :data="opportunities"
                :default-sort="{ prop: 'deadline', order: 'ascending' }"
              >
                <template slot="empty">
                  <p style="color: #222; line-height: 1.5">
                    More opportunities coming soon!
                  </p>
                </template>
                <el-table-column>
                  <template slot="header">
                    <h3 style="margin: 0; color: #222">Opportunities</h3>
                  </template>
                  <template slot-scope="scope">
                    <router-link
                      style="
                        color: #222;
                        word-break: normal;
                        text-decoration: none;
                        font-weight: 500;
                      "
                      :to="'/opportunities/' + scope.row.id"
                      >{{ scope.row.title }}</router-link
                    >
                  </template>
                </el-table-column>
                <el-table-column label="Fee" prop="fee" width="80">
                  <template slot-scope="scope">
                    <span style="margin-left: 10px">{{
                      "$" + scope.row.fee
                    }}</span>
                  </template>
                </el-table-column>
                <el-table-column width="20">
                  <template slot-scope="scope">
                    <el-button
                      circle
                      icon="el-icon-more"
                      type="secondary"
                      size="mini"
                      @click="$router.push('/opportunities/' + scope.row.id)"
                    ></el-button>
                  </template>
                </el-table-column>
              </el-table>
              <el-row type="flex" align="center" justify="space-around">
                <el-button
                  round
                  type="primary"
                  size="small"
                  style="margin-top: 10px"
                  @click="$router.push('/opportunities')"
                  >See all opportunities</el-button
                ></el-row
              >
              <el-row type="flex" align="center" justify="space-around">
                <a
                  href="https://patreon.com/thesyncopps"
                  @click="
                    $intercom.trackEvent('clicked patreon link', {
                      page: 'Songs',
                    })
                  "
                  target="_blank"
                  style="
                    color: #222;
                    font-size: 12px;
                    margin: 20px 0 10px 0;
                    text-decoration: none;
                    text-align: center;
                  "
                  >Provided by the Sync Opps. on Patreon.<br />
                  https://patreon.com/thesyncopps</a
                >
              </el-row>
            </div>
          </el-col>
        </el-row>
      </el-main>
    </el-container>

    <el-dialog
      title="Get the best experience"
      :visible.sync="showMobileWarning"
      width="90%"
    >
      <p style="word-break: normal">
        SongPad currently works best on devices with larger screens. For the
        best experience we recommend using a tablet, laptop or desktop with
        Chrome web browser.
      </p>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="showMobileWarning = false"
          >OK</el-button
        >
      </span>
    </el-dialog>
    <share-modal ref="shareModal" :song-id="selectedSongId" />
  </div>
</template>

<script>
// @ is an alias to /src
import { Auth, API, graphqlOperation } from "aws-amplify";
import { createSong, deleteSong } from "./../graphql/mutations";
import gql from "graphql-tag";
import uuidV4 from "uuid/v4";
import moment from "moment";
import Navbar from "@/components/Navbar";
import ShareModal from "../components/ShareModal";
import Avatar from "vue-avatar";

export default {
  name: "SongsView",
  components: { Navbar, ShareModal, Avatar },
  data() {
    return {
      title: null,
      songs: [],
      nextToken: "",
      user: null,
      showMobileWarning: false,
      selectedSongId: null,
      loading: false,
      search: null,
      viewportWidth: window.innerWidth,
      opportunities: null,
    };
  },
  methods: {
    ago(date) {
      return moment(date).fromNow();
    },
    getSongs() {
      try {
        this.loading = true;
        API.graphql({
          query: gql(`query ListSongs{
          listSongs(limit: 1000 ${
            this.nextToken && `, nextToken: "${this.nextToken}"`
          }) {
            items {
              id
              title
              updatedAt
              owner
              collaboratorDetails
              ownerDetails
            }
            nextToken
          }
        }`),
          fetchPolicy: "network-only",
        }).then(({ data: { listSongs } }) => {
          if (listSongs.items) {
            this.songs.push(...listSongs.items);
          }
          if (listSongs.nextToken != null) {
            this.nextToken = listSongs.nextToken;
            this.getSongs();
          } else {
            this.nextToken = "";
            this.$intercom.update({
              songs: this.songs.length,
            });
            this.loading = false;
          }
        });
      } catch (error) {
        console.log(error);
      }
    },
    loadSong(id, tab) {
      if (!tab) this.$router.push("song/" + id);
      else this.$router.push("song/" + id + "/" + tab);
    },
    async createSong() {
      let id = uuidV4();
      let newSong = {
        id,
        title: this.title,
      };
      await API.graphql(graphqlOperation(createSong, { input: newSong }));
      this.$router.push("song/" + id + "/lyrics");
    },
    async deleteSong(id) {
      let vm = this;
      this.$confirm(
        "This will permanently delete your song. We can not undo this. Are you really, really sure?",
        "Warning",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      ).then(async () => {
        await API.graphql(graphqlOperation(deleteSong, { input: { id } }));
        vm.songs = [];
        vm.getSongs();
      });
    },
    shareSong(id) {
      this.selectedSongId = id;
      let vm = this;
      this.$nextTick(() => {
        vm.$refs.shareModal.showModal();
      });
    },
    ownerName(details) {
      console.log(details);
      let owner = JSON.parse(details);
      console.log(owner);
      let name;
      if (owner.given_name) name = owner.given_name + " ";
      if (owner.family_name) name = name + owner.family_name;
      return name;
    },
    handleResize() {
      this.viewportWidth = window.innerWidth;
    },
    async getOpportunities() {
      try {
        const opportunities = await API.graphql(
          graphqlOperation(
            gql(`query ListSyncOpportunitys {
          listSyncOpportunitys {
            items {
              id
              title
              fee
              deadline
            }
          }
        }`)
          )
        );
        this.opportunities = opportunities.data.listSyncOpportunitys.items;
      } catch (error) {
        console.log(error);
      }
    },
    musiversal() {
      // track event with intercom
      this.$intercom.trackEvent("clicked musiversal link", {
        page: "Songs",
      });
      window.open("https://www.musiversal.com/partners/songpad", "_blank");
    },
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);
    window.lintrk("track", { conversion_id: 15062314 });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
  async created() {
    this.getSongs();
    this.getOpportunities();
    const user = await Auth.currentAuthenticatedUser();
    this.user = user;
    if (this.$mq === "sm") {
      this.showMobileWarning = true;
    }
  },
};
</script>

<style lang="scss">
.songs {
  padding-top: 25px;
}

.el-card {
  margin-bottom: 10px;
  height: 300px;
}

.cardHeader {
  height: 200px;
  overflow: hidden;
}

.el-card h3 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  height: 50px;
}

.el-card.new {
  border: 1px dashed rgb(249, 28, 129);
  display: flex;
  align-items: center;
  justify-content: center;
}

.cardBody {
  background-color: #211538 !important;
  background-image: url("../assets/SongPad-Shape2-RGB-OnPurple-L.png");
  background-size: 250% auto;
  background-position: center -100px;
  min-height: 300px;
}

.el-alert__title.is-bold {
  font-size: 15px !important;
}
.el-alert__description {
  color: #222 !important;
  font-size: 14px !important;
}

.small-avatar {
  margin-right: 2px;
  display: inline-block !important;
}

.opportunities {
  background-color: #fff;
  padding: 10px;
  border-radius: 6px;
  height: 100%;
  box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.1);
}

.musiversal {
  background: #211538;
  padding: 10px;
  border-radius: 6px;
  color: #fff;
  h3 {
    color: #fff;
    margin: 0;
    font-size: 16px;
  }
  p {
    color: #fff;
    margin: 0;
    font-size: 14px;
    strong {
      color: #f91c81;
    }
  }
  margin-bottom: 20px;
}

@media screen and (max-width: 768px) {
  .musiversal {
    margin-top: 20px;
    padding: 10px 20px;
    img {
      margin: 10px auto;
    }
    p {
      margin-bottom: 10px;
    }
  }
}
</style>
