<template>
  <div style="display: inline-block">
    <el-button
      class="recording"
      @click="stopRecording()"
      type="secondary"
      round
      :size="size"
      icon="el-icon-microphone"
      v-if="recording"
    ></el-button>
    <el-button
      @click="startRecording()"
      type="secondary"
      round
      :size="size"
      :loading="recordingPending"
      icon="el-icon-microphone"
      v-else
    ></el-button>
    <div class="el-upload__text" v-if="label">{{ label }}</div>
    <stopwatch v-if="counter && recording" />
  </div>
</template>

<script>
import awsconfig from "@/aws-exports";
import Stopwatch from "./Stopwatch.vue";
import { Storage, Auth, API, graphqlOperation } from "aws-amplify";
import uuid from "uuid";
import { createRecording } from "@/graphql/mutations";
//WebAudioRecorder object
import MicRecorder from "@tscole/mic-recorder-to-mp3";
import gql from "graphql-tag";

export default {
  name: "AudioRecorder",
  components: {
    Stopwatch,
  },
  props: {
    label: {
      type: String,
      default: "",
    },
    counter: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: "small",
    },
  },
  data() {
    return {
      recorder: null,
      recording: false,
      recordingPending: false,
    };
  },
  methods: {
    async startRecording() {
      const vm = this;
      vm.$intercom.trackEvent("Started recording");
      vm.recordingPending = true;
      vm.recorder
        .start()
        .then(() => {
          vm.recordingPending = false;
          vm.recording = true;
          vm.$emit("startRecording");
        })
        .catch((e) => {
          vm.recording = false;
          alert("We could not access your microphone");
          vm.$emit("stopRecording");
          console.error(e);
        });
    },
    async stopRecording() {
      const vm = this;
      vm.$intercom.trackEvent("Stopped recording");
      vm.recording = false;
      vm.$emit("stopRecording");
      vm.recorder
        .stop()
        .getMp3()
        .then(([buffer, blob]) => {
          vm.loading = true;
          // do what ever you want with buffer and blob
          // Example: Create a mp3 file and play
          const selectedFile = new File(buffer, uuid() + ".mp3", {
            type: blob.type,
            lastModified: Date.now(),
          });

          vm.$prompt(
            "To save this recording please give it a name",
            "Save recording?",
            {
              confirmButtonText: "Save Recording",
              cancelButtonText: "Discard Recording",
            }
          )
            .then(({ value }) => {
              (async () => {
                let file;

                const name = value;
                const bucket = awsconfig.aws_user_files_s3_bucket;
                const region = awsconfig.aws_user_files_s3_bucket_region;
                const visibility = "protected";
                const key = `${uuid()}.mp3`;

                await Storage.put(key, selectedFile, {
                  level: visibility,
                  contentType: "audio/mpeg",
                });

                file = {
                  bucket,
                  key,
                  region,
                  //mimeType: "audio/mpeg",
                  //localUri: selectedFile,
                };

                // make a graphql query get the song collaborators
                const result = await API.graphql({
                  query: gql(`query GetSong($id: ID!) {
                    getSong(id: $id) {
                      owner
                      collaborators
                      }
                    }
                  `),
                  variables: {
                    id: vm.$route.params.id,
                  },
                  fetchPolicy: "network-only",
                });

                let creds = await Auth.currentUserCredentials();
                let identityId = creds.identityId;

                let input = {
                  name: name,
                  file: file,
                  recordingSongId: vm.$route.params.id,
                  identityId: identityId,
                  level: visibility,
                };

                // if the song has collaborators, add them to the recording
                if (result.data.getSong.collaborators) {
                  input.collaborators = result.data.getSong.collaborators;
                }

                let user = await Auth.currentAuthenticatedUser();
                // if the current user is not the song owner, add them to the recording
                if (result.data.getSong.owner !== user.username) {
                  input.collaborators.push(result.data.getSong.owner);
                }

                try {
                  const result = await API.graphql(
                    graphqlOperation(createRecording, {
                      input: input,
                    })
                  );

                  console.log(result);
                } catch (e) {
                  console.log(e);
                }

                vm.$message({
                  type: "success",
                  message: "Recording uploaded",
                });

                vm.$emit("recordingUploaded");
                vm.$intercom.trackEvent("Uploaded recording");

                vm.loading = false;
              })();
            })
            .catch((err) => {
              console.log(err);
              vm.loading = false;
              vm.$message({
                type: "info",
                message: "Recording deleted",
              });
            });
          //const player = new Audio(URL.createObjectURL(selectedFile));
          //player.play();
        })
        .catch((e) => {
          alert("We could not retrieve your message");
          console.log(e);
        });
    },
  },
  created() {
    this.recorder = new MicRecorder({
      bitRate: 128,
      audio: {
        echoCancellation: false,
        volume: 1.0,
      },
    });
  },
};
</script>

<style scoped>
.recording {
  animation: pulse 1s infinite;
  box-shadow: 0 0 0 0 rgba(249, 28, 129, 0.7);
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(249, 28, 129, 0.7);
  }
  100% {
    box-shadow: 0 0 0 6px rgba(249, 28, 129, 0);
  }
}
</style>
