const moods = [
  "Happy",
  "Heartbroken",
  "Hopeful",
  "Joyful",
  "Melancolic",
  "Reflective",
  "Regretful",
  "Sad",
  "Sentimental",
  "Sorrowful",
];

export { moods };
