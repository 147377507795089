import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import "./plugins/element.js";
import "element-ui/lib/theme-chalk/display.css";
import "./assets/fonts/fira_code.scss";
import { Amplify } from "aws-amplify";
import {
  applyPolyfills,
  defineCustomElements,
} from "@aws-amplify/ui-components/loader";
import awsconfig from "./aws-exports";
import store from "./store";
import VueIntercom from "vue-intercom";
import VueGtag from "vue-gtag";
import VueMq from "vue-mq";

Vue.use(VueIntercom, { appId: "zo7ccvy6" });
Amplify.configure(awsconfig);
applyPolyfills().then(() => {
  defineCustomElements(window);
});

Vue.config.ignoredElements = [/amplify-\w*/];
Vue.config.productionTip = false;

Vue.use(VueGtag, {
  config: { id: process.env.VUE_APP_GTM_ID },
  params: {
    send_page_view: true,
  },
});

Vue.use(VueMq);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
