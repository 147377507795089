<template>
  <div class="song" v-loading.fullscreen.lock="fullscreenLoading">
    <h1>Information</h1>
    <el-row type="flex" justify="center">
      <el-col :xs="24" :sm="24" :md="18" :lg="12" :xl="9">
        <p style="text-align: left">
          <small><strong>song title</strong></small>
        </p>
        <el-input
          placeholder="Song title"
          v-model="song.title"
          @blur="saveSong({ title: song.title })"
        ></el-input>
      </el-col>
    </el-row>
    <el-row type="flex" justify="center">
      <el-col :xs="14" :sm="18" :md="14" :lg="9" :xl="7">
        <p style="text-align: left">
          <small><strong>key</strong></small>
        </p>
        <el-row type="flex" justify="left">
          <el-select
            v-model="song.tone"
            style="width: 90px"
            placeholder="Select"
            :filterable="true"
            @change="saveSong({ tone: song.tone })"
          >
            <el-option
              v-for="tone in tones"
              :key="tone"
              :label="tone"
              :value="tone"
            >
            </el-option> </el-select
          >&nbsp;
          <el-select
            v-model="song.mode"
            placeholder="Select"
            @change="saveSong({ mode: song.mode })"
          >
            <el-option
              v-for="mode in modes"
              :key="mode"
              :label="mode"
              :value="mode"
            >
            </el-option>
          </el-select>
        </el-row>
      </el-col>
      <el-col :xs="10" :sm="6" :md="4" :lg="3" :xl="2">
        <p style="text-align: left">
          <small><strong>bpm</strong></small>
        </p>
        <el-input-number
          v-model="song.bpm"
          :min="60"
          :max="300"
          @change="saveSong({ bpm: song.bpm })"
          style="width: 100%"
        ></el-input-number>
      </el-col>
    </el-row>
    <el-row type="flex" justify="center">
      <el-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6">
        <p style="text-align: left">
          <small><strong>genre</strong></small>
        </p>
        <el-row type="flex" justify="left">
          <el-select
            v-model="song.genre"
            placeholder="Select"
            :filterable="true"
            @change="saveSong({ genre: song.genre })"
          >
            <el-option
              v-for="genre in genres"
              :key="genre"
              :label="genre"
              :value="genre"
            >
            </el-option>
          </el-select>
        </el-row>
      </el-col>
      <el-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6">
        <p style="text-align: left">
          <small><strong>mood</strong></small>
        </p>
        <el-row type="flex" justify="left">
          <el-select
            v-model="song.mood"
            placeholder="Select"
            :filterable="true"
            @change="saveSong({ mood: song.mood })"
          >
            <el-option
              v-for="mood in moods"
              :key="mood"
              :label="mood"
              :value="mood"
            >
            </el-option>
          </el-select>
        </el-row>
      </el-col>
    </el-row>

    <el-row type="flex" justify="center">
      <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="9">
        <el-divider></el-divider>
        <p style="text-align: left">
          <small><strong>Writers</strong></small>
        </p>
        <el-row
          v-for="(writer, index) in writers"
          :key="index"
          style="margin-bottom: 10px"
        >
          <el-row>
            <el-col
              :xs="12"
              :sm="12"
              :md="10"
              :lg="10"
              :xl="10"
              justify="start"
            >
              <el-input
                placeholder="Writer name"
                v-model="writer.name"
                @change="saveSong({ writers: JSON.stringify(writers) })"
              ></el-input>
            </el-col>
            <el-col :xs="12" :sm="12" :md="6" :lg="6" :xl="6" justify="center">
              <el-input
                v-model="writer.percent"
                placeholder="100%"
                @change="changePercent()"
              ></el-input>
            </el-col>
            <el-col
              :xs="12"
              :sm="12"
              :md="6"
              :lg="6"
              :xl="6"
              style="text-align: right"
              justify="end"
            >
              <el-button icon="el-icon-delete"></el-button>
            </el-col>
          </el-row>
          <el-row>
            <el-col
              :xs="12"
              :sm="12"
              :md="10"
              :lg="10"
              :xl="10"
              justify="start"
            >
              <el-input
                placeholder="Writer PRO"
                v-model="writer.pro"
                @change="saveSong({ writers: JSON.stringify(writers) })"
              ></el-input>
            </el-col>
            <el-col :xs="12" :sm="12" :md="6" :lg="6" :xl="6" justify="start">
              <el-input
                placeholder="Writer IPI / CAE"
                v-model="writer.ipi"
                @change="saveSong({ writers: JSON.stringify(writers) })"
              ></el-input>
            </el-col>

            <el-col
              :xs="12"
              :sm="12"
              :md="10"
              :lg="10"
              :xl="10"
              justify="start"
            >
              <el-input
                placeholder="Publisher name"
                v-model="writer.publisher"
                @change="saveSong({ writers: JSON.stringify(writers) })"
              ></el-input>
            </el-col>

            <el-col :xs="12" :sm="12" :md="6" :lg="6" :xl="6" justify="start">
              <el-input
                placeholder="Publisher IPI / CAE"
                v-model="writer.publisherIpi"
                @change="saveSong({ writers: JSON.stringify(writers) })"
              ></el-input>
            </el-col>
          </el-row>
        </el-row>
        <el-row type="flex" justify="start">
          <el-button size="mini" @click="addWriter" round>Add writer</el-button>
        </el-row>
      </el-col>
    </el-row>

    <el-row type="flex" justify="center">
      <el-col :xs="24" :sm="24" :md="18" :lg="12" :xl="9">
        <el-row justify="start">
          <p style="text-align: left">
            <small><strong>tags</strong></small>
          </p>
          <div style="text-align: left">
            <el-tag
              :key="tag"
              v-for="tag in tags"
              closable
              :disable-transitions="false"
              @close="handleClose(tag)"
            >
              {{ tag }}
            </el-tag>
            <el-input
              class="input-new-tag"
              v-if="inputVisible"
              v-model="inputValue"
              ref="saveTagInput"
              size="mini"
              @keyup.enter.native="handleInputConfirm"
              @blur="handleInputConfirm"
              style="width: 150px"
            >
            </el-input>
            <el-button
              v-else
              class="button-new-tag"
              size="small"
              @click="showInput"
              >+ New Tag</el-button
            >
          </div>
        </el-row>
      </el-col>
    </el-row>

    <el-row type="flex" justify="center">
      <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="9">
        <el-divider></el-divider>
        <!-- URL input field for demo -->
        <p style="text-align: left">
          <small
            ><strong>Streaming Link</strong> Youtube, SoundCloud, Spotify or
            other public URL</small
          >
        </p>
        <el-input
          placeholder="https://www.youtube.com/watch?v=..."
          v-model="song.demo"
          @blur="saveSong({ demo: song.demo })"
        />
        <!-- ISRRC input field -->
        <p style="text-align: left">
          <small><strong>ISRC</strong></small>
        </p>
        <el-input
          placeholder="ISRC"
          v-model="song.isrc"
          @blur="saveSong({ isrc: song.isrc })"
        />
      </el-col>
    </el-row>
  </div>
</template>

<script>
// @ is an alias to /src
import { API, graphqlOperation } from "aws-amplify";
import { updateSong } from "./../../graphql/mutations";
import gql from "graphql-tag";
import { genres } from "@/config/genres";
import { moods } from "@/config/moods";

export default {
  name: "InfoView",
  components: {},
  data() {
    return {
      fullscreenLoading: true,
      song: {
        title: "",
        tone: "",
        mode: "",
        genre: "",
        bpm: 120,
        mood: "",
        demo: "",
      },
      writers: [],
      tags: [],
      tones: ["C", "C#", "D", "Eb", "E", "F", "F#", "G", "G#", "A", "Bb", "B"],
      inputVisible: false,
      inputValue: "",
      modes: [
        "Major",
        "Minor",
        "Dorian",
        "Phrygian",
        "Lydian",
        "Mixolydian",
        "Locrian",
        "Other",
      ],
      genres: null,
      moods: null,
    };
  },
  methods: {
    handleClose(tag) {
      this.tags.splice(this.tags.indexOf(tag), 1);
      this.saveSong({ tags: JSON.stringify(this.tags) });
    },
    changePercent() {
      let total = 0;
      this.writers.forEach((writer) => {
        total += writer.percent * 1;
      });
      if (total < 101) {
        this.saveSong({ writers: JSON.stringify(this.writers) });
      } else {
        this.$alert(
          "You can't assign more than 100% ownership of a song. Please adjust the percentages",
          "oh no..",
          {
            confirmButtonText: "OK",
          }
        );
      }
    },
    showInput() {
      this.inputVisible = true;
      this.$nextTick(() => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },

    handleInputConfirm() {
      let inputValue = this.inputValue;
      if (inputValue) {
        this.tags.push(inputValue);
      }
      this.inputVisible = false;
      this.inputValue = "";
      this.saveSong({ tags: JSON.stringify(this.tags) });
    },
    addWriter() {
      this.writers.push({ name: "", percent: null });
    },
    async saveSong(input) {
      input.id = this.$route.params.id;
      // if the input contains "demo" field check it is a valid URL
      if (input.demo) {
        if (!input.demo.includes("http")) {
          this.$message({
            message: "Please enter a valid URL",
            type: "error",
          });
          return;
        }
      }

      try {
        await API.graphql(graphqlOperation(updateSong, { input: input }));
        this.$message({
          message: "Song updated",
          type: "success",
        });
      } catch (error) {
        console.log(error);
        this.$message({
          message: "Error updating song",
          type: "error",
        });
      }
    },
    loadSong() {
      let vm = this;
      (async () => {
        const result = await API.graphql({
          query: gql(`query GetSong($id: ID!) {
                 getSong(id: $id) {
                   id
                   title
                   updatedAt
                   tone
                   mode
                   genre
                   mood
                   demo
                   bpm
                   writers
                   tags
                 }
               }
              `),
          variables: {
            id: vm.$route.params.id,
          },
          fetchPolicy: "network-only",
        });
        console.log(result.data.getSong);
        vm.fullscreenLoading = false;
        if (result.data.getSong.tags === null) {
          vm.tags = [];
        } else {
          vm.tags = JSON.parse(String(result.data.getSong.tags));
        }
        if (result.data.getSong.writers === null) {
          vm.writers = [];
        } else {
          vm.writers = JSON.parse(String(result.data.getSong.writers));
        }
        console.log(vm.song);
        vm.song = result.data.getSong;
        vm.$forceUpdate();
      })();
    },
  },
  beforeMount() {
    this.loadSong();
    this.genres = genres;
    this.moods = moods;
  },
};
</script>

<style>
.el-tag {
  margin-right: 5px !important;
}
.button-new-tag {
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}
.input-new-tag {
  width: 90px;
  vertical-align: bottom;
}
</style>
