<template>
  <i class="play-icon">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18">
      <polygon
        points="8 5 19 12 8 19 8 5"
        :fill="color"
        x="6"
        y="6"
        width="12"
        height="12"
      />
    </svg>
  </i>
</template>

<script>
export default {
  name: "PlayIcon",
  props: {
    color: {
      type: String,
      default: "#ffffff", // Default fill color is white
    },
  },
};
</script>

<style scoped>
.play-icon {
  display: inline-block;
  width: 0.8em;
  height: 0.9em;
  position: relative;
  top: -1px;
  left: -1px;
}
</style>
