<template>
  <div class="song" v-loading.fullscreen.lock="fullscreenLoading">
    <h1>Outline</h1>

    <el-row type="flex" justify="center">
      <el-col :xs="24" :sm="24" :md="18" :lg="12" :xl="9">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="Blank" name="blank">
            <el-row type="flex" justify="center">
              <el-col :span="24">
                <p style="text-align: left">
                  <small><strong>Plan your song or make notes</strong></small>
                </p>
                <p style="text-align: left">
                  <small
                    >Use this area to plan your song, create seed lists or jot
                    down notes and thoughts for later</small
                  >
                </p>
                <el-input
                  type="textarea"
                  placeholder="Notes, ideas, thoughts..."
                  v-model="outline.notes"
                  maxlength="10000"
                  show-word-limit
                  :rows="35"
                  @change="saveOutline"
                >
                </el-input>
              </el-col>
            </el-row>
          </el-tab-pane>
          <el-tab-pane label="Guided" name="guided">
            <el-row type="flex" justify="center">
              <el-col :span="24">
                <p style="text-align: left">
                  <small><strong>who is talking?</strong></small>
                </p>
                <el-input
                  placeholder="who"
                  @change="saveOutline"
                  v-model="outline.who"
                ></el-input>
              </el-col>
            </el-row>
            <el-row type="flex" justify="center">
              <el-col :span="24">
                <p style="text-align: left">
                  <small><strong>whom are they talking to?</strong></small>
                </p>
                <el-input
                  placeholder="to whom"
                  @change="saveOutline"
                  v-model="outline.whom"
                ></el-input>
              </el-col>
            </el-row>
            <el-row type="flex" justify="center">
              <el-col :span="24">
                <p style="text-align: left">
                  <small><strong>why?</strong></small>
                </p>
                <el-input
                  placeholder="why"
                  @change="saveOutline"
                  v-model="outline.why"
                ></el-input>
              </el-col>
            </el-row>
            <el-row type="flex" justify="center">
              <el-col :span="24">
                <p style="text-align: left">
                  <small><strong>when, where and how?</strong></small>
                </p>
                <p style="text-align: left">
                  <small
                    >Are events taking place at specific time of year or time of
                    day? Perhaps different versus take place at different times?
                    Does the story ocurr in a specific location? Think about how
                    times and places can act as methaphors and add to the colour
                    of the song</small
                  >
                </p>
                <el-input
                  type="textarea"
                  placeholder="When, where and how..."
                  v-model="outline.whenwherehow"
                  maxlength="1000"
                  show-word-limit
                  :rows="5"
                  @change="saveOutline"
                >
                </el-input>
              </el-col>
            </el-row>
            <el-row type="flex" justify="center">
              <el-col :span="24">
                <p style="text-align: left">
                  <small><strong>three stages</strong></small>
                </p>
                <p style="text-align: left">
                  <small
                    >Your song should create a journey for the listener. Don't
                    just repeat the same idea over and over. Think of three
                    "stages" of your song. These may or may not correspond to
                    sections, but at each stage the central idea (the title or
                    hook) should be expanded. The second time the listener hears
                    the hook it should mean more than the first time. The final
                    time should be the big reveal and unveil the "why" of the
                    song that you identified already.</small
                  >
                </p>
                <el-input
                  type="textarea"
                  placeholder="Stage one... stage two... stage three..."
                  v-model="outline.stages"
                  maxlength="1000"
                  show-word-limit
                  :rows="15"
                  @change="saveOutline"
                >
                </el-input>
              </el-col>
            </el-row>
          </el-tab-pane>
        </el-tabs>
      </el-col>
    </el-row>
  </div>
</template>

<script>
// @ is an alias to /src
import { API, graphqlOperation } from "aws-amplify";
import { updateSong } from "./../../graphql/mutations";
import gql from "graphql-tag";

export default {
  name: "OutlineView",
  components: {},
  data() {
    return {
      fullscreenLoading: true,
      activeName: "blank",
      outline: {
        notes: "",
        stages: "",
        who: "",
        whom: "",
        why: "",
        when: "",
      },
    };
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    },
    loadOutline() {
      let vm = this;
      (async () => {
        const result = await API.graphql({
          query: gql(`query GetSong($id: ID!) {
               getSong(id: $id) {
                 id
                 outline
               }
             }
            `),
          variables: {
            id: vm.$route.params.id,
          },
          fetchPolicy: "network-only",
        });
        if (!result.data.getSong.outline) {
          vm.outline = {
            notes: null,
            stages: null,
            who: null,
            whom: null,
            why: null,
            whenwherehow: null,
          };
        } else {
          vm.outline = JSON.parse(result.data.getSong.outline);
        }
        vm.fullscreenLoading = false;
      })();
    },
    async saveOutline() {
      let vm = this;
      let input = {
        id: vm.$route.params.id,
        outline: JSON.stringify(vm.outline),
      };
      const result = await API.graphql(
        graphqlOperation(updateSong, { input: input })
      );
      console.log(result);
    },
  },
  beforeMount() {
    this.loadOutline();
  },
  beforeDestroy() {
    this.saveOutline();
  },
};
</script>

<style scoped>
.el-tab-pane {
  max-height: 10000px;
}
</style>
