<template>
  <div style="margin-top: 15px; margin-left: 10px; display: inline-block">
    <div v-if="!button">
      <el-button type="text" size="small" @click="startAudio()" v-if="!playing">
        {{ label }}
      </el-button>

      <el-button type="text" size="small" @click="stopAudio()" v-if="playing">
        Stop
      </el-button>
    </div>
    <div v-else>
      <el-button
        type="secondary"
        size="small"
        round
        @click="startAudio()"
        v-if="!playing"
      >
        <play-icon :color="iconColor"></play-icon>
      </el-button>

      <el-button
        type="secondary"
        size="small"
        round
        @click="stopAudio()"
        v-if="playing"
      >
        <stop-icon
          :color="iconColor"
          style="margin: -2px 0; top: 1px"
        ></stop-icon>
      </el-button>
    </div>
  </div>
</template>

<script>
import * as Tone from "tone";
import { chordParserFactory } from "chord-symbol/lib/chord-symbol.js";
import PlayIcon from "./PlayIcon.vue";
import StopIcon from "./StopIcon.vue";
import * as MidiWriter from "midi-writer-js";

export default {
  components: { PlayIcon, StopIcon },
  name: "ChordPlayer",
  props: {
    label: {
      type: String,
      default: "Play",
    },
    chords: {
      type: Array,
      default: () => [],
    },
    song: {
      type: Object,
      default: () => {},
    },
    durations: {
      type: Array,
      default: () => [],
    },
    loop: {
      type: Boolean,
      default: false,
    },
    clap: {
      type: Boolean,
      default: false,
    },
    button: {
      type: Boolean,
      default: false,
    },
    iconColor: {
      type: String,
      default: "#ffffff",
    },
    start: {
      type: Number,
      default: 0,
    },
    end: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      playing: false,
      sampler: null,
      claps: null,
      part: null,
      animationFrameId: null,
    };
  },
  methods: {
    startAudio(download = false) {
      // clear all existing events
      this.$emit("started");
      this.$intercom.trackEvent("Chords played");
      this.part = null;
      Tone.Transport.cancel();
      if (this.animationFrameId) {
        cancelAnimationFrame(this.animationFrameId);
      }

      this.playing = true;
      let bpm = this.song.bpm ? this.song.bpm : 120;
      Tone.Transport.bpm.value = bpm;

      let chords = [];

      for (let i = 0; i < this.chords.length; i++) {
        let chord = this.music[i];
        let time;
        let duration;
        if (this.durations.length > 0) {
          duration = this.durations[i].duration * (60 / bpm);
          // time = the sum of all previous durations
          time = 0;
          for (let j = 0; j < i; j++) {
            time += this.durations[j].duration * (60 / bpm);
          }
        } else {
          time = i * 4 * (60 / bpm);
          duration = 4 * (60 / bpm);
        }
        chords.push({ notes: chord, duration: duration, time: time });
      }

      let sampler = this.sampler;
      this.part = new Tone.Part((time, value) => {
        sampler.triggerAttackRelease(value.notes, value.duration, time);
      }, chords);

      const self = this;

      if (download) {
        try {
          this.playing = false;
          let notes = [];
          const track = new MidiWriter.Track();
          track.setTempo(this.song.bpm ? this.song.bpm : 120);
          for (let index = 0; index < chords.length; index++) {
            const chord = chords[index];

            const note = new MidiWriter.NoteEvent({
              pitch: chord.notes,
              duration: "T" + self.durations[index].duration * 128,
            });
            notes.push(note);
          }
          track.addEvent(notes);

          const writer = new MidiWriter.Writer(track);
          const midiData = writer.buildFile();

          const blob = new Blob([midiData], { type: "audio/midi" });
          const url = URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.download = this.song.title + ".midi";
          this.$intercom.trackEvent("Midi successfully exported");
          link.click();
          return;
        } catch (error) {
          console.log(error);
          return;
        }
      }

      let clapSampler = this.claps;

      clapSampler.volume.value = -35; // Adjust the volume of the clap sampler directly

      const clapReverb = new Tone.Reverb(3); // Adjust the reverb decay time (2 seconds in this example)
      clapReverb.wet.value = 0.75; // Adjust the reverb wet/dry mix (0.5 for a balanced mix)

      const clapLoop = new Tone.Loop((time) => {
        clapSampler.triggerAttackRelease("C1", "4n", time);
      }, "4n");

      clapSampler.chain(clapReverb, Tone.Destination); // Apply the volume and reverb effects to the clap sampler

      Tone.Transport.stop();
      this.part.stop();
      Tone.Transport.position = 0;
      Tone.start();
      this.part.start(0);
      if (this.loop === true && this.clap) {
        clapLoop.start(0);
      }

      if (this.loop === true) {
        Tone.Transport.loop = true;
      } else {
        Tone.Transport.loop = false;
      }

      let offset = 0;
      // set start point offset
      if (this.start != this.end) {
        for (let index = 0; index < this.start; index++) {
          const element = this.durations[index];
          offset += element.duration * (60 / bpm);
        }
        Tone.Transport.loopStart = offset;
        Tone.Transport.position = offset;
      } else {
        Tone.Transport.loopStart = 0;
      }

      if (this.durations.length > 0) {
        let loopEnd = 0;
        if (this.start != this.end) {
          loopEnd = offset;
          for (let index = this.start; index <= this.end; index++) {
            loopEnd += this.durations[index].duration * (60 / bpm);
          }
        } else {
          for (let index = 0; index < this.durations.length; index++) {
            loopEnd += this.durations[index].duration * (60 / bpm);
          }
        }
        Tone.Transport.loopEnd = loopEnd;
      } else {
        Tone.Transport.loopEnd = this.chords.length + "m"; // Adjust the loop duration as needed
      }

      Tone.Transport.start();

      Tone.Transport.on("stop", () => {
        // Your code here when the audio playback stops
        if (this.loop === false) {
          this.playing = false;
        }
      });

      let totalDuration = 0;
      // calculate total duration of the loop based on durations array
      if (this.durations.length > 0) {
        totalDuration = 0;
        for (let index = 0; index < this.durations.length; index++) {
          totalDuration += this.durations[index].duration * (60 / bpm);
        }
      } else {
        totalDuration = Tone.Transport.loopEnd;
      }

      const updatePlayheadPosition = () => {
        const currentTime = Tone.Transport.seconds;
        const percentage = (currentTime / totalDuration) * 100;
        self.$emit("current-time", percentage);

        // Request the next animation frame
        self.animationFrameId = requestAnimationFrame(updatePlayheadPosition);
      };

      if (this.loop !== false) {
        // Start the animation loop
        updatePlayheadPosition();
      }
    },
    stopAudio() {
      Tone.Transport.stop();
      this.part.stop();
      Tone.Transport.loop = false;
      this.playing = false;
      cancelAnimationFrame(this.animationFrameId);
      this.$emit("stopped");
      this.$emit("current-time", 0);
    },
    handleKeyDown(event) {
      // if key combo is (cmd or ctrl) + shift + e then export audio
      if (
        (event.ctrlKey || event.metaKey) &&
        event.shiftKey &&
        event.keyCode === 69
      ) {
        this.startAudio(true);
      }
    },
  },
  computed: {
    music() {
      let music = [];
      for (var i = 0; i < this.chords.length; i++) {
        var obj = this.chords[i];
        const parseChord = chordParserFactory();
        let data = parseChord(obj);
        let bass = "";
        if (data.formatted.bassNote) {
          bass = data.formatted.bassNote;
        } else {
          bass = data.formatted.rootNote;
        }
        let chord = [bass + "0", bass + "1"];
        data.normalized.notes.forEach((note) => {
          chord.push(note + "3");
        });
        music.push(chord);
      }
      return music;
    },
  },
  created() {
    // Clap setup
    this.claps = new Tone.Sampler({
      C1: "/samples/clap.mp3",
    }).toDestination();

    this.sampler = new Tone.Sampler({
      urls: {
        C4: "C4.mp3",
        A4: "A4.mp3",
      },
      release: 1,
      baseUrl: "/samples/",
    }).toDestination();
  },
  mounted() {
    document.addEventListener("keydown", this.handleKeyDown);
  },
  beforeDestroy() {
    Tone.Transport.stop();
    if (this.part) {
      this.part.stop();
    }
    Tone.Transport.loop = false;
    this.playing = false;
    cancelAnimationFrame(this.animationFrameId);
    document.removeEventListener("keydown", this.handleKeyDown);
  },
};
</script>

<style></style>
