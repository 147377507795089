<template>
  <el-row type="flex" justify="space-around" v-loading="loading">
    <el-col :lg="16" xl="12" v-if="formData">
      <el-form ref="form" label-position="top" label-width="120px">
        <el-form-item label="Title">
          <el-input v-model="formData.title" type="textarea"></el-input>
        </el-form-item>

        <el-form-item label="Description">
          <el-input v-model="formData.description" type="textarea"></el-input>
        </el-form-item>

        <el-form-item label="Soundalike">
          <el-input v-model="formData.soundalike" type="textarea"></el-input>
        </el-form-item>

        <el-form-item label="Era">
          <el-input v-model="formData.era" type="textarea"></el-input>
        </el-form-item>

        <el-form-item label="Tempo">
          <el-input v-model="formData.tempo" type="textarea"></el-input>
        </el-form-item>

        <el-form-item label="Format">
          <el-input v-model="formData.format" type="textarea"></el-input>
        </el-form-item>

        <el-form-item label="Rights">
          <el-input v-model="formData.rights" type="textarea"></el-input>
        </el-form-item>

        <el-form-item label="Term">
          <el-input v-model="formData.term" type="textarea"></el-input>
        </el-form-item>

        <el-form-item label="Fee">
          <el-input-number
            v-model="formData.fee"
            :precision="2"
            :step="100"
          ></el-input-number>
        </el-form-item>

        <el-form-item label="Submission">
          <el-input v-model="formData.submission" type="textarea"></el-input>
        </el-form-item>

        <el-form-item label="Deadline">
          <el-date-picker
            v-model="formData.deadline"
            type="date"
            format="yyyy-MM-dd"
            placeholder="Select Date"
          ></el-date-picker>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="submitForm">Submit</el-button>
        </el-form-item>
      </el-form>
    </el-col>
  </el-row>
</template>

<script>
import { graphqlOperation, API } from "aws-amplify";

export default {
  name: "AdminOpportunity",
  data() {
    return {
      loading: false,
      formData: {
        title: "",
        description: "",
        soundalike: "",
        era: "",
        tempo: "",
        format: "",
        rights: "",
        term: "",
        fee: 0,
        submission: "",
        deadline: null,
      },
    };
  },
  methods: {
    async loadOpportunity() {
      // load opportunity from API using GraphQL query
      this.loading = true;
      try {
        const getSyncOpportunity = /* GraphQL */ `
          query getSyncOpportunity($id: ID!) {
            getSyncOpportunity(id: $id) {
              id
              title
              description
              soundalike
              era
              tempo
              format
              rights
              term
              fee
              submission
              deadline
            }
          }
        `;
        const syncOpportunityData = await API.graphql(
          graphqlOperation(getSyncOpportunity, {
            id: this.$route.params.id,
          })
        );
        this.formData = syncOpportunityData.data.getSyncOpportunity;
        console.log("syncOpportunity loaded!", syncOpportunityData);
        // set the deadline date
        if (this.formData.deadline) {
          this.formData.deadline = new Date(this.formData.deadline);
        }
        this.loading = false;
      } catch (err) {
        console.log("error fetching syncOpportunity...", err);
        this.loading = false;
      }
    },
    async saveOpportunity() {
      // save opportunity using GraphQL mutation
      this.loading = true;
      console.log(this.formData.deadline);
      // format the deadline date YYYY-MM-DD
      this.formData.deadline = this.formData.deadline
        ? this.formData.deadline.toISOString().split("T")[0]
        : null;

      try {
        const updateSyncOpportunity = /* GraphQL */ `
          mutation updateSyncOpportunity(
            $input: UpdateSyncOpportunityInput!
            $condition: ModelSyncOpportunityConditionInput
          ) {
            updateSyncOpportunity(input: $input, condition: $condition) {
              id
              title
              description
              soundalike
              era
              tempo
              format
              rights
              term
              fee
              submission
              deadline
            }
          }
        `;
        const syncOpportunityData = await API.graphql(
          graphqlOperation(updateSyncOpportunity, {
            input: this.formData,
          })
        );
        console.log("syncOpportunity saved!", syncOpportunityData);
        this.$message({
          message: "Sync Opportunity saved",
          type: "success",
        });
        this.loading = false;
        this.$router.push("/admin/");
      } catch (err) {
        this.$message({
          message: "Error saving Sync Opportunity",
          type: "error",
        });
        this.loading = false;
        console.log("error saving syncOpportunity...", err);
      }
    },
    submitForm() {
      this.saveOpportunity();
    },
  },
  mounted() {
    this.loading = true;
    // delay loading the opportunity to allow the form to render
    setTimeout(() => {
      this.loadOpportunity();
      this.loading = false;
    }, 2500);
  },
};
</script>

<style></style>
