<template>
  <el-main v-loading="loading">
    <el-row type="flex" justify="space-around">
      <h1>{{ opportunity.title }}</h1>
    </el-row>
    <el-row type="flex" justify="space-around">
      <el-col :span="12">
        <p v-if="opportunity.description">
          <strong>Music Description:</strong> {{ opportunity.description }}
        </p>
        <p v-if="opportunity.soundalike">
          <strong>Soundalike or Similar Style:</strong>
          {{ opportunity.soundalike }}
        </p>
        <p v-if="opportunity.era">
          <strong>Era:</strong> {{ opportunity.era }}
        </p>
        <p v-if="opportunity.tempo">
          <strong>Tempo:</strong> {{ opportunity.tempo }}
        </p>
        <p v-if="opportunity.format">
          <strong>Format:</strong> {{ opportunity.format }}
        </p>
        <p v-if="opportunity.rights">
          <strong>Rights:</strong> {{ opportunity.rights }}
        </p>
        <p v-if="opportunity.term">
          <strong>Term:</strong> {{ opportunity.term }}
        </p>
        <p v-if="opportunity.fee">
          <!-- fee formated to currency with two decinal places -->
          <strong>Fee Available:</strong> ${{ opportunity.fee.toFixed(2) }}
        </p>
        <p v-if="opportunity.deadline">
          <strong>Deadline:</strong> {{ opportunity.deadline }}
        </p>
      </el-col>
    </el-row>
    <el-row type="flex" justify="space-around">
      <!-- button to submit song -->
      <el-button type="primary" size="large" round @click="submitSong"
        >Find out more and submit song</el-button
      >
    </el-row>
    <upgrade-modal
      ref="upgradeModal"
      intro="To submit your song, you must be a SongPad Plus member."
    />
  </el-main>
</template>

<script>
import { graphqlOperation, API, Auth } from "aws-amplify";
import gql from "graphql-tag";
import UpgradeModal from "../../components/UpgradeModal.vue";

export default {
  name: "SyncOpportunity",
  components: {
    UpgradeModal,
  },
  data() {
    return {
      loading: false,
      opportunity: {},
    };
  },
  methods: {
    async getOpportunity() {
      this.loading = true;
      // load the opportunity from the API using the id from the route
      try {
        const opportunity = await API.graphql(
          graphqlOperation(
            gql(`query GetSyncOpportunity($id: ID!) {
          getSyncOpportunity(id: $id) {
            id
            title
            description
            fee
            soundalike
            era
            tempo
            format
            rights
            term
            submission
            deadline
          }
        }`),
            {
              id: this.$route.params.id,
            }
          )
        );
        this.opportunity = opportunity.data.getSyncOpportunity;
        this.loading = false;
      } catch (error) {
        this.$alert("Error loading opportunity", "Error", {
          confirmButtonText: "OK",
          callback: () => {
            this.$router.push("/");
          },
        });
        console.log(error);
        this.loading = false;
      }
    },
    async submitSong() {
      // check if the user is a SongPad plus member
      const user = await Auth.currentAuthenticatedUser({ bypassCache: true });
      let subscription = user.attributes["custom:stripe_subscription"];
      if (!subscription) {
        // if not a SongPad plus member, show the upgrade modal
        this.$refs.upgradeModal.showModal();
        return;
      } else {
        // if a SongPad plus member, redirect to the submit song page
        this.$router.push(`/opportunities/submit/${this.$route.params.id}`);
      }
    },
  },
  mounted() {
    this.getOpportunity();
    this.$intercom.trackEvent("viewed sync opportunity", {
      opportunityId: this.$route.params.id,
    });
  },
};
</script>

<style scoped>
h1 {
  font-size: 2rem;
  margin-top: 50px;
  margin-bottom: 20px;
}
</style>
