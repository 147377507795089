import Vue from "vue";
import Router from "vue-router";
import { Auth } from "aws-amplify";
import Home from "./views/Home.vue";
import Songs from "./views/Songs.vue";
import Song from "./views/Song.vue";
import Info from "./views/song/Info.vue";
import Outline from "./views/song/Outline.vue";
import Recordings from "./views/song/Recordings.vue";
import Copyright from "./views/song/Copyright.vue";
import Lyrics from "./views/song/Lyrics.vue";
import Reviews from "./views/song/Reviews.vue";
import SyncOpportunities from "./views/sync/SyncOpportunities.vue";
import SyncOpportunitiesList from "./views/sync/SyncOpportunitiesList.vue";
import SyncOpportunity from "./views/sync/SyncOpportunity.vue";
import SyncOpportunitySubmit from "./views/sync/SyncOpportunitySubmit.vue";
import AdminHome from "./views/admin/AdminHome.vue";
import AdminOpportunity from "./views/admin/AdminOpportunity.vue";
import AdminOpportunitiesList from "./views/admin/AdminOpportunitiesList.vue";

Vue.use(Router);

const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "Home",
      component: Home,
      children: [
        {
          path: "",
          component: Songs,
        },
        {
          // UserPosts will be rendered inside User's <router-view>
          // when /user/:id/posts is matched
          path: "/song/:id",
          name: "Song",
          component: Song,
          children: [
            {
              path: "",
              component: Info,
            },
            {
              path: "outline",
              component: Outline,
            },
            {
              path: "lyrics",
              component: Lyrics,
            },
            {
              path: "recordings",
              component: Recordings,
            },
            {
              path: "reviews",
              component: Reviews,
            },
            {
              path: "copyright",
              component: Copyright,
            },
          ],
        },
        {
          path: "/opportunities",
          name: "Sync Opportunities",
          component: SyncOpportunities,
          children: [
            {
              path: "",
              name: "Sync Opportunities List",
              component: SyncOpportunitiesList,
            },
            {
              path: ":id",
              name: "Sync Opportunity",
              component: SyncOpportunity,
            },
            {
              path: "submit/:id",
              name: "Submit Opportunity",
              component: SyncOpportunitySubmit,
            },
          ],
        },
      ],
    },
    {
      path: "/admin",
      name: "Admin Home",
      component: AdminHome,
      beforeEnter: async (to, from, next) => {
        const user = await Auth.currentAuthenticatedUser();
        const groups =
          user.signInUserSession.accessToken.payload["cognito:groups"];
        if (groups && groups.includes("Admin")) {
          next();
        } else {
          alert("You are not authorized to access this page.");
          next("/");
        }
      },
      children: [
        {
          path: "",
          name: "Admin Sync Opportunities List",
          component: AdminOpportunitiesList,
        },
        {
          path: "opportunity/:id",
          name: "Admin Sync Opportunity",
          component: AdminOpportunity,
        },
      ],
    },
  ],
});

export default router;
