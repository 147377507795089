<template>
  <el-dialog
    :visible.sync="dialogVisible"
    width="410px"
    :before-close="handleClose"
    append-to-body
    custom-class="upgrade-modal"
    id="upgradeModal"
  >
    <el-row type="flex" justify="space-around" style="margin: 0 0 20px 0"
      ><h1>Upgrade to SongPad Plus</h1></el-row
    >

    <el-row type="flex" justify="space-around">
      <p class="intro">
        {{ intro }}
      </p></el-row
    >

    <el-row>
      <el-row type="flex" justify="space-around">
        <el-col :lg="24" class="price-block">
          <el-row type="flex" justify="space-around">
            <el-badge
              value="Save $6"
              style="margin-bottom: 10px; border: none"
              type="warning"
            >
              <el-switch
                style="display: block"
                v-model="yearly"
                active-color="#f91c81"
                inactive-color="#f91c81"
                active-text="one year"
                inactive-text="one month"
              >
              </el-switch>
            </el-badge>
          </el-row>
          <el-row type="flex" justify="space-around">
            <p
              style="
                word-break: break-word;
                text-align: center;
                font-size: 16px;
              "
            >
              For just ${{ price }}* get a {{ duration }} full access to SongPad
              Plus including:
            </p>
          </el-row>
          <el-row type="flex" justify="space-around">
            <ul style="padding: 0">
              <li class="benefit">
                <i class="el-icon-success"></i> Unlimited Lyric Helper tokens
              </li>
              <li class="benefit">
                <i class="el-icon-success"></i> 5 song reviews a month
              </li>
              <li class="benefit">
                <i class="el-icon-success"></i> Download recordings
              </li>
              <li class="benefit">
                <i class="el-icon-success"></i> Export MIDI Files
              </li>
              <li class="benefit">
                <i class="el-icon-success"></i> Export ChordPro Files
              </li>
              <li class="benefit">
                <i class="el-icon-success"></i> Export PDF chord charts
              </li>
              <li class="benefit">
                <i class="el-icon-success"></i> Export Text Files
              </li>
            </ul>
          </el-row>
          <el-row type="flex" justify="space-around">
            <el-button
              type="primary"
              size="medium"
              round
              @click="checkout(priceId)"
              v-loading.fullscreen.lock="fullscreenLoading"
              >Upgrade now for ${{ price }}</el-button
            ></el-row
          >
        </el-col>
      </el-row>
      <el-row type="flex" justify="space-around">
        <p style="margin: 0">
          If you have a discount code enter it on the next screen.
        </p>
      </el-row>
      <el-row type="flex" justify="space-around">
        <p style="margin: 0">
          <small>* All plans renew automatically. Cancel anytime</small>
        </p>
      </el-row>
    </el-row>
    <el-row type="flex" justify="space-around">
      <el-button type="text" size="mini" @click="handleClose"
        >Not yet</el-button
      >
    </el-row>
  </el-dialog>
</template>

<script>
import { API, Auth } from "aws-amplify";
import { stripe_pricing } from "@/config/stripe.js";

export default {
  props: ["intro"],
  data() {
    return {
      dialogVisible: false,
      stripePricing: null,
      fullscreenLoading: false,
      yearly: false,
    };
  },
  methods: {
    showModal() {
      this.dialogVisible = true;
      this.$gtag.event("modal_shown", {
        event_category: "engagement",
        event_label: "upgrade_modal_shown",
      });
    },
    async checkout(priceId) {
      this.$gtag.event("button_click", {
        event_category: "engagement",
        event_label: "get_started_upgrade",
      });

      let vm = this;
      const user = await Auth.currentAuthenticatedUser();
      vm.fullscreenLoading = true;
      try {
        const myInit = {
          body: {
            priceId,
            email: user.attributes.email,
            successUrl:
              location.protocol + "//" + location.host + location.pathname,
            cancelUrl:
              location.protocol + "//" + location.host + location.pathname,
          },
          headers: { "Content-Type": "application/json" },
        };

        let response = await API.post("stripe", "/checkout", myInit);
        if (response.body.url) {
          window.location.href = response.body.url;
        }
      } catch (error) {
        console.log(error);
        vm.fullscreenLoading = false;
        vm.$alert(
          "Something seems to have gone wrong. Please try again",
          "Sorry",
          {
            confirmButtonText: "OK",
          }
        );
      }
    },
    handleClose(done) {
      this.$confirm(
        "Are you sure you don't want to upgrade? You'll be missing out on some great features!"
      )
        .then(() => {
          this.dialogVisible = false;
          done();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    openUrl(url) {
      window.open(url, "_blank");
    },
  },
  computed: {
    price() {
      if (this.yearly == false) {
        return this.stripePricing.plus.monthlyAmount;
      } else {
        return this.stripePricing.plus.yearlyAmount;
      }
    },
    duration() {
      if (this.yearly == false) {
        return "month's";
      } else {
        return "year's";
      }
    },
    priceId() {
      if (this.yearly == false) {
        return this.stripePricing.plus.monthlyPriceId;
      } else {
        return this.stripePricing.plus.yearlyPriceId;
      }
    },
  },
  created() {
    this.stripePricing = stripe_pricing;
    console.log(this.stripePricing);
  },
};
</script>

<style lang="scss">
.intro {
  margin-top: -20px;
  margin-bottom: 10px;
  font-size: 1rem;
  white-space: normal;
  word-break: break-word !important;
  text-align: center;
}

.benefit {
  list-style: none;
  word-break: break-word !important;
  color: #fff;
  margin-bottom: 5px;
  padding-left: 0;
  margin-left: -5px;
  i {
    font-size: 16px;
    color: #f91c81;
    margin: 0 5px 0 0;
  }
}

.bordered {
  padding-top: 20px;
  margin-top: 10px;
  padding-bottom: 20px;
  margin-bottom: 10px;
  border-top: 1px dotted rgba($color: #fff, $alpha: 0.2);
  border-bottom: 1px dotted rgba($color: #fff, $alpha: 0.2);
}

.price-block {
  background: rgba($color: #fff, $alpha: 0.05);
  padding: 20px 15px;
  margin: 5px;
  border-radius: 6px !important;
  .el-badge__content {
    border: none !important;
  }
  .el-badge__content.is-fixed {
    right: 1px !important;
    top: -2px;
  }
}

.el-dialog__body {
  padding: 0 20px 20px 20px !important;
  padding-top: 0 !important;
}

.upgrade-modal {
  background: #211538 !important;
  border-radius: 6px !important;
  color: #fff !important;
  h1,
  h2,
  h3,
  h4,
  p {
    color: #fff !important;
  }
  .el-switch__label {
    font-size: 14px;
    font-weight: 400;
    color: #fff !important;
    .is-active {
      color: #fff !important;
    }
  }
}
</style>
