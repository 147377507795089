<template>
  <div>
    <el-page-header @back="goBack" content="Admin"> </el-page-header>
    <el-menu
      :default-active="activeIndex"
      class="el-menu-admin"
      mode="horizontal"
      @select="handleSelect"
    >
      <el-menu-item index="1">Sync Opportunities</el-menu-item>
      <el-menu-item index="2">Submissions</el-menu-item>
    </el-menu>
    <router-view style="margin-top: 25px" />
  </div>
</template>

<script>
export default {
  name: "AdminHome",
  data() {
    return {
      activeIndex: "1",
    };
  },
  methods: {
    goBack() {
      history.back();
    },
    handleSelect(index) {
      let path = "";
      switch (index) {
        case 1:
          path = "";
          break;
        case 2:
          path = "submissions";
          break;
      }
      this.$router.push(`/admin/${path}`);
    },
  },
};
</script>

<style scoped>
.el-page-header {
  padding: 15px;
}
</style>
