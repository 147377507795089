function isEmptyOrSpaces(str) {
  return str === null || str.match(/^ *$/) !== null;
}

const isChord = (string) => {
  var notes = "[CDEFGAB]",
    accidentals = "(b|bb)?",
    chords = "(m|mb|maj7|maj|min7|min|sus|aug|add|dim)?",
    suspends = "(10|11|12|13|1|2|3|4|5|6|7|8|9)?",
    modifiers = "(alt|b|#)?",
    sharp = "(#)?",
    regex = new RegExp(
      "\\b" +
        notes +
        accidentals +
        chords +
        suspends +
        modifiers +
        suspends +
        "\\b" +
        sharp,
      "g"
    );

  if (regex.test(string)) {
    return true;
  }
  return false;
};

const lineHasChord = (line) => {
  let words = line.split(" ");
  let chord = false;
  words.forEach((word) => {
    if (isChord(word) == true) {
      chord = true;
    }
  });
  return chord;
};

const lineIsOnlyChords = (line) => {
  let onlyChord = false;
  if (!lineHasChord(line)) {
    return false;
  } else {
    let words = line.split(" ");
    onlyChord = true;
    words.forEach((word) => {
      if (!isEmptyOrSpaces(word) && isChord(word) == false) {
        onlyChord = false;
      }
    });
  }
  return onlyChord;
};

export { isChord, lineHasChord, lineIsOnlyChords };
