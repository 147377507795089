<template>
  <div>
    <el-dialog
      :visible.sync="dialogVisible"
      width="460px"
      :before-close="handleClose"
      append-to-body
    >
      <el-row>
        <h2>Refer a friend</h2>
        <div v-if="stage == 1">
          <p>
            Refer a friend and you'll both get 3 months of SongPad Plus for
            free!
          </p>
          <ol>
            <li>
              Use the form on the next screen to generate a promo code and send
              it to your friend
            </li>
            <li>
              When your friend signs up for SongPad Plus and uses the promocode
              they will get 3 months for free
            </li>
            <li>
              We'll send you an email with a promocode to claim 3 months of
              SongPad Plus for yourself
            </li>
          </ol>
          <el-row type="flex" justify="space-around">
            <el-button type="primary" @click="stage++"
              >Refer a friend now</el-button
            >
          </el-row>
        </div>
        <div v-if="stage == 2">
          <h3>Generate a promocode</h3>
          <p>
            Enter your friend's first name and email address and we'll generate
            a promocode for you to send to them.
          </p>
          <el-input
            style="margin-bottom: 5px"
            size="medium"
            placeholder="Your friend's first name"
            v-model="name"
          ></el-input>
          <el-input
            style="margin-bottom: 5px"
            size="medium"
            placeholder="Your friend's email"
            v-model="email"
          ></el-input>
          <el-row type="flex" justify="space-around">
            <el-button type="primary" :loading="generatingCode" @click="getCode"
              >Generate</el-button
            >
          </el-row>
        </div>

        <div v-if="stage == 3">
          <h3>Send the below promo code to your friend</h3>
          <p>
            You can copy this text or directly email it to your friend. Remember
            to include the promo code if you write your own! Note 'Send as
            email' requires you to have your email accounts configured
            correctly.
          </p>
          <div id="email">
            <p>
              Hi {{ name }},<br /><br />
              I've been using SongPad, a platform for songwriters, and I think
              you'd love it too. So, I've got a special promo code just for you
              to enjoy up to 3 months of SongPad Plus for FREE.
              <br /><br /><strong>Here's the code: {{ code }}</strong>
              <br /><br />
              Simply follow these steps to get started:
            </p>
            <ol>
              <li>
                Go to
                <a href="https://app.songpad.co?plus=true"
                  >https://app.songpad.co?plus=true</a
                >
              </li>
              <li>Sign up for a SongPad account.</li>
              <li>Upgrade to SongPad Plus.</li>
              <li>Enter the promo code: {{ code }}</li>
            </ol>
            <p>
              That's it! You'll have access to amazing songwriting tools and
              features.
            </p>
          </div>
          <el-row type="flex" justify="space-around">
            <div>
              <!-- button to copy email to clipboard -->
              <el-button
                type="primary"
                @click="copyToClipboard()"
                style="margin-bottom: 5px; margin-right: 5px"
                >Copy text to clipboard</el-button
              >&nbsp;
              <el-button
                type="primary"
                @click="sendEmail()"
                style="margin-bottom: 5px"
                >Send as email</el-button
              >
            </div>
          </el-row>
        </div>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
import { API } from "aws-amplify";

export default {
  name: "ReferralModal",
  props: ["songId"],
  data() {
    return {
      code: null,
      dialogVisible: false,
      fullscreenLoading: false,
      generatingCode: false,
      stage: 1,
      name: null,
      email: null,
    };
  },
  methods: {
    handleClose() {
      this.dialogVisible = false;
      this.$emit("close");
    },
    showModal() {
      this.dialogVisible = true;
      // Send an event to Google Analytics
      this.$gtag.event("modal_shown", {
        event_category: "engagement",
        event_label: "referral_modal_shown",
      });
      // Send an event to intercom
      this.$intercom.trackEvent("Open referral modal");
    },
    async getCode() {
      // Send an event to intercom
      this.$intercom.trackEvent("Clicked generate referral code");
      // Make an API call to the referral endpoint
      let vm = this;
      vm.generatingCode = true;
      vm.fullscreenLoading = true;

      // Check if name and email are valid

      // regex to check for valid email
      let emailRegex = /\S+@\S+\.\S+/;

      if (
        !this.name ||
        !this.email ||
        this.name.length < 3 ||
        this.email.length < 3 ||
        !emailRegex.test(this.email)
      ) {
        alert("Please enter a valid name and email address");
        vm.fullscreenLoading = false;
        vm.generatingCode = false;
        return;
      }

      try {
        let response = await API.get("stripe", "/referral");
        if (response.code) {
          vm.code = response.code.code;
        }
        console.log(response);
        vm.fullscreenLoading = false;
        vm.generatingCode = false;
        vm.stage = 3;
      } catch (error) {
        console.log(error);
        vm.fullscreenLoading = false;
        vm.generatingCode = false;
        vm.$alert(
          "Something seems to have gone wrong. Please try again",
          "Sorry",
          {
            confirmButtonText: "OK",
          }
        );
      }
    },
    copyToClipboard() {
      // Send an event to intercom
      this.$intercom.trackEvent("Clicked copy referral email to clipboard");
      // Select the "email" div element
      const emailElement = document.getElementById("email");

      // Create a range and select the content
      const range = document.createRange();
      range.selectNode(emailElement);

      // Create a selection
      const selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);

      // Copy the selected content to the clipboard
      document.execCommand("copy");

      // Clean up the selection
      selection.removeAllRanges();
      // use elementio to show a message
      this.$message({
        message: "Copied to clipboard",
        type: "success",
      });
    },
    sendEmail() {
      // Send an event to intercom
      this.$intercom.trackEvent("Clicked send referral email");
      window.open(this.mailToLink, "_self");
    },
  },
  computed: {
    buttonDisabled() {
      // Check if either name or email has length less than 3, disable the button
      if (this.name && this.email) {
        return this.name.length < 3 || this.email.length < 3;
      }

      // Disable the button if either name or email is empty
      return true;
    },
    mailToLink() {
      let subject = encodeURIComponent(
        "Get up to 3 months of SongPad Plus for free"
      );
      let body = encodeURIComponent(document.getElementById("email").innerText);
      return `mailto:${this.email}?subject=${subject}&body=${body}`;
    },
  },
  watch: {
    stage() {
      // if stage == 2 we know a button was clicked to get here
      if (this.stage == 2) {
        // Send an event to Google Analytics
        this.$gtag.event("modal_shown", {
          event_category: "engagement",
          event_label: "referral_modal_shown",
        });
        // Send an event to intercom
        this.$intercom.trackEvent("Clicked refer a friend");
      }
    },
  },
  mounted() {
    this.stage = 1;
    this.name = null;
    this.email = null;
  },
};
</script>

<style scoped>
.el-button {
  margin: 5px auto !important;
}

div {
  word-break: normal;
}
#email {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  padding: 20px;
  margin-bottom: 10px;
}
</style>
