const artists = [
  "ABBA",
  "AC/DC",
  "Aerosmith",
  "Al Green",
  "Albert King",
  "Alice Cooper",
  "Amy Winehouse",
  "Arcade Fire",
  "Arctic Monkeys",
  "Aretha Franklin",
  "B.B. King",
  "Barry White",
  "Beastie Boys",
  "Beck",
  "Big Star",
  "Billy Joel",
  "Bjork",
  "Black Flag",
  "Black Sabbath",
  "Blondie",
  "Blood, Sweat and Tears",
  "Bo Diddley",
  "Bob Dylan",
  "Bob Dylan and the Band",
  "Bob Marley & the Wailers",
  "Bobby Bland",
  "Bonnie Raitt",
  "Boz Scaggs",
  "Brian Eno",
  "Bruce Springsteen",
  "Buddy Holly",
  "Buddy Holly and the Crickets",
  "Buffalo Springfield",
  "Buzzcocks",
  "Carole King",
  "Cat Stevens",
  "Cheap Trick",
  "Chuck Berry",
  "Coldplay",
  "Cream",
  "Creedence Clearwater Revival",
  "Crosby Stills and Nash",
  "Crosby Stills Nash and Young",
  "Curtis Mayfield",
  "Curtis Mayfield and the Impressions",
  "Cyndi Lauper",
  "D'Angelo",
  "David Bowie",
  "De La Soul",
  "Def Leppard",
  "Depeche Mode",
  "Derek and the Dominos",
  "Devo",
  "Diana Ross and the Supremes",
  "Dire Straits",
  "Dolly Parton",
  "Don Henley",
  "Dr. Dre",
  "Dr. John",
  "Dusty Springfield",
  "Earth, Wind and Fire",
  "Echo and the Bunnymen",
  "Elton John",
  "Elvis Costello",
  "Elvis Presley",
  "Eminem",
  "EPMD",
  "Eric Clapton",
  "Etta James",
  "Eurythmics",
  "Fleetwood Mac",
  "Frank Sinatra",
  "Fugees",
  "Funkadelic",
  "Gang of Four",
  "George Harrison",
  "George Michael",
  "Go-Go's",
  "Graham Parker",
  "Gram Parsons",
  "Grateful Dead",
  "Green Day",
  "Guns n' Roses",
  "Hank Williams",
  "Harry Smith, ed.",
  "Hole",
  "Howlin' Wolf",
  "Husker Du",
  "Ike and Tina Turner",
  "Jackie Wilson",
  "Jackson Browne",
  "James Brown",
  "James Taylor",
  "Jane's Addiction",
  "Janet Jackson",
  "Janis Joplin",
  "Jay-Z",
  "Jeff Buckley",
  "Jefferson Airplane",
  "Jerry Lee Lewis",
  "Jethro Tull",
  "John Coltrane",
  "John Lee Hooker",
  "John Lennon",
  "John Mayall With Eric Clapton",
  "John Prine",
  "Johnny Cash",
  "Joni Mitchell",
  "Joy Division",
  "Kanye West",
  "Kiss",
  "Kraftwerk",
  "Labelle",
  "Lauryn Hill",
  "LCD Soundsystem",
  "Led Zeppelin",
  "Leonard Cohen",
  "Lil Wayne",
  "Linda Ronstadt",
  "Little Richard",
  "Little Walter",
  "Liz Phair",
  "LL Cool J",
  "Loretta Lynn",
  "Los Lobos",
  "Lou Reed",
  "Love",
  "Lucinda Williams",
  "Lynyrd Skynyrd",
  "M.I.A.",
  "Madonna",
  "Manu Chao",
  "Marvin Gaye",
  "Mary J. Blige",
  "Massive Attack",
  "MC5",
  "Meat Loaf",
  "Merle Haggard",
  "Metallica",
  "MGMT",
  "Michael Jackson",
  "Miles Davis",
  "Minutemen",
  "Moby",
  "Moby Grape",
  "Mott the Hoople",
  "Muddy Waters",
  "My Bloody Valentine",
  "My Morning Jacket",
  "N.W.A",
  "Nas",
  "Neil Diamond",
  "Neil Young",
  "Neil Young and Crazy Horse",
  "Neil Young With Crazy Horse",
  "New Order",
  "New York Dolls",
  "Nick Drake",
  "Nine Inch Nails",
  "Nirvana",
  "Oasis",
  "Ornette Coleman",
  "Otis Redding",
  "Outkast",
  "Parliament",
  "Patsy Cline",
  "Patti Smith",
  "Paul Simon",
  "Pavement",
  "Pearl Jam",
  "Peter Gabriel",
  "Peter Wolf",
  "Pink Floyd",
  "Pixies",
  "PJ Harvey",
  "Portishead",
  "Prince",
  "Professor Longhair",
  "Public Enemy",
  "Public Image Ltd.",
  "Queen",
  "Quicksilver Messenger Service",
  "R.E.M.",
  "Radiohead",
  "Raekwon",
  "Rage Against the Machine",
  "Ramones",
  "Randy Newman",
  "Ray Charles",
  "Red Hot Chili Peppers",
  "Richard and Linda Thompson",
  "Robert Johnson",
  "Rod Stewart",
  "Roxy Music",
  "Run-DMC",
  "Sam Cooke",
  "Santana",
  "Simon and Garfunkel",
  "Sinead O' Connor",
  "Sleater-Kinney",
  "Sly and the Family Stone",
  "Smokey Robinson and the Miracles",
  "Sonic Youth",
  "Soundgarden",
  "Stan Getz and Joao Gilberto Featuring Antonio Carlos Jobim",
  "Steely Dan",
  "Steve Earle",
  "Steve Miller Band",
  "Stevie Wonder",
  "Suicide",
  "T. Rex",
  "Talking Heads",
  "Television",
  "The Allman Brothers Band",
  "The B-52's",
  "The Band",
  "The Beach Boys",
  "The Beatles",
  "The Byrds",
  "The Carpenters",
  "The Cars",
  "The Clash",
  "The Cure",
  "The Doors",
  "The Drifters",
  "The Eagles",
  "The Flying Burrito Brothers",
  "The Jesus and Mary Chain",
  "The Jimi Hendrix Experience",
  "The Kinks",
  "The Magnetic Fields",
  "The Mamas and the Papas",
  "The Meters",
  "The Modern Lovers",
  "The Mothers of Invention",
  "The Notorious B.I.G.",
  "The O'Jays",
  "The Paul Butterfield Blues Band",
  "The Pogues",
  "The Police",
  "The Pretenders",
  "The Replacements",
  "The Rolling Stones",
  "The Ronettes",
  "The Sex Pistols",
  "The Smashing Pumpkins",
  "The Smiths",
  "The Stone Roses",
  "The Stooges",
  "The Strokes",
  "The Temptations",
  "The Velvet Underground",
  "The White Stripes",
  "The Who",
  "The Yardbirds",
  "The Zombies",
  "TLC",
  "Todd Rundgren",
  "Tom Petty and the Heartbreakers",
  "Tom Waits",
  "Toots and the Maytals",
  "Tracy Chapman",
  "U2",
  "Vampire Weekend",
  "Van Halen",
  "Van Morrison",
  "Various Artists",
  "War",
  "Weezer",
  "Whitney Houston",
  "Wilco",
  "Willie Nelson",
  "Wings",
  "Wire",
  "Wu-Tang Clan",
  "ZZ Top",
];

export { artists };
